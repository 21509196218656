// @flow

export type DonorOrganId = {
    unos_id: string,
    match_id: string,
    organ_type: string,
    opo: string,
    current_location: string,
}

export type DonorOrganDemographics = {
    age: number,
    blood_type: ?string,
    bmi: number,
    ethnicity: ?string,
    height: number,
    race: ?string,
    sex: ?string,
    weight: number,
}

export type DonorOrganAdmission = {
    admit_course: string,
    admit_date: ?string,
    cause_of_death: ?string,
    downtime_duration: number,
    type_of_death: ?string,
}

export type DonorOrganTransplant = {
    cross_clamp_date: ?string,
    calculated_current_CIT: number,
    hemodialysis: boolean,
    kdpi: number,
    or_date: ?string,
    pump_number: string,
    warm_ischemia_time: number,
}

export type DonorOrganMedications = {
    medications: string,
    vasopressors: string,
    blood_products: string,
}

export type DonorOrganLabs = {
    hba1c: number,
    serologies: any,
    admit_bun: number,
    admit_creatinine: number,
    current_bun: number,
    current_creatinine: number,
    peak_bun: number,
    peak_creatinine: number,
    ua_performed: number,
    ua_protein_positive: number,

    admit_alt: number,
    admit_ast: number,
    admit_bilirubin: number,
    admit_alk_ph: number,
    admit_inr: number,
    current_alt: number,
    current_ast: number,
    current_bilirubin: number,
    current_alk_ph: number,
    current_inr: number,
    peak_alt: number,
    peak_ast: number,
    peak_bilirubin: number,
    peak_alk_ph: number,
    peak_inr: number,
    ggt: number,
    sodium: number,
}

export type DonorOrganObservations = {
    urine_output: number,
    anuric_time: number,
}

export type DonorOrganHistory = {
    primary_history: string,
    phs_increased_risk: string,
    iv_drug_use: boolean,
    etoh_use: boolean,
    smoking: ?string,
    cancer: boolean,
    hypertension: boolean,
    diabetes: boolean,
}

export type DonorOrganMisc = {
    imaging_details: string,
    biopsy_details: string,
    other_information: string,
}

export type DonorSerologies = {
    other: string,
    CMV?: boolean,
    EBV?: boolean,
    HBV?: boolean,
    HBVNat?: boolean,
    HIV?: boolean,
    HIVNat?: boolean,
    HCV?: boolean,
    HCVNat?: boolean,
}

export type DonorOrganData = {
    donor_id: number,
    organ_id: number,
    organization_id: number,
    user_id: number,
    identification: DonorOrganId,
    demographics: DonorOrganDemographics,
    admission: DonorOrganAdmission,
    transplant: DonorOrganTransplant,
    medications: DonorOrganMedications,
    labs: DonorOrganLabs,
    observations: DonorOrganObservations,
    history: DonorOrganHistory,
    misc: DonorOrganMisc,
};

export const defaultDonorOrganId: DonorOrganId = {
    unos_id: '', match_id: '', organ_type: 'Kidney', opo: '', current_location: '',
};
export const defaultDonorOrganDemog: DonorOrganDemographics = {
    sex: '', age: 0, blood_type: '', bmi: 0, height: 0, weight: 0, race: '', ethnicity: '',
};
export const defaultDonorOrganAdmission: DonorOrganAdmission = {
    admit_date: '', type_of_death: '', downtime_duration: 0, cause_of_death: '', admit_course: '',
};
export const defaultDonorOrganTransplant: DonorOrganTransplant = {
    or_date: '', cross_clamp_date: '', calculated_current_CIT: 0, kdpi: 0, hemodialysis: false, pump_number: '', warm_ischemia_time: 0,
};
export const defaultDonorOrganMedications: DonorOrganMedications = { medications: '', vasopressors: '', blood_products: '', };
export const defaultDonorSerologies: DonorSerologies = { other: '', };
export const defaultDonorOrganLabs: DonorOrganLabs = {
    hba1c: 0,
    serologies: defaultDonorSerologies,
    admit_creatinine: 0,
    current_creatinine: 0,
    peak_creatinine: 0,
    admit_bun: 0,
    current_bun: 0,
    peak_bun: 0,
    ua_performed: 0,
    ua_protein_positive: 0,
    admit_alt: 0,
    current_alt: 0,
    peak_alt: 0,
    admit_ast: 0,
    current_ast: 0,
    peak_ast: 0,
    admit_bilirubin: 0,
    current_bilirubin: 0,
    peak_bilirubin: 0,
    admit_alk_ph: 0,
    current_alk_ph: 0,
    peak_alk_ph: 0,
    admit_inr: 0,
    current_inr: 0,
    peak_inr: 0,
    ggt: 0,
    sodium: 0,
};
export const defaultDonorOrganObservations: DonorOrganObservations = { urine_output: 0, anuric_time: 0, };
export const defaultDonorOrganHistory: DonorOrganHistory = {
    primary_history: '', phs_increased_risk: '', iv_drug_use: false, etoh_use: false, smoking: '', cancer: false, hypertension: false, diabetes: false,
};
export const defaultDonorOrganMisc: DonorOrganMisc = { imaging_details: '', biopsy_details: '', other_information: '', };

export const defaultDonorOrganData: DonorOrganData = {
    donor_id: 0,
    organ_id: 0,
    organization_id: 0,
    user_id: 0,
    identification: defaultDonorOrganId,
    demographics: defaultDonorOrganDemog,
    admission: defaultDonorOrganAdmission,
    transplant: defaultDonorOrganTransplant,
    medications: defaultDonorOrganMedications,
    labs: defaultDonorOrganLabs,
    observations: defaultDonorOrganObservations,
    history: defaultDonorOrganHistory,
    misc: defaultDonorOrganMisc,
};
