import { all, takeLatest } from 'redux-saga/effects';

import verifyApplicationActivationCode, {
    getOrganizationTemplatePreferences,
} from './ActivationSaga';
import {
    getOffer,
    saveDonorOrgan,
    saveRecipient,
    saveCandidate,
} from './ClinicalSaga';

export function* rootActivationSaga() {
    yield all([
        takeLatest('Activation/VERIFY_APPLICATION_ACTIVATION_CODE', verifyApplicationActivationCode),
        takeLatest('Activation/GET_TEMPLATE_PREFERENCES', getOrganizationTemplatePreferences),
        takeLatest('Clinical/GET_OFFER', getOffer),
        takeLatest('Clinical/SAVE_DONOR_ORGAN', saveDonorOrgan),
        takeLatest('Clinical/SAVE_RECIPIENT', saveRecipient),
        takeLatest('Clinical/SAVE_CANDIDATE', saveCandidate)
    ]);
}

const allSagas = [rootActivationSaga];
export default allSagas;
