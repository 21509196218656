// @flow

import React, { PureComponent } from 'react';
import { Col, Row } from 'react-simple-flex-grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import '../../node_modules/react-simple-flex-grid/lib/main.css';

import AdmitPeakCurrentLabs from './AdmitPeakCurrentLabs';
import HighlightedTextField from './HighlightedTextField';

import AskAlanStyles from '../Styles/AskAlanStyles';

type Props = {
    editableData: boolean,
    formData: any,
    donorTemplatePreferences: any,

    handleChange: (prop: string, value: any) => *,

};

class DonorLiver extends PureComponent<Props> {
    handleChange = (prop: any) => (event: any) => {
        this.props.handleChange(prop, event.target.value);
    }

    render() {
        const {
            editableData,
            formData,
            handleChange,
            donorTemplatePreferences,
        } = this.props;

        const templateGuidance = donorTemplatePreferences || {};

        return (
            <div>
                <AdmitPeakCurrentLabs
                    editableData={editableData}
                    labNameDisplay="ALT"
                    labNameSuffix="Alt"
                    labNameUnits="U/L"
                    admitValue={formData.admitAlt}
                    peakValue={formData.peakAlt}
                    currentValue={formData.currentAlt}
                    handleChange={handleChange}
                    donorTemplatePreferences={donorTemplatePreferences}
                    templateFieldSuffix="ALT"
                />
                <AdmitPeakCurrentLabs
                    editableData={editableData}
                    labNameDisplay="AST"
                    labNameSuffix="Ast"
                    labNameUnits="U/L"
                    admitValue={formData.admitAst}
                    peakValue={formData.peakAst}
                    currentValue={formData.currentAst}
                    handleChange={handleChange}
                    donorTemplatePreferences={donorTemplatePreferences}
                    templateFieldSuffix="AST"
                />
                <AdmitPeakCurrentLabs
                    editableData={editableData}
                    labNameDisplay="Bilirubin"
                    labNameSuffix="Bilirubin"
                    labNameUnits="mg/dl"
                    admitValue={formData.admitBilirubin}
                    peakValue={formData.peakBilirubin}
                    currentValue={formData.currentBilirubin}
                    handleChange={handleChange}
                    donorTemplatePreferences={donorTemplatePreferences}
                    templateFieldSuffix="BILIRUBIN"
                />
                <AdmitPeakCurrentLabs
                    editableData={editableData}
                    labNameDisplay="Alkaline Phosphatase"
                    labNameSuffix="AlkPh"
                    labNameUnits="IU/L"
                    admitValue={formData.admitAlkPh}
                    peakValue={formData.peakAlkPh}
                    currentValue={formData.currentAlkPh}
                    handleChange={handleChange}
                    donorTemplatePreferences={donorTemplatePreferences}
                    templateFieldSuffix="ALK_PH"
                />
                <AdmitPeakCurrentLabs
                    editableData={editableData}
                    labNameDisplay="INR"
                    labNameSuffix="Inr"
                    labNameUnits=""
                    admitValue={formData.admitInr}
                    peakValue={formData.peakInr}
                    currentValue={formData.currentInr}
                    handleChange={handleChange}
                    donorTemplatePreferences={donorTemplatePreferences}
                    templateFieldSuffix="INR"
                />
                <Row>
                    <Col span={4}>
                        <HighlightedTextField
                            highlighted={templateGuidance.SODIUM}
                            style={AskAlanStyles.textField}
                            margin="dense"
                            disabled={!editableData}
                            variant="outlined"
                            label="Sodium"
                            type="number"
                            value={formData.sodium}
                            onChange={this.handleChange('sodium')}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mEq/L</InputAdornment>,
                            }}
                        />
                    </Col>
                    <Col span={4}>
                        <HighlightedTextField
                            highlighted={templateGuidance.GGT}
                            style={AskAlanStyles.textField}
                            margin="dense"
                            disabled={!editableData}
                            variant="outlined"
                            label="GGT"
                            type="number"
                            value={formData.ggt}
                            onChange={this.handleChange('ggt')}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">U/L</InputAdornment>,
                            }}
                        />
                    </Col>
                    <Col span={4} />

                </Row>
            </div>
        );
    }
}

export default DonorLiver;
