// @flow

import React, { PureComponent } from 'react';
import { Col, Row } from 'react-simple-flex-grid';
import { HorizontalBar } from 'react-chartjs-2';
import DemogHeader from './DemogHeader';

import type { RiskFactor } from '../Analytics/Predictor';
import {
    predict, getWaitlistMortalityModel, getDeceasedTransplantRateModel, loadCandidateWaitlistRiskFactors, createDisplayName,
}
    from '../Analytics/Predictor';
import combineCandidateData from '../Utils/combineCandidateRecipientData';

import type { DonorOrganData } from '../Types/DonorOrgan';
import type { CandidateData } from '../Types/Candidate';
import type { RecipientData } from '../Types/Recipient';

import AskAlanStyles from '../Styles/AskAlanStyles';

type Props = {
    donorOrganData: DonorOrganData,
    candidateData: CandidateData,
    recipientData: RecipientData,
};

class WaitlistSurvival extends PureComponent<Props> {
    render() {
        const {
            donorOrganData,
            candidateData,
            recipientData,
        } = this.props;

        const showStats = donorOrganData.identification.organ_type === 'Kidney';
        if (!showStats) {
            return (
                <div>
                    {`Not yet available for ${donorOrganData.identification.organ_type}`}
                </div>
            );
        }

        // Merge candidate and recipient data before getting risk factors.
        //   Candidate is from the TC and supercedes recipient where there is overlap.
        const mergedCandidateData = combineCandidateData(recipientData, candidateData);

        // Get predictor values for waitlist mortality
        const waitlistModel = getWaitlistMortalityModel('KIDNEY', 'ADULT');
        const candidateWLRiskFactors: RiskFactor[] = loadCandidateWaitlistRiskFactors(mergedCandidateData, waitlistModel);
        const donorWLRiskFactors: RiskFactor[] = [];
        const waitlistPrediction = predict(waitlistModel, donorWLRiskFactors, candidateWLRiskFactors);

        // Get predictor values for transplant rate
        const transplantRateModel = getDeceasedTransplantRateModel('KIDNEY', 'ADULT');
        // It looks like the factors are the same as waitlist... at least for now.
        const candidateTxRiskFactors: RiskFactor[] = loadCandidateWaitlistRiskFactors(mergedCandidateData, transplantRateModel);
        const donorTxRiskFactors: RiskFactor[] = [];
        const transplantPrediction = predict(waitlistModel, donorTxRiskFactors, candidateTxRiskFactors);


        const transplantRateFactorsApplied = candidateTxRiskFactors.map((rf) => (
            <span key={rf.factorName}>
                <span className="groupLabel">
                    {createDisplayName(rf, 20)}
                    {' : '}
                </span>
                <span>
                    {rf.hazardRatio.toFixed(4)}
                    <br />
                </span>
            </span>
        ));

        const candidateFactorsApplied = candidateWLRiskFactors.map((rf) => (
            <span key={rf.factorName}>
                <span className="groupLabel">
                    {createDisplayName(rf, 20)}
                    {' : '}
                </span>
                <span>
                    {rf.hazardRatio.toFixed(4)}
                    <br />
                </span>
            </span>
        ));

        let specificWaitlistColor = (waitlistPrediction.baselineHazardRatio < waitlistPrediction.specificHazardRatio)
            ? 'rgb(180, 0, 0)' : 'rgb(0,180,0)';
        let specificRateColor = (transplantPrediction.baselineHazardRatio < transplantPrediction.specificHazardRatio)
            ? 'rgb(180, 0, 0)' : 'rgb(0,180,0)';
        if (waitlistPrediction.baselineHazardRatio === waitlistPrediction.specificHazardRatio) specificWaitlistColor = 'rgb(180,180,180)';
        if (transplantPrediction.baselineHazardRatio === transplantPrediction.specificHazardRatio) specificRateColor = 'rgb(180,180,180)';

        const chartData = {
            labels: ['Baseline (waitlist)', 'Specific (waitlist)', 'Baseline (transplant rate)', 'Specific (transplant rate)'],
            datasets: [{
                label: 'SRTR Waitlist Survival Prediction',
                backgroundColor: ['rgb(0, 100, 0)', specificWaitlistColor, 'rgb(0, 100, 0)', specificRateColor],
                data: [waitlistPrediction.baselineHazardRatio, waitlistPrediction.specificHazardRatio,
                    transplantPrediction.baselineHazardRatio, transplantPrediction.specificHazardRatio],
            }],
        };

        return (
            <div>
                <DemogHeader candidateData={candidateData} />
                <h2>Waitlist Survival [Adult]</h2>
                <div style={AskAlanStyles.form}>
                    <span className="groupLabelCenter">Candidate Outcome</span>
                    <Row>
                        <Col span={4} />
                        <Col span={4}><span className="groupLabelCenter">Baseline HR</span></Col>
                        <Col span={4}><span className="groupLabelCenter">Predicted HR</span></Col>
                    </Row>
                    <Row>
                        <Col span={4}><span className="groupLabel">Waitlist Survival</span></Col>
                        <Col span={4}>{waitlistPrediction.baselineHazardRatio.toFixed(4)}</Col>
                        <Col span={4}>{waitlistPrediction.specificHazardRatio.toFixed(4)}</Col>
                    </Row>
                    <Row>
                        <Col span={4}><span className="groupLabel">Transplant Rate</span></Col>
                        <Col span={4}>{transplantPrediction.baselineHazardRatio.toFixed(4)}</Col>
                        <Col span={4}>{transplantPrediction.specificHazardRatio.toFixed(4)}</Col>
                    </Row>
                    <br />
                    <span className="groupLabelCenter">Factors Applied</span>
                    <Row>
                        <Col span={6} className="groupLabelCenter">Waitlist factors</Col>
                        <Col span={6} className="groupLabelCenter">Transplant Rate Factors</Col>
                    </Row>
                    <Row>
                        {
                            candidateFactorsApplied.length === 0
                                ? (<Col span={6}>None</Col>)
                                : (<Col span={6}>{candidateFactorsApplied}</Col>)
                        }
                        {
                            transplantRateFactorsApplied.length === 0
                                ? (<Col span={6}>None</Col>)
                                : (<Col span={6}>{transplantRateFactorsApplied}</Col>)
                        }
                    </Row>
                    <br />
                    <HorizontalBar data={chartData} />
                </div>
            </div>
        );
    }
}

export default WaitlistSurvival;
