/* eslint-disable no-underscore-dangle */
// @flow

import React, { PureComponent } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { Col, Row } from 'react-simple-flex-grid';
import '../../node_modules/react-simple-flex-grid/lib/main.css';
import moment from 'moment';
import HighlightedDatePicker from './HighlightedDatePicker';
import HighlightedSelect from './HighlightedSelect';
import HighlightedTextField from './HighlightedTextField';
import HighlightedDateTimePicker from './HighlightedDateTimePicker';

// import AdmitPeakCurrentLabs from './AdmitPeakCurrentLabs';
import DonorLiver from './DonorLiver';

import type { DonorOrganData } from '../Types/DonorOrgan';
import { renderDateLabel, renderDateTimeLabel } from '../Utils/dateTime';

import AskAlanStyles from '../Styles/AskAlanStyles';

type Props = {
    userOrgId: number,
    donorOrganData: DonorOrganData,
    saveDonorOrganStatus: string,
    donorTemplatePreferences: any,

    onSaveDonorOrganData: (donorOrganData: DonorOrganData, saveToServer: boolean) => *,
    onSetSaveDonorOrganStatus: (status: string, donorId: number, organId: number) => *,

};

type State = {
     errors: {
        unosId: string,
        matchId: string,
        organType: string,
        bmi: string,
        age: string,
        sex: string,
        typeOfDeath: string,
        urineOutput: string,
        admitCreat: string,
        peakCreat: string,
        currentCreat: string,
        phsIncreasedRisk: string,
        vasopressors: string,
        bloodProducts: string,
        imagingDetails: string,
        biopsyDetails: string,
    },
    formData: {
        unosId: string,
        matchId: string,
        organType: string,
        opo: string,
        currentLocation: string,

        sex: string,
        age: number,
        bloodType: string,
        bmi: number,
        weight: number,
        height: number,
        race: string,
        ethnicity: string,

        admitDate: ?string,
        typeOfDeath: string,
        downtime: number,
        causeOfDeath: string,
        admitCourse: string,

        orDate: ?string,
        crossClampDate: ?string,
        calculatedCurrentCIT: number,
        kdpi: number,
        hemodialysis: boolean,
        pumpNumber: string,
        warmIschemiaTime: number,

        medications: string,
        vasopressorUse: boolean,
        vasopressors: string,
        bloodProductUse: boolean,
        bloodProducts: string,

        admitCreat: number,
        peakCreat: number,
        currentCreat: number,
        admitBun: number,
        peakBun: number,
        currentBun: number,
        hba1c: number,
        uaPerformed: number,
        uaProteinPositive: number,

        urineOutput: number,
        anuricTime: number,

        primaryHistory: string,
        phsIncreasedRiskFlag: boolean,
        phsIncreasedRisk: string,
        ivDrugUse: boolean,
        etohUse: boolean,
        smoking: string,
        cancer: boolean,
        hypertension: boolean,
        diabetes: boolean,

        imagesAvailable: boolean,
        imagingDetails: string,
        biopsiesAvailable: boolean,
        biopsyDetails: string,
        otherInformation: string,

        otherSerologies: string,
        CMV: boolean,
        EBV: boolean,
        HBV: boolean,
        HBVNat: boolean,
        HIV: boolean,
        HIVNat: boolean,
        HCV: boolean,
        HCVNat: boolean,

        admitAlt: number,
        peakAlt: number,
        currentAlt: number,
        admitAst: number,
        peakAst: number,
        currentAst: number,
        admitBilirubin: number,
        peakBilirubin: number,
        currentBilirubin: number,
        admitAlkPh: number,
        peakAlkPh: number,
        currentAlkPh: number,
        admitInr: number,
        peakInr: number,
        currentInr: number,

        ggt: number,
        sodium: number,
    },
};

class DonorOrgan extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            errors: {
                unosId: '',
                matchId: '',
                organType: '',
                bmi: '',
                age: '',
                sex: '',
                typeOfDeath: '',
                urineOutput: '',
                admitCreat: '',
                peakCreat: '',
                currentCreat: '',
                phsIncreasedRisk: '',
                vasopressors: '',
                bloodProducts: '',
                imagingDetails: '',
                biopsyDetails: '',
            },
            formData: {
                unosId: this.props.donorOrganData.identification.unos_id || '',
                matchId: this.props.donorOrganData.identification.match_id || '',
                organType: this.props.donorOrganData.identification.organ_type || 'Kidney',
                opo: this.props.donorOrganData.identification.opo || '',
                currentLocation: this.props.donorOrganData.identification.current_location || '',

                sex: this.props.donorOrganData.demographics.sex || 'Please Select',
                age: this.props.donorOrganData.demographics.age,
                bloodType: this.props.donorOrganData.demographics.blood_type || 'Please Select',
                bmi: this.props.donorOrganData.demographics.bmi,
                height: this.props.donorOrganData.demographics.height,
                weight: this.props.donorOrganData.demographics.weight,
                race: this.props.donorOrganData.demographics.race || 'Please Select',
                ethnicity: this.props.donorOrganData.demographics.ethnicity || 'Please Select',

                admitDate: this.props.donorOrganData.admission.admit_date || null,
                typeOfDeath: this.props.donorOrganData.admission.type_of_death || 'Please Select',
                downtime: this.props.donorOrganData.admission.downtime_duration,
                causeOfDeath: this.props.donorOrganData.admission.cause_of_death || 'Please Select',
                admitCourse: this.props.donorOrganData.admission.admit_course || '',

                orDate: this.props.donorOrganData.transplant.or_date || null,
                crossClampDate: this.props.donorOrganData.transplant.cross_clamp_date || null,
                calculatedCurrentCIT: this.props.donorOrganData.transplant.calculated_current_CIT,
                kdpi: this.props.donorOrganData.transplant.kdpi || 0,
                hemodialysis: this.props.donorOrganData.transplant.hemodialysis,
                pumpNumber: this.props.donorOrganData.transplant.pump_number || '',
                warmIschemiaTime: this.props.donorOrganData.transplant.warm_ischemia_time || 0,

                medications: this.props.donorOrganData.medications.medications,
                vasopressorUse: (this.props.donorOrganData.medications.vasopressors || '').length !== 0,
                vasopressors: this.props.donorOrganData.medications.vasopressors || '',
                bloodProductUse: (this.props.donorOrganData.medications.blood_products || '').length !== 0,
                bloodProducts: this.props.donorOrganData.medications.blood_products || '',

                admitCreat: this.props.donorOrganData.labs.admit_creatinine,
                peakCreat: this.props.donorOrganData.labs.peak_creatinine,
                currentCreat: this.props.donorOrganData.labs.current_creatinine,
                admitBun: this.props.donorOrganData.labs.admit_bun,
                peakBun: this.props.donorOrganData.labs.peak_bun,
                currentBun: this.props.donorOrganData.labs.current_bun,
                hba1c: this.props.donorOrganData.labs.hba1c,
                uaPerformed: this.props.donorOrganData.labs.ua_performed,
                uaProteinPositive: this.props.donorOrganData.labs.ua_protein_positive,

                urineOutput: this.props.donorOrganData.observations.urine_output,
                anuricTime: this.props.donorOrganData.observations.anuric_time,

                primaryHistory: this.props.donorOrganData.history.primary_history || '',
                phsIncreasedRisk: this.props.donorOrganData.history.phs_increased_risk || '',
                phsIncreasedRiskFlag: (this.props.donorOrganData.history.phs_increased_risk || '').length !== 0,
                ivDrugUse: this.props.donorOrganData.history.iv_drug_use,
                etohUse: this.props.donorOrganData.history.etoh_use,
                smoking: this.props.donorOrganData.history.smoking || 'Please Select',
                cancer: this.props.donorOrganData.history.cancer,
                hypertension: this.props.donorOrganData.history.hypertension,
                diabetes: this.props.donorOrganData.history.diabetes,

                imagesAvailable: (this.props.donorOrganData.misc.imaging_details || '').length !== 0,
                imagingDetails: this.props.donorOrganData.misc.imaging_details || '',
                biopsiesAvailable: (this.props.donorOrganData.misc.biopsy_details || '').length !== 0,
                biopsyDetails: this.props.donorOrganData.misc.biopsy_details || '',

                otherInformation: this.props.donorOrganData.misc.other_information || '',

                otherSerologies: this.props.donorOrganData.labs.serologies.other || '',
                CMV: this.props.donorOrganData.labs.serologies.CMV || false,
                EBV: this.props.donorOrganData.labs.serologies.EBV || false,
                HBV: this.props.donorOrganData.labs.serologies.HBV || false,
                HBVNat: this.props.donorOrganData.labs.serologies.HBVNat || false,
                HCV: this.props.donorOrganData.labs.serologies.HCV || false,
                HCVNat: this.props.donorOrganData.labs.serologies.HCVNat || false,
                HIV: this.props.donorOrganData.labs.serologies.HIV || false,
                HIVNat: this.props.donorOrganData.labs.serologies.HIVNat || false,

                // Liver Data
                admitAlt: this.props.donorOrganData.labs.admit_alt || 0,
                peakAlt: this.props.donorOrganData.labs.peak_alt || 0,
                currentAlt: this.props.donorOrganData.labs.current_alt || 0,
                admitAst: this.props.donorOrganData.labs.admit_ast || 0,
                peakAst: this.props.donorOrganData.labs.peak_ast || 0,
                currentAst: this.props.donorOrganData.labs.current_ast || 0,
                admitBilirubin: this.props.donorOrganData.labs.admit_bilirubin || 0,
                peakBilirubin: this.props.donorOrganData.labs.peak_bilirubin || 0,
                currentBilirubin: this.props.donorOrganData.labs.current_bilirubin || 0,
                admitAlkPh: this.props.donorOrganData.labs.admit_alk_ph || 0,
                peakAlkPh: this.props.donorOrganData.labs.peak_alk_ph || 0,
                currentAlkPh: this.props.donorOrganData.labs.current_alk_ph || 0,
                admitInr: this.props.donorOrganData.labs.admit_inr || 0,
                peakInr: this.props.donorOrganData.labs.peak_inr || 0,
                currentInr: this.props.donorOrganData.labs.current_inr || 0,

                ggt: this.props.donorOrganData.labs.ggt || 0,
                sodium: this.props.donorOrganData.labs.sodium || 0,
            },
        };
    }

    componentWillUnmount() {
        // If this is 'my data' then save. If not (e.g. TC viewing data entered by OPO then don't save)
        if (this.props.donorOrganData.organization_id === 0 || this.props.userOrgId === this.props.donorOrganData.organization_id) {
            const donorOrganData = this.getDonorOrganData();
            this.props.onSaveDonorOrganData(donorOrganData, false);
            this.props.onSetSaveDonorOrganStatus('', donorOrganData.donor_id, donorOrganData.organ_id);
        }
    }

    setError = (fieldName: string, errorMessage: string) => {
        this.setState((prevState) => {
            const errors = { ...prevState.errors, };
            errors[fieldName] = errorMessage;
            return { errors, };
        });
        return 1;
    };

    getDonorOrganData = (): DonorOrganData => {
        const form = this.state.formData;
        let admitDateStr = '';
        if (form.admitDate !== null) {
            admitDateStr = moment(form.admitDate).toISOString();
        }
        let orDateStr = '';
        if (form.orDate !== null) {
            orDateStr = moment(form.orDate).toISOString();
        }
        let crossClampDateStr = '';
        if (form.crossClampDate !== null) {
            crossClampDateStr = moment(form.crossClampDate).toISOString();
        }

        const donorOrganData: DonorOrganData = {
            donor_id: this.props.donorOrganData.donor_id,
            organ_id: this.props.donorOrganData.organ_id,
            organization_id: this.props.donorOrganData.organization_id,
            user_id: this.props.donorOrganData.user_id,
            identification: {
                unos_id: form.unosId,
                match_id: form.matchId,
                organ_type: form.organType,
                opo: form.opo,
                current_location: form.currentLocation,
            },
            demographics: {
                sex: form.sex === 'Please Select' ? null : form.sex,
                age: Number(form.age),
                blood_type: form.bloodType === 'Please Select' ? null : form.bloodType,
                bmi: Number(form.bmi),
                height: Number(form.height),
                weight: Number(form.weight),
                race: form.race === 'Please Select' ? null : form.race,
                ethnicity: form.ethnicity === 'Please Select' ? null : form.ethnicity,
            },
            admission: {
                admit_date: admitDateStr === '' ? null : admitDateStr,
                type_of_death: form.typeOfDeath === 'Please Select' ? null : form.typeOfDeath,
                downtime_duration: Number(form.downtime),
                cause_of_death: form.causeOfDeath === 'Please Select' ? null : form.causeOfDeath,
                admit_course: form.admitCourse,
            },
            transplant: {
                or_date: orDateStr === '' ? null : orDateStr,
                cross_clamp_date: crossClampDateStr === '' ? null : crossClampDateStr,
                calculated_current_CIT: form.calculatedCurrentCIT,
                kdpi: Number(form.kdpi),
                hemodialysis: form.hemodialysis,
                pump_number: form.pumpNumber,
                warm_ischemia_time: Number(form.warmIschemiaTime),
            },
            medications: {
                medications: form.medications,
                vasopressors: form.vasopressors,
                blood_products: form.bloodProducts,
            },
            labs: {
                serologies: {
                    other: form.otherSerologies,
                    CMV: form.CMV,
                    EBV: form.EBV,
                    HBV: form.HBV,
                    HBVNat: form.HBVNat,
                    HIV: form.HIV,
                    HIVNat: form.HIVNat,
                    HCV: form.HCV,
                    HCVNat: form.HCVNat,
                },

                admit_creatinine: Number(form.admitCreat),
                peak_creatinine: Number(form.peakCreat),
                current_creatinine: Number(form.currentCreat),
                admit_bun: Number(form.admitBun),
                peak_bun: Number(form.peakBun),
                current_bun: Number(form.currentBun),
                admit_alt: Number(form.admitAlt),
                peak_alt: Number(form.peakAlt),
                current_alt: Number(form.currentAlt),
                admit_ast: Number(form.admitAst),
                peak_ast: Number(form.peakAst),
                current_ast: Number(form.currentAst),
                admit_bilirubin: Number(form.admitBilirubin),
                peak_bilirubin: Number(form.peakBilirubin),
                current_bilirubin: Number(form.currentBilirubin),
                admit_alk_ph: Number(form.admitAlkPh),
                peak_alk_ph: Number(form.peakAlkPh),
                current_alk_ph: Number(form.currentAlkPh),
                admit_inr: Number(form.admitInr),
                peak_inr: Number(form.peakInr),
                current_inr: Number(form.currentInr),
                ggt: Number(form.ggt),
                sodium: Number(form.sodium),
                hba1c: Number(form.hba1c),
                ua_performed: Number(form.uaPerformed),
                ua_protein_positive: Number(form.uaProteinPositive),
            },
            observations: {
                urine_output: Number(form.urineOutput),
                anuric_time: Number(form.anuricTime),
            },
            history: {
                primary_history: form.primaryHistory,
                phs_increased_risk: form.phsIncreasedRisk,
                iv_drug_use: form.ivDrugUse,
                etoh_use: form.etohUse,
                smoking: form.smoking === 'Please Select' ? null : form.smoking,
                cancer: form.cancer,
                diabetes: form.diabetes,
                hypertension: form.hypertension,
            },
            misc: {
                imaging_details: form.imagingDetails,
                biopsy_details: form.biopsyDetails,
                other_information: form.otherInformation,
            },
        };

        if (form.CMV) donorOrganData.labs.serologies.CMV = true;
        if (form.EBV) donorOrganData.labs.serologies.EBV = true;
        if (form.HBV) donorOrganData.labs.serologies.HBV = true;
        if (form.HBVNat) donorOrganData.labs.serologies.HBVNat = true;
        if (form.HCV) donorOrganData.labs.serologies.HCV = true;
        if (form.HCVNat) donorOrganData.labs.serologies.HCVNat = true;
        if (form.HIV) donorOrganData.labs.serologies.HIV = true;
        if (form.HIVNat) donorOrganData.labs.serologies.HIVNat = true;

        return donorOrganData;
    }

    clearErrors = () => {
        this.setState((prevState) => {
            const errors = { ...prevState.errors, };
            errors.unosId = '';
            errors.matchId = '';
            errors.organType = '';
            errors.age = '';
            errors.bmi = '';
            errors.sex = '';
            errors.typeOfDeath = '';
            errors.urineOutput = '';
            errors.admitCreat = '';
            errors.peakCreat = '';
            errors.currentCreat = '';
            errors.phsIncreasedRisk = '';
            errors.vasopressors = '';
            errors.bloodProducts = '';
            errors.imagingDetails = '';
            errors.biopsyDetails = '';
            return { errors, };
        });
    };

    handleChange = (prop: any) => (event: any) => {
        let newValue = '';
        if (event._isAMomentObject) {
            newValue = event._d;
        } else {
            newValue = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
        }
        if (prop === 'crossClampDate') {
            this.updateChange('calculatedCurrentCIT', Number(((moment() - event._d) / 60000).toFixed(0)));
        }

        this.updateChange(prop, newValue);
    };

    // Update change is used by embedded components (AdmitPeakCurrentLabs)
    // Partly because of trouble getting sub comps to trigger handleChange. Consider reworking.
    updateChange = (prop: string, newValue: any) => {
        this.setState((prevState) => {
            const formData = { ...prevState.formData, };
            formData[prop] = newValue;
            if (prop === 'bmi') {
                formData.height = 0;
                formData.weight = 0;
            }

            if (prop === 'height' || prop === 'weight') {
                formData.bmi = 0;
                const w = formData.weight;
                const h = formData.height;
                if ((w > 0) && (h > 0)) {
                    formData.bmi = Number((w / ((h / 100) ** 2)).toFixed(1));
                }
            }

            return { formData, };
        });
    };

    saveRecord = () => {
        // update the donor object first
        const donorOrganData = this.getDonorOrganData();
        this.props.onSaveDonorOrganData(donorOrganData, false);

        this.clearErrors();
        const form = this.state.formData;
        let errorCnt = 0;

        // System required fields
        if (form.unosId.length === 0) errorCnt += this.setError('unosId', 'Must be entered');
        if (form.matchId.length === 0) errorCnt += this.setError('matchId', 'Must be entered');
        if (form.organType === 'Please Select') errorCnt += this.setError('organType', 'Must be entered');

        // Fields required for consistency
        if (form.vasopressorUse && form.vasopressors.length === 0) errorCnt += this.setError('vasopressors', 'Must be entered if Pressor Support selected');
        if (form.bloodProductUse && form.bloodProducts.length === 0) errorCnt += this.setError('bloodProducts', 'Must be entered if Blood Products selected');
        if (form.imagesAvailable && form.imagingDetails.length === 0) errorCnt += this.setError('imagingDetails', 'Must be entered if Imaging selected');
        if (form.biopsiesAvailable && form.biopsyDetails.length === 0) errorCnt += this.setError('biopsyDetails', 'Must be entered if Biopsies selected');

        if (errorCnt > 0) return;

        this.props.onSaveDonorOrganData(donorOrganData, true);
    };

    render() {
        const {
            formData,
        } = this.state;

        const {
            userOrgId,
            donorOrganData,
            saveDonorOrganStatus,
            donorTemplatePreferences,
        } = this.props;

        const templateGuidance = donorTemplatePreferences || {};
        const editableKeys = (this.props.donorOrganData.organ_id === 0);
        const editableData = ((donorOrganData.organization_id === 0) || (userOrgId === donorOrganData.organization_id));

        return (
            <div>
                <div style={AskAlanStyles.form}>
                    <span className="groupLabel">ID</span>
                    <Row>
                        <Col span="4">
                            <HighlightedTextField
                                highlighted={templateGuidance.UNOS_ID}
                                style={AskAlanStyles.textField}
                                disabled={!editableKeys}
                                margin="dense"
                                variant="outlined"
                                error={this.state.errors.unosId.length !== 0}
                                helperText={this.state.errors.unosId}
                                label="UNOS ID"
                                autoFocus
                                value={formData.unosId}
                                onChange={this.handleChange('unosId')}
                            />
                        </Col>
                        <Col span="4">
                            <HighlightedTextField
                                highlighted={templateGuidance.MATCH_ID}
                                style={AskAlanStyles.textField}
                                disabled={!editableKeys}
                                margin="dense"
                                variant="outlined"
                                error={this.state.errors.matchId.length !== 0}
                                helperText={this.state.errors.matchId}
                                label="Match ID"
                                value={formData.matchId}
                                onChange={this.handleChange('matchId')}
                            />
                        </Col>
                        <Col span="4">
                            <HighlightedSelect
                                highlighted={templateGuidance.ORGAN_TYPE}
                                fullWidth
                                value={formData.organType}
                                variant="outlined"
                                label="Organ Type"
                                error={this.state.errors.organType.length !== 0}
                                onChange={this.handleChange('organType')}
                                disabled={!editableData}
                                style={AskAlanStyles.textField}
                                margin="dense"
                                options={[
                                    { value: 'Please Select', text: 'Please Select', },
                                    { value: 'Kidney', text: 'Kidney', },
                                    { value: 'Liver', text: 'Liver', }
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span="6">
                            <HighlightedTextField
                                style={AskAlanStyles.textField}
                                disabled={!editableData}
                                margin="dense"
                                highlighted={templateGuidance.OPO}
                                variant="outlined"
                                label="OPO"
                                value={formData.opo}
                                onChange={this.handleChange('opo')}
                            />
                        </Col>
                        <Col span="6">
                            <HighlightedTextField
                                style={AskAlanStyles.textField}
                                disabled={!editableData}
                                margin="dense"
                                highlighted={templateGuidance.CURRENT_LOCATION}
                                variant="outlined"
                                label="Location"
                                value={formData.currentLocation}
                                onChange={this.handleChange('currentLocation')}
                            />
                        </Col>
                    </Row>
                    <span className="groupLabel">Demographic</span>
                    <Row>
                        <Col span="4">
                            <HighlightedSelect
                                highlighted={templateGuidance.SEX}
                                fullWidth
                                value={formData.sex}
                                variant="outlined"
                                label="Sex"
                                error={this.state.errors.sex.length !== 0}
                                onChange={this.handleChange('sex')}
                                disabled={!editableData}
                                style={AskAlanStyles.textField}
                                margin="dense"
                                options={[
                                    { value: 'Please Select', text: 'Please Select', },
                                    { value: 'Male', text: 'Male', },
                                    { value: 'Female', text: 'Female', }
                                ]}
                            />
                        </Col>
                        <Col span="4">
                            <HighlightedTextField
                                highlighted={templateGuidance.AGE}
                                style={AskAlanStyles.textField}
                                disabled={!editableData}
                                margin="dense"
                                variant="outlined"
                                label="Age"
                                error={this.state.errors.age.length !== 0}
                                type="number"
                                value={formData.age}
                                onChange={this.handleChange('age')}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">years</InputAdornment>,
                                }}
                            />
                        </Col>
                        <Col span={4}>
                            <HighlightedSelect
                                highlighted={templateGuidance.BLOOD_TYPE}
                                fullWidth
                                value={formData.bloodType}
                                variant="outlined"
                                label="Blood Type"
                                onChange={this.handleChange('bloodType')}
                                disabled={!editableData}
                                style={AskAlanStyles.textField}
                                margin="dense"
                                options={[
                                    { value: 'Please Select', text: 'Please Select', },
                                    { value: 'A', text: 'A', },
                                    { value: 'B', text: 'B', },
                                    { value: 'AB', text: 'AB', },
                                    { value: 'O', text: 'O', }
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={4}>
                            <HighlightedTextField
                                highlighted={templateGuidance.BMI}
                                style={AskAlanStyles.textField}
                                disabled={!editableData}
                                margin="dense"
                                variant="outlined"
                                label="BMI"
                                error={this.state.errors.bmi.length !== 0}
                                type="number"
                                value={formData.bmi}
                                onChange={this.handleChange('bmi')}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">kg/m²</InputAdornment>,
                                }}
                            />
                        </Col>
                        <Col span={4}>
                            <HighlightedTextField
                                highlighted={templateGuidance.HEIGHT}
                                style={AskAlanStyles.textField}
                                disabled={!editableData}
                                margin="dense"
                                variant="outlined"
                                label="Height"
                                type="number"
                                value={formData.height}
                                onChange={this.handleChange('height')}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                }}
                            />
                        </Col>
                        <Col span={4}>
                            <HighlightedTextField
                                highlighted={templateGuidance.WEIGHT}
                                style={AskAlanStyles.textField}
                                disabled={!editableData}
                                margin="dense"
                                variant="outlined"
                                label="Weight"
                                type="number"
                                value={formData.weight}
                                onChange={this.handleChange('weight')}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span="6">
                            <HighlightedSelect
                                highlighted={templateGuidance.RACE}
                                value={formData.race}
                                variant="outlined"
                                label="Race"
                                onChange={this.handleChange('race')}
                                disabled={!editableData}
                                style={AskAlanStyles.textField}
                                margin="dense"
                                options={[
                                    { value: 'Please Select', text: 'Please Select', },
                                    { value: 'Multiracial', text: 'Multiracial', },
                                    { value: 'Asian', text: 'Asian', },
                                    { value: 'Black', text: 'Black', },
                                    { value: 'Native American', text: 'Native American', },
                                    { value: 'White', text: 'White', },
                                    { value: 'Unknown', text: 'Unknown', }
                                ]}
                            />
                        </Col>
                        <Col span="6">
                            <HighlightedSelect
                                highlighted={templateGuidance.ETHNICITY}
                                value={formData.ethnicity}
                                variant="outlined"
                                label="Ethnicity"
                                onChange={this.handleChange('ethnicity')}
                                disabled={!editableData}
                                style={AskAlanStyles.textField}
                                margin="dense"
                                options={[
                                    { value: 'Please Select', text: 'Please Select', },
                                    { value: 'Latino', text: 'Latino', },
                                    { value: 'Non-Latino', text: 'Non-Latino', },
                                    { value: 'Unknown', text: 'Unknown', }
                                ]}
                            />
                        </Col>
                    </Row>
                    <span className="groupLabel">Admission</span>
                    <Row>
                        <Col span={6}>
                            <HighlightedDatePicker
                                highlighted={templateGuidance.ADMIT_DATE}
                                style={AskAlanStyles.textField}
                                margin="dense"
                                disabled={!editableData}
                                variant="outlined"
                                label="Admit Date"
                                value={formData.admitDate}
                                onChange={this.handleChange('admitDate')}
                                labelFunc={renderDateLabel}
                            />
                        </Col>
                        <Col span={6}>
                            <HighlightedSelect
                                highlighted={templateGuidance.CAUSE_OF_DEATH}
                                value={formData.causeOfDeath}
                                variant="outlined"
                                label="Cause of Death"
                                onChange={this.handleChange('causeOfDeath')}
                                disabled={!editableData}
                                style={AskAlanStyles.textField}
                                margin="dense"
                                options={[
                                    { value: 'Please Select', text: 'Please Select', },
                                    { value: 'Anoxia', text: 'Anoxia', },
                                    { value: 'Cerebrovascular/Stroke', text: 'Cerebrovascular/Stroke', },
                                    { value: 'CNS Tumor', text: 'CNS Tumor', },
                                    { value: 'Head Trauma', text: 'Head Trauma', },
                                    { value: 'Other', text: 'Other', },
                                    { value: 'Unknown', text: 'Unknown', }
                                ]}
                            />
                        </Col>
                    </Row>
                    <HighlightedTextField
                        highlighted={templateGuidance.ADMIT_COURSE}
                        style={AskAlanStyles.textField}
                        disabled={!editableData}
                        margin="dense"
                        variant="outlined"
                        label="Admit Course"
                        multiline
                        rowsMax="4"
                        value={formData.admitCourse}
                        onChange={this.handleChange('admitCourse')}
                    />
                    <Row>
                        <Col span={6}>
                            <HighlightedSelect
                                highlighted={templateGuidance.TYPE_OF_DEATH}
                                value={formData.typeOfDeath}
                                variant="outlined"
                                label="Type of Death"
                                error={this.state.errors.typeOfDeath.length !== 0}
                                onChange={this.handleChange('typeOfDeath')}
                                disabled={!editableData}
                                style={AskAlanStyles.textField}
                                margin="dense"
                                options={[
                                    { value: 'Please Select', text: 'Please Select', },
                                    { value: 'DCD', text: 'DCD', },
                                    { value: 'BD', text: 'BD', }
                                ]}
                            />
                        </Col>
                        <Col span={6}>
                            {formData.typeOfDeath === 'DCD'
                                ? (
                                    <HighlightedTextField
                                        highlighted={templateGuidance.DOWNTIME_DURATION}
                                        margin="dense"
                                        disabled={!editableData}
                                        style={AskAlanStyles.textField}
                                        variant="outlined"
                                        label="Cardiac Down Time"
                                        value={formData.downtime}
                                        onChange={this.handleChange('downtime')}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">minutes</InputAdornment>,
                                        }}
                                    />
                                ) : <div />}
                        </Col>
                    </Row>
                    <span className="groupLabel">Transplant</span>
                    <HighlightedDateTimePicker
                        highlighted={templateGuidance.OR_DATE}
                        style={AskAlanStyles.textField}
                        disabled={!editableData}
                        margin="dense"
                        variant="outlined"
                        label="OR Date"
                        showTodayButton
                        value={formData.orDate}
                        onChange={this.handleChange('orDate')}
                        labelFunc={renderDateTimeLabel}
                    />
                    <Row>
                        <Col span={4}>
                            <HighlightedDateTimePicker
                                highlighted={templateGuidance.CROSS_CLAMP_DATE}
                                style={AskAlanStyles.textField}
                                disabled={!editableData}
                                margin="dense"
                                variant="outlined"
                                label="Cross Clamp Time"
                                showTodayButton
                                value={formData.crossClampDate}
                                onChange={this.handleChange('crossClampDate')}
                                labelFunc={renderDateTimeLabel}
                            />
                        </Col>
                        <Col span={4}>
                            <HighlightedTextField
                                margin="dense"
                                style={AskAlanStyles.textField}
                                variant="outlined"
                                label="Calc CIT"
                                value={formData.calculatedCurrentCIT}
                                onChange={this.handleChange('calculatedCurrentCIT')}
                                disabled
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">minutes</InputAdornment>,
                                }}
                            />
                        </Col>
                        <Col span={4}>
                            <HighlightedTextField
                                highlighted={templateGuidance.WARM_ISCHEMIA_TIME}
                                margin="dense"
                                disabled={!editableData}
                                style={AskAlanStyles.textField}
                                variant="outlined"
                                label="WIT"
                                type="number"
                                value={formData.warmIschemiaTime}
                                onChange={this.handleChange('warmIschemiaTime')}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">minutes</InputAdornment>,
                                }}
                            />
                        </Col>
                    </Row>
                    {formData.organType === 'Kidney' ? (
                        <Row>
                            <Col span={4}>
                                <HighlightedTextField
                                    highlighted={templateGuidance.KDPI}
                                    margin="dense"
                                    disabled={!editableData}
                                    style={AskAlanStyles.textField}
                                    label="KDPI"
                                    variant="outlined"
                                    type="number"
                                    value={formData.kdpi}
                                    onChange={this.handleChange('kdpi')}
                                />
                            </Col>
                            <Col span={4}>
                                <HighlightedTextField
                                    highlighted={templateGuidance.PUMP_NUMBER}
                                    margin="dense"
                                    disabled={!editableData}
                                    style={AskAlanStyles.textField}
                                    variant="outlined"
                                    label="Pumps"
                                    value={formData.pumpNumber}
                                    onChange={this.handleChange('pumpNumber')}
                                />
                            </Col>
                            <Col span={1} />
                            <Col span={3}>
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            style={AskAlanStyles.margin}
                                            disabled={!editableData}
                                            checked={formData.hemodialysis}
                                            onChange={this.handleChange('hemodialysis')}
                                            value="hemodialysis"
                                            color="primary"
                                        />
                                    )}
                                    label="On HD"
                                />
                            </Col>
                        </Row>
                    ) : ''}
                    <span className="groupLabel">History</span>
                    <HighlightedTextField
                        highlighted={templateGuidance.PRIMARY_HISTORY}
                        style={AskAlanStyles.textField}
                        disabled={!editableData}
                        margin="dense"
                        variant="outlined"
                        multiline
                        rowsMax="4"
                        label="Past Medical History"
                        value={formData.primaryHistory}
                        onChange={this.handleChange('primaryHistory')}
                    />
                    <Row>
                        <Col span={6}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        style={AskAlanStyles.margin}
                                        disabled={!editableData}
                                        checked={formData.phsIncreasedRiskFlag}
                                        onChange={this.handleChange('phsIncreasedRiskFlag')}
                                        value="phsIncreasedRiskFlag"
                                        color="primary"
                                    />
                                )}
                                label="PHS Inc. Risk"
                            />
                        </Col>
                        <Col span={6}>
                            {formData.phsIncreasedRiskFlag
                                ? (
                                    <HighlightedTextField
                                        highlighted={templateGuidance.PHS_INCREASED_RISK}
                                        margin="dense"
                                        disabled={!editableData}
                                        style={AskAlanStyles.textField}
                                        error={this.state.errors.phsIncreasedRisk.length !== 0}
                                        helperText={this.state.errors.phsIncreasedRisk}
                                        variant="outlined"
                                        label="Reason"
                                        value={formData.phsIncreasedRisk}
                                        onChange={this.handleChange('phsIncreasedRisk')}
                                    />
                                ) : <div />}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={4}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        style={AskAlanStyles.margin}
                                        disabled={!editableData}
                                        checked={formData.diabetes}
                                        onChange={this.handleChange('diabetes')}
                                        value="diabetes"
                                        color="primary"
                                    />
                                )}
                                label="Hist Diabetes"
                            />
                        </Col>
                        <Col span={4}>
                            {formData.diabetes
                                ? (
                                    <HighlightedTextField
                                        highlighted={templateGuidance.HBA1C}
                                        margin="dense"
                                        disabled={!editableData}
                                        style={AskAlanStyles.textField}
                                        variant="outlined"
                                        label="HBA1C"
                                        value={formData.hba1c}
                                        onChange={this.handleChange('hba1c')}
                                    />
                                ) : <div />}
                        </Col>
                        <Col span={4}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        style={AskAlanStyles.margin}
                                        disabled={!editableData}
                                        checked={formData.hypertension}
                                        onChange={this.handleChange('hypertension')}
                                        value="hypertension"
                                        color="primary"
                                    />
                                )}
                                label="Hist HTN"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        style={AskAlanStyles.margin}
                                        disabled={!editableData}
                                        checked={formData.ivDrugUse}
                                        onChange={this.handleChange('ivDrugUse')}
                                        value="ivDrugUse"
                                        color="primary"
                                    />
                                )}
                                label="IV Drug User"
                            />
                        </Col>
                        <Col span={6}>
                            <HighlightedSelect
                                highlighted={templateGuidance.SMOKING}
                                value={formData.smoking}
                                variant="outlined"
                                label="Hist Smoking"
                                onChange={this.handleChange('smoking')}
                                disabled={!editableData}
                                style={AskAlanStyles.textField}
                                margin="dense"
                                options={[
                                    { value: 'Please Select', text: 'Please Select', },
                                    { value: 'Current', text: 'Current', },
                                    { value: 'Former', text: 'Former', },
                                    { value: 'Never', text: 'Never', },
                                    { value: 'Unknown', text: 'Unknown', }
                                ]}
                            />
                        </Col>
                        <Col span={4}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        style={AskAlanStyles.margin}
                                        disabled={!editableData}
                                        checked={formData.etohUse}
                                        onChange={this.handleChange('etohUse')}
                                        value="etohUse"
                                        color="primary"
                                    />
                                )}
                                label="ETOH Use"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        style={AskAlanStyles.margin}
                                        checked={formData.cancer}
                                        disabled={!editableData}
                                        onChange={this.handleChange('cancer')}
                                        value="cancer"
                                        color="primary"
                                    />
                                )}
                                label="Hist Cancer"
                            />
                        </Col>
                    </Row>
                    <span className="groupLabel">Medications</span>
                    <Row>
                        <Col span={4}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        style={AskAlanStyles.margin}
                                        disabled={!editableData}
                                        checked={formData.vasopressorUse}
                                        onChange={this.handleChange('vasopressorUse')}
                                        value="vasopressorUse"
                                        color="primary"
                                    />
                                )}
                                label="Pressor Support"
                            />
                        </Col>
                        <Col span={8}>
                            {formData.vasopressorUse
                                ? (
                                    <HighlightedTextField
                                        highlighted={templateGuidance.VASOPRESSORS}
                                        margin="dense"
                                        disabled={!editableData}
                                        style={AskAlanStyles.textField}
                                        variant="outlined"
                                        error={this.state.errors.vasopressors.length !== 0}
                                        helperText={this.state.errors.vasopressors}
                                        label="Details"
                                        value={formData.vasopressors}
                                        onChange={this.handleChange('vasopressors')}
                                    />
                                ) : <div />}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={4}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        style={AskAlanStyles.margin}
                                        disabled={!editableData}
                                        checked={formData.bloodProductUse}
                                        onChange={this.handleChange('bloodProductUse')}
                                        value="bloodProductUse"
                                        color="primary"
                                    />
                                )}
                                label="Blood Products"
                            />
                        </Col>
                        <Col span={8}>
                            {formData.bloodProductUse
                                ? (
                                    <HighlightedTextField
                                        highlighted={templateGuidance.BLOOD_PRODUCTS}
                                        margin="dense"
                                        disabled={!editableData}
                                        style={AskAlanStyles.textField}
                                        variant="outlined"
                                        label="Details"
                                        error={this.state.errors.bloodProducts.length !== 0}
                                        helperText={this.state.errors.bloodProducts}
                                        value={formData.bloodProducts}
                                        onChange={this.handleChange('bloodProducts')}
                                    />
                                ) : <div />}
                        </Col>
                    </Row>
                    <HighlightedTextField
                        highlighted={templateGuidance.MEDICATIONS}
                        style={AskAlanStyles.textField}
                        margin="dense"
                        variant="outlined"
                        disabled={!editableData}
                        label="Other Medications"
                        multiline
                        rowsMax="4"
                        value={formData.medications}
                        onChange={this.handleChange('medications')}
                    />
                    <span className="groupLabel">Labs</span>
                    {formData.organType === 'Liver' ? (
                        <DonorLiver
                            editableData={editableData}
                            formData={formData}
                            donorTemplatePreferences={donorTemplatePreferences}
                            handleChange={this.updateChange}
                        />
                    ) : (
                        <div>
                            <span className="groupLabelCenter">Creatinine</span>
                            <Row>
                                <Col span={4}>
                                    <HighlightedTextField
                                        highlighted={templateGuidance.ADMIT_CREATININE}
                                        style={AskAlanStyles.textField}
                                        margin="dense"
                                        disabled={!editableData}
                                        variant="outlined"
                                        label="Admit"
                                        error={this.state.errors.admitCreat.length !== 0}
                                        type="number"
                                        value={formData.admitCreat}
                                        onChange={this.handleChange('admitCreat')}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
                                        }}
                                    />
                                </Col>
                                <Col span={4}>
                                    <HighlightedTextField
                                        highlighted={templateGuidance.PEAK_CREATININE}
                                        style={AskAlanStyles.textField}
                                        margin="dense"
                                        disabled={!editableData}
                                        variant="outlined"
                                        label="Peak"
                                        error={this.state.errors.peakCreat.length !== 0}
                                        type="number"
                                        value={formData.peakCreat}
                                        onChange={this.handleChange('peakCreat')}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
                                        }}
                                    />
                                </Col>
                                <Col span={4}>
                                    <HighlightedTextField
                                        highlighted={templateGuidance.CURRENT_CREATININE}
                                        style={AskAlanStyles.textField}
                                        margin="dense"
                                        disabled={!editableData}
                                        variant="outlined"
                                        label="Current"
                                        error={this.state.errors.currentCreat.length !== 0}
                                        type="number"
                                        value={formData.currentCreat}
                                        onChange={this.handleChange('currentCreat')}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
                                        }}
                                    />
                                </Col>
                            </Row>
                            <span className="groupLabelCenter">BUN</span>
                            <Row>
                                <Col span={4}>
                                    <HighlightedTextField
                                        highlighted={templateGuidance.ADMIT_BUN}
                                        style={AskAlanStyles.textField}
                                        margin="dense"
                                        disabled={!editableData}
                                        variant="outlined"
                                        label="Admit"
                                        type="number"
                                        value={formData.admitBun}
                                        onChange={this.handleChange('admitBun')}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
                                        }}
                                    />
                                </Col>
                                <Col span={4}>
                                    <HighlightedTextField
                                        highlighted={templateGuidance.PEAK_BUN}
                                        style={AskAlanStyles.textField}
                                        margin="dense"
                                        variant="outlined"
                                        disabled={!editableData}
                                        label="Peak"
                                        type="number"
                                        value={formData.peakBun}
                                        onChange={this.handleChange('peakBun')}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
                                        }}
                                    />
                                </Col>
                                <Col span={4}>
                                    <HighlightedTextField
                                        highlighted={templateGuidance.CURRENT_BUN}
                                        style={AskAlanStyles.textField}
                                        margin="dense"
                                        disabled={!editableData}
                                        variant="outlined"
                                        label="Current"
                                        type="number"
                                        value={formData.currentBun}
                                        onChange={this.handleChange('currentBun')}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
                                        }}
                                    />
                                </Col>
                            </Row>
                            <span className="groupLabelCenter">Urinalysis</span>
                            <Row>
                                <Col span={6}>
                                    <HighlightedTextField
                                        highlighted={templateGuidance.UA_PERFORMED}
                                        style={AskAlanStyles.textField}
                                        disabled={!editableData}
                                        margin="dense"
                                        variant="outlined"
                                        label="Performed"
                                        type="number"
                                        value={formData.uaPerformed}
                                        onChange={this.handleChange('uaPerformed')}
                                    />
                                </Col>
                                <Col span={6}>
                                    <HighlightedTextField
                                        highlighted={templateGuidance.UA_PROTEIN_POSITIVE}
                                        style={AskAlanStyles.textField}
                                        disabled={!editableData}
                                        margin="dense"
                                        variant="outlined"
                                        label="Positive"
                                        type="number"
                                        value={formData.uaProteinPositive}
                                        onChange={this.handleChange('uaProteinPositive')}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                    <span className="groupLabelCenter">Serologies</span>
                    <Row>
                        <Col span={3}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        style={AskAlanStyles.margin}
                                        disabled={!editableData}
                                        checked={formData.CMV}
                                        onChange={this.handleChange('CMV')}
                                        value="CMV"
                                        color="primary"
                                    />
                                )}
                                label="CMV"
                            />
                        </Col>
                        <Col span={3}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        style={AskAlanStyles.margin}
                                        disabled={!editableData}
                                        checked={formData.EBV}
                                        onChange={this.handleChange('EBV')}
                                        value="EBV"
                                        color="primary"
                                    />
                                )}
                                label="EBV"
                            />
                        </Col>
                        <Col span={3}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        style={AskAlanStyles.margin}
                                        disabled={!editableData}
                                        checked={formData.HIV}
                                        onChange={this.handleChange('HIV')}
                                        value="HIV"
                                        color="primary"
                                    />
                                )}
                                label="HIV"
                            />
                        </Col>
                        <Col span={3}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        style={AskAlanStyles.margin}
                                        disabled={!editableData}
                                        checked={formData.HIVNat}
                                        onChange={this.handleChange('HIVNat')}
                                        value="HIVNat"
                                        color="primary"
                                    />
                                )}
                                label="HIV-Nat"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={3}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        style={AskAlanStyles.margin}
                                        checked={formData.HBV}
                                        disabled={!editableData}
                                        onChange={this.handleChange('HBV')}
                                        value="HBV"
                                        color="primary"
                                    />
                                )}
                                label="HBV"
                            />
                        </Col>
                        <Col span={3}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        style={AskAlanStyles.margin}
                                        checked={formData.HBVNat}
                                        disabled={!editableData}
                                        onChange={this.handleChange('HBVNat')}
                                        value="HBVNat"
                                        color="primary"
                                    />
                                )}
                                label="HBV-Nat"
                            />
                        </Col>
                        <Col span={3}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        style={AskAlanStyles.margin}
                                        checked={formData.HCV}
                                        disabled={!editableData}
                                        onChange={this.handleChange('HCV')}
                                        value="HCV"
                                        color="primary"
                                    />
                                )}
                                label="HCV"
                            />
                        </Col>
                        <Col span={3}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        style={AskAlanStyles.margin}
                                        checked={formData.HCVNat}
                                        disabled={!editableData}
                                        onChange={this.handleChange('HCVNat')}
                                        value="HCVNat"
                                        color="primary"
                                    />
                                )}
                                label="HCV-Nat"
                            />
                        </Col>
                    </Row>

                    <HighlightedTextField
                        highlighted={templateGuidance.SEROLOGIES}
                        style={AskAlanStyles.textField}
                        margin="dense"
                        disabled={!editableData}
                        variant="outlined"
                        label="Other Serologies"
                        multiline
                        rowsMax="4"
                        value={formData.otherSerologies}
                        onChange={this.handleChange('otherSerologies')}
                    />
                    <span className="groupLabel">Observations</span>
                    {formData.organType === 'Kidney' ? (
                        <Row>
                            <Col span={6}>
                                <HighlightedTextField
                                    highlighted={templateGuidance.URINE_OUTPUT}
                                    style={AskAlanStyles.textField}
                                    margin="dense"
                                    disabled={!editableData}
                                    variant="outlined"
                                    label="Urine Output"
                                    error={this.state.errors.urineOutput.length !== 0}
                                    type="number"
                                    value={formData.urineOutput}
                                    onChange={this.handleChange('urineOutput')}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">ml/hr</InputAdornment>,
                                    }}
                                />
                            </Col>
                            <Col span={6}>
                                <HighlightedTextField
                                    highlighted={templateGuidance.BLOOD_TYPE}
                                    style={AskAlanStyles.textField}
                                    margin="dense"
                                    disabled={!editableData}
                                    variant="outlined"
                                    label="Anuric Time"
                                    type="number"
                                    value={formData.anuricTime}
                                    onChange={this.handleChange('anuricTime')}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">hours</InputAdornment>,
                                    }}
                                />
                            </Col>
                        </Row>
                    ) : ''}
                    <span className="groupLabel">Other</span>
                    <Row>
                        <Col span={4}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        style={AskAlanStyles.margin}
                                        disabled={!editableData}
                                        checked={formData.imagesAvailable}
                                        onChange={this.handleChange('imagesAvailable')}
                                        value="imagesAvailable"
                                        color="primary"
                                    />
                                )}
                                label="Imaging"
                            />
                        </Col>
                        <Col span={8}>
                            {formData.imagesAvailable
                                ? (
                                    <HighlightedTextField
                                        highlighted={templateGuidance.IMAGING_DETAILS}
                                        margin="dense"
                                        disabled={!editableData}
                                        style={AskAlanStyles.textField}
                                        variant="outlined"
                                        label="Details"
                                        error={this.state.errors.imagingDetails.length !== 0}
                                        helperText={this.state.errors.imagingDetails}
                                        value={formData.imagingDetails}
                                        onChange={this.handleChange('imagingDetails')}
                                    />
                                ) : <div />}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={4}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        style={AskAlanStyles.margin}
                                        disabled={!editableData}
                                        checked={formData.biopsiesAvailable}
                                        onChange={this.handleChange('biopsiesAvailable')}
                                        value="biopsiesAvailable"
                                        color="primary"
                                    />
                                )}
                                label="Biopsies"
                            />
                        </Col>
                        <Col span={8}>
                            {formData.biopsiesAvailable
                                ? (
                                    <HighlightedTextField
                                        highlighted={templateGuidance.BIOPSY_DETAILS}
                                        margin="dense"
                                        disabled={!editableData}
                                        style={AskAlanStyles.textField}
                                        variant="outlined"
                                        label="Details"
                                        error={this.state.errors.biopsyDetails.length !== 0}
                                        helperText={this.state.errors.biopsyDetails}
                                        value={formData.biopsyDetails}
                                        onChange={this.handleChange('biopsyDetails')}
                                    />
                                ) : <div />}
                        </Col>
                    </Row>
                    <HighlightedTextField
                        highlighted={templateGuidance.OTHER_INFORMATION}
                        style={AskAlanStyles.textField}
                        disabled={!editableData}
                        margin="dense"
                        variant="outlined"
                        label="Other Information"
                        multiline
                        rowsMax="4"
                        value={formData.otherInformation}
                        onChange={this.handleChange('otherInformation')}
                    />
                </div>
                {(editableData)
                    ? (
                        <div style={AskAlanStyles.actionsContainer}>
                            <Row>
                                <Col span={6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.saveRecord}
                                        style={AskAlanStyles.button}
                                    >
                                        Send to Chatroom
                                    </Button>
                                </Col>
                                <Col>
                                    {((saveDonorOrganStatus === 'FAILED'))
                                        ? (
                                            <div style={AskAlanStyles.error}>Unable to save.</div>
                                        ) : <div style={AskAlanStyles.info}>{saveDonorOrganStatus}</div>}
                                </Col>
                            </Row>
                        </div>
                    ) : <div />}
            </div>
        );
    }
}

export default DonorOrgan;
