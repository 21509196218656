// @flow

import React, { PureComponent } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
    Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import '../../node_modules/react-tabs/style/react-tabs.css';
import Header from '../Components/Header';
import GraftSurvival from '../Components/GraftSurvival';
import WaitlistSurvival from '../Components/WaitlistSurvival';
import AskAlanStyles from '../Styles/AskAlanStyles';

import type {
    SaveDonorOrgan, SaveRecipient, SaveCandidate,
    SaveCandidateLocal, SaveRecipientLocal, SaveDonorOrganLocal,
    SetSaveDonorOrganStatus, SetSaveRecipientStatus, SetSaveCandidateStatus,
} from '../Redux/ClinicalActions';

import {
    saveDonorOrgan, saveRecipient, saveCandidate,
    saveCandidateLocal, saveRecipientLocal, saveDonorOrganLocal,
    setSaveDonorOrganStatus, setSaveRecipientStatus, setSaveCandidateStatus,
} from '../Redux/ClinicalActions';

import DonorOrgan from '../Components/DonorOrgan';
import type { DonorOrganData } from '../Types/DonorOrgan';

import Recipient from '../Components/Recipient';
import type { RecipientData } from '../Types/Recipient';

import Candidate from '../Components/Candidate';
import type { CandidateData } from '../Types/Candidate';

import type { GetTemplatePreferences } from '../Redux/ActivationActions';
import { getTemplatePreferences as _getTemplatePreferences } from '../Redux/ActivationActions';

type Props = {
    userOrgId: number,
    targetOrgId: number,
    donorOrganData: DonorOrganData,
    recipientData: RecipientData,
    candidateData: CandidateData,
    saveDonorOrganStatus: string,
    saveRecipientStatus: string,
    saveCandidateStatus: string,
    templatePreferences: any,

    onSaveDonorOrgan: (donorOrgan: DonorOrganData) => SaveDonorOrgan,
    onSaveDonorOrganLocal: (donorOrgan: DonorOrganData) => SaveDonorOrganLocal,
    onSetSaveDonorOrganStatus: (status: string, donorId: number, organId: number)=> SetSaveDonorOrganStatus,

    onSaveRecipient: (recipient: RecipientData) => SaveRecipient,
    onSaveRecipientLocal: (recipient: RecipientData) => SaveRecipientLocal,
    onSetSaveRecipientStatus: (status: string, recipientId: number)=> SetSaveRecipientStatus,

    onSaveCandidate: (candidate: CandidateData) => SaveCandidate,
    onSaveCandidateLocal: (candidate: CandidateData) => SaveCandidateLocal,
    onSetSaveCandidateStatus: (status: string, candidateId: number)=> SetSaveCandidateStatus,

    getTemplatePreferences: (organizationId: number) => GetTemplatePreferences,

};

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#0069FF',
        },
        secondary: {
            main: '#00A79D',
        },
    },
});

class AskAlan extends PureComponent<Props> {
    componentDidMount() {
        const {
            targetOrgId,
            getTemplatePreferences,
        } = this.props;

        getTemplatePreferences(targetOrgId);
    }

    onSaveDonorOrganData = (donorOrganData: DonorOrganData, saveToServer: boolean) => {
        if (saveToServer) {
            this.props.onSaveDonorOrgan(donorOrganData);
        } else {
            this.props.onSaveDonorOrganLocal(donorOrganData);
        }
    };

    onSaveCandidateData = (candidateData: CandidateData, saveToServer: boolean) => {
        if (saveToServer) {
            this.props.onSaveCandidate(candidateData);
        } else {
            this.props.onSaveCandidateLocal(candidateData);
        }
    };

    onSaveRecipientData = (recipientData: RecipientData, saveToServer: boolean) => {
        if (saveToServer) {
            this.props.onSaveRecipient(recipientData);
        } else {
            this.props.onSaveRecipientLocal(recipientData);
        }
    };

    render() {
        const {
            userOrgId,
            saveDonorOrganStatus,
            saveCandidateStatus,
            saveRecipientStatus,
            templatePreferences,
            donorOrganData,
            recipientData,
            candidateData,
        } = this.props;

        return (
            <MuiThemeProvider theme={theme}>
                <Header
                    userOrgId={userOrgId}
                />
                <div style={AskAlanStyles.root}>
                    <Tabs>
                        <TabList>
                            <Tab>Donor</Tab>
                            <Tab>CAND (OPO)</Tab>
                            <Tab>CAND (TC)</Tab>
                            <Tab>Graft Survival</Tab>
                            <Tab>Waiting List</Tab>
                        </TabList>
                        <TabPanel>
                            <DonorOrgan
                                userOrgId={userOrgId}
                                donorOrganData={donorOrganData}
                                saveDonorOrganStatus={saveDonorOrganStatus}
                                donorTemplatePreferences={(templatePreferences) ? templatePreferences.donor : {}}
                                onSaveDonorOrganData={this.onSaveDonorOrganData}
                                onSetSaveDonorOrganStatus={this.props.onSetSaveDonorOrganStatus}
                            />
                        </TabPanel>
                        <TabPanel>
                            <Recipient
                                userOrgId={userOrgId}
                                donorOrganData={donorOrganData}
                                recipientData={recipientData}
                                saveRecipientStatus={saveRecipientStatus}
                                recipientTemplatePreferences={(templatePreferences) ? templatePreferences.recipient : {}}
                                onSaveRecipientData={this.onSaveRecipientData}
                                onSetSaveRecipientStatus={this.props.onSetSaveRecipientStatus}
                            />
                        </TabPanel>
                        <TabPanel>
                            <Candidate
                                userOrgId={userOrgId}
                                donorOrganData={donorOrganData}
                                candidateData={candidateData}
                                saveCandidateStatus={saveCandidateStatus}
                                recipientTemplatePreferences={(templatePreferences) ? templatePreferences.recipient : {}}
                                onSaveCandidateData={this.onSaveCandidateData}
                                onSetSaveCandidateStatus={this.props.onSetSaveCandidateStatus}
                            />
                        </TabPanel>
                        <TabPanel>
                            <GraftSurvival
                                donorOrganData={donorOrganData}
                                candidateData={candidateData}
                                recipientData={recipientData}
                            />
                        </TabPanel>
                        <TabPanel>
                            <WaitlistSurvival
                                donorOrganData={donorOrganData}
                                candidateData={candidateData}
                                recipientData={recipientData}
                            />
                        </TabPanel>
                    </Tabs>
                </div>
            </MuiThemeProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    userOrgId: state.activation.orgId,
    targetOrgId: state.activation.targetOrgId,
    templatePreferences: state.activation.templatePreferences,
    donorOrganData: state.clinical.donorOrgan,
    recipientData: state.clinical.recipient,
    candidateData: state.clinical.candidate,

    saveDonorOrganStatus: state.clinical.saveDonorOrganStatus,
    saveRecipientStatus: state.clinical.saveRecipientStatus,
    saveCandidateStatus: state.clinical.saveCandidateStatus,

});
export default connect(mapStateToProps, {
    onSaveDonorOrgan: saveDonorOrgan,
    onSaveDonorOrganLocal: saveDonorOrganLocal,
    onSetSaveDonorOrganStatus: setSaveDonorOrganStatus,

    onSaveRecipient: saveRecipient,
    onSaveRecipientLocal: saveRecipientLocal,
    onSetSaveRecipientStatus: setSaveRecipientStatus,

    onSaveCandidate: saveCandidate,
    onSaveCandidateLocal: saveCandidateLocal,
    onSetSaveCandidateStatus: setSaveCandidateStatus,

    getTemplatePreferences: _getTemplatePreferences,

})(AskAlan);
