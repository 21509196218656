/* eslint-disable */
export const kiddadgs3y = {
    baselineHazard: 0.109396813789497,
    predictors: [
        {
            'predictor': 'Recipient crossmatch (T cell, B cell)',
            'level': 'Not Positive',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient crossmatch (T cell, B cell)',
            'level': 'Positive',
            'coefficient': -0.0194354188581053,
        },
        {
            'predictor': 'Recipient crossmatch (T cell, B cell)',
            'level': 'Missing',
            'coefficient': -0.0194354188581053,
        },
        {
            'predictor': 'Candidate diabetes status/type at onset',
            'level': 'None',
            'coefficient': -0.19020577086012,
        },
        {
            'predictor': 'Candidate diabetes status/type at onset',
            'level': 'Type I',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate diabetes status/type at onset',
            'level': 'Type II',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate diabetes status/type at onset',
            'level': 'Type Other/Unknown',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate diabetes status/type at onset',
            'level': 'Missing',
            'coefficient': -0.19020577086012,
        },
        {
            'predictor': 'Candidate education',
            'level': 'Grade School/None',
            'coefficient': -0.00118498576533175,
        },
        {
            'predictor': 'Candidate education',
            'level': 'High School',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate education',
            'level': 'Post High School Degree',
            'coefficient': -0.0214605276994646,
        },
        {
            'predictor': 'Candidate education',
            'level': 'Technical',
            'coefficient': 0.00459848690981415,
        },
        {
            'predictor': 'Candidate education',
            'level': 'Missing',
            'coefficient': -0.0214605276994646,
        },
        {
            'predictor': 'Candidate ethnicity',
            'level': 'Latino',
            'coefficient': -0.283511809893179,
        },
        {
            'predictor': 'Candidate ethnicity',
            'level': 'Non-Latino or unknown',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate ethnicity',
            'level': 'Missing',
            'coefficient': -0.283511809893179,
        },
        {
            'predictor': 'Candidate gender',
            'level': 'Female',
            'coefficient': -0.0476748454435284,
        },
        {
            'predictor': 'Candidate gender',
            'level': 'Male',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate gender',
            'level': 'Missing',
            'coefficient': -0.0476748454435284,
        },
        {
            'predictor': 'Candidate previous malignancy',
            'level': 'No',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate previous malignancy',
            'level': 'Yes',
            'coefficient': 0.059903314054295,
        },
        {
            'predictor': 'Candidate previous malignancy',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate PVD',
            'level': 'No',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate PVD',
            'level': 'Yes',
            'coefficient': 0.277031685455483,
        },
        {
            'predictor': 'Candidate PVD',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate race',
            'level': 'Asian',
            'coefficient': -0.332845246635451,
        },
        {
            'predictor': 'Candidate race',
            'level': 'Black',
            'coefficient': 0.0691698888788858,
        },
        {
            'predictor': 'Candidate race',
            'level': 'Multiracial',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate race',
            'level': 'Native American',
            'coefficient': -0.123359208725932,
        },
        {
            'predictor': 'Candidate race',
            'level': 'Pacific Islander',
            'coefficient': -0.0107528851702128,
        },
        {
            'predictor': 'Candidate race',
            'level': 'White',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate race',
            'level': 'Missing',
            'coefficient': -0.332845246635451,
        },
        {
            'predictor': 'Candidate total albumin',
            'level': 'Apply to < 3 (Left LS)',
            'coefficient': 0.193529311931875,
        },
        {
            'predictor': 'Candidate total albumin',
            'level': 'Apply to < 4.6 (Left LS)',
            'coefficient': 0.0291090755593522,
        },
        {
            'predictor': 'Candidate total albumin',
            'level': 'Apply to > 3.6 (Right LS)',
            'coefficient': -0.00228263986637523,
        },
        {
            'predictor': 'Candidate total albumin',
            'level': 'Apply to > 3.8 (Right LS)',
            'coefficient': -0.0750897998911854,
        },
        {
            'predictor': 'Candidate total albumin',
            'level': 'Apply to > 4.8 (Right LS)',
            'coefficient': 0.0269649786560408,
        },
        {
            'predictor': 'Candidate total albumin',
            'level': 'Missing',
            'coefficient': -0.0929512060612916,
        },
        {
            'predictor': 'Donor smoking history',
            'level': 'Current',
            'coefficient': 0.0174385892066745,
        },
        {
            'predictor': 'Donor smoking history',
            'level': 'Former',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor smoking history',
            'level': 'Never',
            'coefficient': -0.00181521467925177,
        },
        {
            'predictor': 'Donor smoking history',
            'level': 'Missing',
            'coefficient': -0.00181521467925177,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'Congenital',
            'coefficient': 0.124344557928441,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'DM',
            'coefficient': 0.0131775602938965,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'GN',
            'coefficient': -0.0117335826622658,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'HTN',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'Other',
            'coefficient': -0.0963009741732692,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'Missing',
            'coefficient': -0.0963009741732692,
        },
        {
            'predictor': 'Donor age',
            'level': 'Apply to > 50 (Right LS)',
            'coefficient': -0.000582245041510003,
        },
        {
            'predictor': 'Donor age',
            'level': 'Missing',
            'coefficient': -0.0110626557886901,
        },
        {
            'predictor': 'Donor BMI',
            'level': 'Apply to > 34 (Right LS)',
            'coefficient': 0.0000531183450690763,
        },
        {
            'predictor': 'Donor BMI',
            'level': 'Apply to > 40 (Right LS)',
            'coefficient': 0.00138847612245172,
        },
        {
            'predictor': 'Donor BMI',
            'level': 'Apply to > 42 (Right LS)',
            'coefficient': 0.014737689440861,
        },
        {
            'predictor': 'Donor BMI',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor BUN',
            'level': 'Apply to < 20 (Left LS)',
            'coefficient': -0.000922526468541322,
        },
        {
            'predictor': 'Donor BUN',
            'level': 'Missing',
            'coefficient': -0.0156829499652025,
        },
        {
            'predictor': 'Donor cause of death',
            'level': 'Anoxia',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor cause of death',
            'level': 'Cerebrovascular/Stroke',
            'coefficient': 0.0193112168297398,
        },
        {
            'predictor': 'Donor cause of death',
            'level': 'CNS Tumor',
            'coefficient': 0.146872369840675,
        },
        {
            'predictor': 'Donor cause of death',
            'level': 'Head Trauma',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor cause of death',
            'level': 'Other',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor cause of death',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor serum creatinine',
            'level': 'Apply to < 0.8 (Left LS)',
            'coefficient': 0.0431064853632028,
        },
        {
            'predictor': 'Donor serum creatinine',
            'level': 'Apply to > 2.8 (Right LS)',
            'coefficient': -0.00827254676159075,
        },
        {
            'predictor': 'Donor serum creatinine',
            'level': 'Missing',
            'coefficient': -0.0229149545296064,
        },
        {
            'predictor': 'Donor ethnicity',
            'level': 'Latino',
            'coefficient': 0.000590789728120671,
        },
        {
            'predictor': 'Donor ethnicity',
            'level': 'Non-Latino or unknown',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor ethnicity',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor gender',
            'level': 'Female',
            'coefficient': 0.0239059301910777,
        },
        {
            'predictor': 'Donor gender',
            'level': 'Male',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor gender',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor hematocrit',
            'level': 'Apply to < 30 (Left LS)',
            'coefficient': -0.00861873847956758,
        },
        {
            'predictor': 'Donor hematocrit',
            'level': 'Apply to > 36 (Right LS)',
            'coefficient': -0.00887815559785577,
        },
        {
            'predictor': 'Donor hematocrit',
            'level': 'Apply to > 38 (Right LS)',
            'coefficient': -0.0000673380753995806,
        },
        {
            'predictor': 'Donor hematocrit',
            'level': 'Missing',
            'coefficient': -0.0964766555203586,
        },
        {
            'predictor': 'Donor history of diabetes',
            'level': 'No',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor history of diabetes',
            'level': 'Yes',
            'coefficient': 0.117061950645012,
        },
        {
            'predictor': 'Donor history of diabetes',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor history of hypertension',
            'level': 'No',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor history of hypertension',
            'level': 'Yes',
            'coefficient': 0.0433993387674845,
        },
        {
            'predictor': 'Donor history of hypertension',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor INR',
            'level': 'Apply to > 1.5 (Right LS)',
            'coefficient': -0.0813981261497881,
        },
        {
            'predictor': 'Donor INR',
            'level': 'Apply to > 1.7 (Right LS)',
            'coefficient': -0.0350320344039104,
        },
        {
            'predictor': 'Donor INR',
            'level': 'Missing',
            'coefficient': -0.140859897022415,
        },
        {
            'predictor': 'Donor received insulin',
            'level': 'No',
            'coefficient': 0.000970431495086862,
        },
        {
            'predictor': 'Donor received insulin',
            'level': 'Yes',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor received insulin',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor received diuretics',
            'level': 'No',
            'coefficient': 0.0059096770179189,
        },
        {
            'predictor': 'Donor received diuretics',
            'level': 'Yes',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor received diuretics',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor race',
            'level': 'Asian',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor race',
            'level': 'Black',
            'coefficient': 0.000310894241898068,
        },
        {
            'predictor': 'Donor race',
            'level': 'Multiracial',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor race',
            'level': 'Native American',
            'coefficient': -0.00225373159973842,
        },
        {
            'predictor': 'Donor race',
            'level': 'Pacific Islander',
            'coefficient': 5.87306802616873e-7,
        },
        {
            'predictor': 'Donor race',
            'level': 'White',
            'coefficient': -0.00503500393734885,
        },
        {
            'predictor': 'Donor race',
            'level': 'Missing',
            'coefficient': -0.00503500393734885,
        },
        {
            'predictor': 'Donor received vasodilators',
            'level': 'No',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor received vasodilators',
            'level': 'Yes',
            'coefficient': 0.0472410537089693,
        },
        {
            'predictor': 'Donor received vasodilators',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor eGFR',
            'level': 'Apply to < 80 (Left LS)',
            'coefficient': 0.0000645914033949033,
        },
        {
            'predictor': 'Donor eGFR',
            'level': 'Apply to < 90 (Left LS)',
            'coefficient': 0.00062006600121104,
        },
        {
            'predictor': 'Donor eGFR',
            'level': 'Apply to > 110 (Right LS)',
            'coefficient': 0.0000240600583183043,
        },
        {
            'predictor': 'Donor eGFR',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient total ESRD time (days) at transplant',
            'level': 'Apply to < 500 (Left LS)',
            'coefficient': -0.00046858466251344,
        },
        {
            'predictor': 'Recipient total ESRD time (days) at transplant',
            'level': 'Apply to < 2000 (Left LS)',
            'coefficient': -0.0000691845817822042,
        },
        {
            'predictor': 'Recipient total ESRD time (days) at transplant',
            'level': 'Apply to < 3000 (Left LS)',
            'coefficient': -0.0000564543976572354,
        },
        {
            'predictor': 'Recipient total ESRD time (days) at transplant',
            'level': 'Apply to < 3500 (Left LS)',
            'coefficient': -0.0000355629472189143,
        },
        {
            'predictor': 'Recipient total ESRD time (days) at transplant',
            'level': 'Apply to < 4000 (Left LS)',
            'coefficient': -0.0000157926815709716,
        },
        {
            'predictor': 'Recipient total ESRD time (days) at transplant',
            'level': 'Missing',
            'coefficient': -0.729665729342921,
        },
        {
            'predictor': 'Recipient total ESRD time (days) at transplant = 0',
            'level': 'Not Zero',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient total ESRD time (days) at transplant = 0',
            'level': 'Zero',
            'coefficient': -0.0384900503339304,
        },
        {
            'predictor': 'Recipient total ESRD time (days) at transplant = 0',
            'level': 'Missing',
            'coefficient': -0.0384900503339304,
        },
        {
            'predictor': 'Donor risk index',
            'level': 'Linear',
            'coefficient': 0.240641176039205,
        },
        {
            'predictor': 'Donor risk index',
            'level': 'Apply to < 0.8 (Left LS)',
            'coefficient': -0.145754530674491,
        },
        {
            'predictor': 'Donor risk index',
            'level': 'Apply to < 1.4 (Left LS)',
            'coefficient': -0.00184661488955189,
        },
        {
            'predictor': 'Donor risk index',
            'level': 'Apply to < 1.5 (Left LS)',
            'coefficient': -0.0778850562697887,
        },
        {
            'predictor': 'Donor risk index',
            'level': 'Apply to < 1.6 (Left LS)',
            'coefficient': -0.155572185012905,
        },
        {
            'predictor': 'Donor risk index',
            'level': 'Apply to < 1.8 (Left LS)',
            'coefficient': -0.00108968765556749,
        },
        {
            'predictor': 'Donor risk index',
            'level': 'Apply to > 1 (Right LS)',
            'coefficient': 0.263613148386489,
        },
        {
            'predictor': 'Donor risk index',
            'level': 'Missing',
            'coefficient': -0.0450766109061115,
        },
        {
            'predictor': 'Recipient BMI',
            'level': 'Apply to < 20 (Left LS)',
            'coefficient': 0.0280183054239985,
        },
        {
            'predictor': 'Recipient BMI',
            'level': 'Apply to > 24 (Right LS)',
            'coefficient': 0.0137666099998406,
        },
        {
            'predictor': 'Recipient BMI',
            'level': 'Apply to > 36 (Right LS)',
            'coefficient': 0.000852820809130693,
        },
        {
            'predictor': 'Recipient BMI',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient cold ischemia time',
            'level': 'Linear',
            'coefficient': 0.00314567261871836,
        },
        {
            'predictor': 'Recipient cold ischemia time',
            'level': 'Apply to > 5 (Right LS)',
            'coefficient': 0.000483878794266598,
        },
        {
            'predictor': 'Recipient cold ischemia time',
            'level': 'Apply to > 15 (Right LS)',
            'coefficient': 0.00140436813529823,
        },
        {
            'predictor': 'Recipient cold ischemia time',
            'level': 'Apply to > 35 (Right LS)',
            'coefficient': 0.00251433493786355,
        },
        {
            'predictor': 'Recipient cold ischemia time',
            'level': 'Missing',
            'coefficient': 0.0109469407131399,
        },
        {
            'predictor': 'Recipient HLA mismatches (DR)',
            'level': 0,
            'coefficient': -0.0648261238960511,
        },
        {
            'predictor': 'Recipient HLA mismatches (DR)',
            'level': 1,
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient HLA mismatches (DR)',
            'level': 2,
            'coefficient': 0.0440375287694558,
        },
        {
            'predictor': 'Recipient HLA mismatches (DR)',
            'level': 'Missing',
            'coefficient': -0.0648261238960511,
        },
        {
            'predictor': 'Recipient HIV status',
            'level': 'Not Positive',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient HIV status',
            'level': 'Positive',
            'coefficient': 0.0865736939933999,
        },
        {
            'predictor': 'Recipient HIV status',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient most recent CPRA',
            'level': 'Apply to < 10 (Left LS)',
            'coefficient': -0.000233143274694753,
        },
        {
            'predictor': 'Recipient most recent CPRA',
            'level': 'Apply to > 30 (Right LS)',
            'coefficient': 0.000188410025326834,
        },
        {
            'predictor': 'Recipient most recent CPRA',
            'level': 'Apply to > 40 (Right LS)',
            'coefficient': 0.00141903882245999,
        },
        {
            'predictor': 'Recipient most recent CPRA',
            'level': 'Apply to > 70 (Right LS)',
            'coefficient': 0.00000700852574419778,
        },
        {
            'predictor': 'Recipient most recent CPRA',
            'level': 'Apply to > 90 (Right LS)',
            'coefficient': 0.00192212544533906,
        },
        {
            'predictor': 'Recipient most recent CPRA',
            'level': 'Missing',
            'coefficient': -0.00233143274694753,
        },
        {
            'predictor': 'Recipient most recent CPRA =0',
            'level': 'No',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient most recent CPRA =0',
            'level': 'Yes',
            'coefficient': -0.000173190739843768,
        },
        {
            'predictor': 'Recipient most recent CPRA =0',
            'level': 'Missing',
            'coefficient': -0.000173190739843768,
        },
        {
            'predictor': 'Recipient primary insurance',
            'level': 'Other',
            'coefficient': -0.0926458598340572,
        },
        {
            'predictor': 'Recipient primary insurance',
            'level': 'Public',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient primary insurance',
            'level': 'Missing',
            'coefficient': -0.0926458598340572,
        },
        {
            'predictor': 'Recipient transplant procedure type',
            'level': 'EnBloc',
            'coefficient': -0.0323795330523236,
        },
        {
            'predictor': 'Recipient transplant procedure type',
            'level': 'Left',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient transplant procedure type',
            'level': 'Right',
            'coefficient': 0.0140981574226686,
        },
        {
            'predictor': 'Recipient transplant procedure type',
            'level': 'Sequential',
            'coefficient': -0.074166149221445,
        },
        {
            'predictor': 'Recipient transplant procedure type',
            'level': 'Missing',
            'coefficient': -0.074166149221445,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Apply to < 30 (Left LS)',
            'coefficient': 0.0719005186140694,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Apply to > 50 (Right LS)',
            'coefficient': 0.00266503853350914,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Apply to > 55 (Right LS)',
            'coefficient': 0.00723674438880275,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Apply to > 60 (Right LS)',
            'coefficient': 0.0272950456194692,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Missing',
            'coefficient': 0,
        }
    ],
}
