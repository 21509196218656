/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
// @flow

import React, { PureComponent } from 'react';
import { Col, Row } from 'react-simple-flex-grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import HighlightedDatePicker from './HighlightedDatePicker';
import HighlightedSelect from './HighlightedSelect';
import HighlightedTextField from './HighlightedTextField';

import UNOSHeader from './UNOSHeader';
import type { DonorOrganData } from '../Types/DonorOrgan';
import type { CandidateData } from '../Types/Candidate';
import { renderDateLabel } from '../Utils/dateTime';

import AskAlanStyles from '../Styles/AskAlanStyles';

type Props = {
    userOrgId: number,
    donorOrganData: DonorOrganData,
    candidateData: CandidateData,
    saveCandidateStatus: string,
    recipientTemplatePreferences: any,

    onSaveCandidateData: (candidateData: CandidateData, saveToServer: boolean) => *,
    onSetSaveCandidateStatus: (status: string, candidateId: number)=> *,

};

type State = {
    errors: {
        name: string,
        mrn: string,
        sex: string,
        age: string,
        bloodType: string,
        bmi: string,
        height: string,
        weight: string,
        race: string,
        ethnicity: string,
        priorTransplant: string,
        esrdTime: string,
        cPRA: string,
        diabetesType: string,
        cancer: string,
        pvd: string,
        lastSeen: string,
        primaryDiagnosis: string,
    },
    formData: {
        mrn: string,
        name: string,
        sex: string,
        age: number,
        bloodType: string,
        bmi: number,
        height: number,
        weight: number,
        race: string,
        ethnicity: string,

        priorTransplant: boolean,
        esrdTime: number,
        diabetesType: string,
        cancer: boolean,
        pvd: boolean,
        lastSeen: ?string,
        primaryDiagnosis: string,

        cPRA: number,
    },
};
class Candidate extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            errors: {
                name: '',
                mrn: '',
                sex: '',
                age: '',
                bloodType: '',
                bmi: '',
                height: '',
                weight: '',
                race: '',
                ethnicity: '',
                priorTransplant: '',
                esrdTime: '',
                cPRA: '',
                diabetesType: '',
                cancer: '',
                pvd: '',
                lastSeen: '',
                primaryDiagnosis: '',
            },
            formData: {
                mrn: this.props.candidateData.demographics.mrn,
                name: this.props.candidateData.demographics.full_name,
                sex: this.props.candidateData.demographics.sex || 'Please Select',
                age: this.props.candidateData.demographics.age || 0,
                bloodType: this.props.candidateData.demographics.blood_type || 'Please Select',
                bmi: this.props.candidateData.demographics.bmi || 0,
                height: this.props.candidateData.demographics.height || 0,
                weight: this.props.candidateData.demographics.weight || 0,
                race: this.props.candidateData.demographics.race || 'Please Select',
                ethnicity: this.props.candidateData.demographics.ethnicity || 'Please Select',

                priorTransplant: this.props.candidateData.history.prior_transplant,
                esrdTime: this.props.candidateData.history.esrd_time || 0,
                diabetesType: this.props.candidateData.history.diabetes_type || 'Please Select',
                cancer: this.props.candidateData.history.cancer,
                pvd: this.props.candidateData.history.pvd,
                lastSeen: this.props.candidateData.history.last_seen || null,
                primaryDiagnosis: this.props.candidateData.history.primary_diagnosis || 'Please Select',

                cPRA: this.props.candidateData.labs.cpra || 0,
            },
        };
    }

    componentWillUnmount() {
        // If this is 'my data' then save. If not (e.g. TC viewing data entered by OPO then don't save)
        if (this.props.userOrgId !== this.props.donorOrganData.organization_id) {
            const candidate = this.getCandidateData();
            this.props.onSaveCandidateData(candidate, false);
            this.props.onSetSaveCandidateStatus('', candidate.candidate_id);
        }
    }

    setError = (fieldName: string, errorMessage: string) => {
        this.setState((prevState) => {
            const errors = { ...prevState.errors, };
            errors[fieldName] = errorMessage;
            return { errors, };
        });
        return 1;
    };

    getCandidateData = (): CandidateData => {
        const form = this.state.formData;
        let lastSeenStr = '';
        if (form.lastSeen !== null) {
            lastSeenStr = moment(form.lastSeen).toISOString();
        } else {
            lastSeenStr = null;
        }

        const candidateData: CandidateData = {
            candidate_id: this.props.candidateData.candidate_id,
            demographics: {
                mrn: form.mrn,
                full_name: form.name,
                sex: form.sex === 'Please Select' ? null : form.sex,
                age: Number(form.age),
                blood_type: form.bloodType === 'Please Select' ? null : form.bloodType,
                bmi: Number(form.bmi),
                height: Number(form.height),
                weight: Number(form.weight),
                race: form.race === 'Please Select' ? null : form.race,
                ethnicity: form.ethnicity === 'Please Select' ? null : form.ethnicity,
            },
            history: {
                prior_transplant: form.priorTransplant,
                esrd_time: Number(form.esrdTime),
                diabetes_type: form.diabetesType === 'Please Select' ? null : form.diabetesType,
                cancer: form.cancer,
                pvd: form.pvd,
                last_seen: lastSeenStr,
                primary_diagnosis: form.primaryDiagnosis === 'Please Select' ? null : form.primaryDiagnosis,
            },
            labs: {
                cpra: Number(form.cPRA),
            },
        };
        return candidateData;
    }

    clearErrors = () => {
        this.setState((prevState) => {
            const errors = { ...prevState.errors, };
            errors.name = '';
            errors.mrn = '';
            errors.sex = '';
            errors.age = '';
            errors.bloodType = '';
            errors.bmi = '';
            errors.height = '';
            errors.weight = '';
            errors.race = '';
            errors.ethnicity = '';
            errors.priorTransplant = '';
            errors.esrdTime = '';
            errors.cPRA = '';
            errors.diabetesType = '';
            errors.cancer = '';
            errors.pvd = '';
            errors.lastSeen = '';
            errors.primaryDiagnosis = '';
            return { errors, };
        });
    };

    handleChange = (prop: any) => (event: any) => {
        let newValue = '';
        if (event._isAMomentObject) {
            newValue = event._d;
        } else {
            newValue = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
        }
        this.setState((prevState) => {
            const formData = { ...prevState.formData, };
            formData[prop] = newValue;
            if (prop === 'bmi') {
                formData.height = 0;
                formData.weight = 0;
            }
            if (prop === 'height' || prop === 'weight') {
                formData.bmi = 0;
                const w = formData.weight;
                const h = formData.height;
                if ((w > 0) && (h > 0)) {
                    formData.bmi = Number((w / ((h / 100) ** 2)).toFixed(1));
                }
            }
            return { formData, };
        });
    };


    saveRecord = () => {
        this.clearErrors();

        const candidate = this.getCandidateData();
        // Save locally
        this.props.onSaveCandidateData(candidate, false);

        // validate
        const form = this.state.formData;
        let errorCnt = 0;

        if (isNaN(form.bmi)) errorCnt += this.setError('bmi', 'Must be numeric');
        if (isNaN(form.height)) errorCnt += this.setError('height', 'Must be numeric');
        if (isNaN(form.weight)) errorCnt += this.setError('weight', 'Must be numeric');

        if (errorCnt > 0) return;

        // Save to server
        this.props.onSaveCandidateData(candidate, true);
    };

    render() {
        const {
            userOrgId,
            donorOrganData,
            saveCandidateStatus,
            recipientTemplatePreferences,
        } = this.props;

        const templateGuidance = recipientTemplatePreferences || {};

        const {
            name,
            mrn,
            sex,
            age,
            bloodType,
            bmi,
            height,
            weight,
            race,
            ethnicity,
            priorTransplant,
            esrdTime,
            cPRA,
            diabetesType,
            cancer,
            pvd,
            lastSeen,
            primaryDiagnosis,
        } = this.state.formData;

        const editableData = ((donorOrganData.organization_id > 0) && (userOrgId !== donorOrganData.organization_id));

        return (
            <div>
                <UNOSHeader donorOrganData={donorOrganData} editable={false} />
                <span className="groupLabel">Demographic</span>
                <Row>
                    <Col span="6">
                        <HighlightedTextField
                            highlighted={templateGuidance.FULL_NAME}
                            style={AskAlanStyles.textField}
                            disabled={!editableData}
                            margin="dense"
                            variant="outlined"
                            label="Name"
                            value={name}
                            onChange={this.handleChange('name')}
                        />
                    </Col>
                    <Col span="6">
                        <HighlightedTextField
                            highlighted={templateGuidance.MRN}
                            style={AskAlanStyles.textField}
                            disabled={!editableData}
                            margin="dense"
                            variant="outlined"
                            label="MRN"
                            value={mrn}
                            onChange={this.handleChange('mrn')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span="4">
                        <HighlightedSelect
                            highlighted={templateGuidance.SEX}
                            value={sex}
                            variant="outlined"
                            label="Sex"
                            onChange={this.handleChange('sex')}
                            disabled={!editableData}
                            style={AskAlanStyles.textField}
                            margin="dense"
                            options={[
                                { value: 'Please Select', text: 'Please Select', },
                                { value: 'Male', text: 'Male', },
                                { value: 'Female', text: 'Female', }
                            ]}
                        />
                    </Col>
                    <Col span="4">
                        <HighlightedTextField
                            highlighted={templateGuidance.AGE}
                            style={AskAlanStyles.textField}
                            disabled={!editableData}
                            margin="dense"
                            error={this.state.errors.age.length !== 0}
                            helperText={this.state.errors.age}
                            variant="outlined"
                            label="Age"
                            type="number"
                            value={age}
                            onChange={this.handleChange('age')}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">years</InputAdornment>,
                            }}
                        />
                    </Col>
                    <Col span={4}>
                        <HighlightedSelect
                            highlighted={templateGuidance.BLOOD_TYPE}
                            value={bloodType}
                            variant="outlined"
                            label="Blood Type"
                            onChange={this.handleChange('bloodType')}
                            disabled={!editableData}
                            style={AskAlanStyles.textField}
                            margin="dense"
                            error={this.state.errors.bloodType.length !== 0}
                            options={[
                                { value: 'Please Select', text: 'Please Select', },
                                { value: 'A', text: 'A', },
                                { value: 'B', text: 'B', },
                                { value: 'AB', text: 'AB', },
                                { value: 'O', text: 'O', },
                                { value: 'Unknown', text: 'Unknown', }
                            ]}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <HighlightedTextField
                            highlighted={templateGuidance.BMI}
                            style={AskAlanStyles.textField}
                            disabled={!editableData}
                            margin="dense"
                            error={this.state.errors.bmi.length !== 0}
                            helperText={this.state.errors.bmi}
                            variant="outlined"
                            label="BMI"
                            type="number"
                            value={bmi}
                            onChange={this.handleChange('bmi')}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">kg/m²</InputAdornment>,
                            }}
                        />
                    </Col>
                    <Col span={4}>
                        <HighlightedTextField
                            highlighted={templateGuidance.HEIGHT}
                            style={AskAlanStyles.textField}
                            disabled={!editableData}
                            margin="dense"
                            variant="outlined"
                            label="Height"
                            type="number"
                            value={height}
                            onChange={this.handleChange('height')}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                            }}
                        />
                    </Col>
                    <Col span={4}>
                        <HighlightedTextField
                            highlighted={templateGuidance.WEIGHT}
                            style={AskAlanStyles.textField}
                            disabled={!editableData}
                            margin="dense"
                            variant="outlined"
                            label="Weight"
                            type="number"
                            value={weight}
                            onChange={this.handleChange('weight')}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <HighlightedSelect
                            highlighted={templateGuidance.RACE}
                            value={race}
                            variant="outlined"
                            label="Race"
                            onChange={this.handleChange('race')}
                            disabled={!editableData}
                            style={AskAlanStyles.textField}
                            margin="dense"
                            error={this.state.errors.race.length !== 0}
                            options={[
                                { value: 'Please Select', text: 'Please Select', },
                                { value: 'Multiracial', text: 'Multiracial', },
                                { value: 'Asian', text: 'Asian', },
                                { value: 'Black', text: 'Black', },
                                { value: 'Native American', text: 'Native American', },
                                { value: 'White', text: 'White', },
                                { value: 'Unknown', text: 'Unknown', }
                            ]}
                        />
                    </Col>
                    <Col span="6">
                        <HighlightedSelect
                            highlighted={templateGuidance.ETHNICITY}
                            value={ethnicity}
                            variant="outlined"
                            label="Ethnicity"
                            onChange={this.handleChange('ethnicity')}
                            disabled={!editableData}
                            style={AskAlanStyles.textField}
                            margin="dense"
                            options={[
                                { value: 'Please Select', text: 'Please Select', },
                                { value: 'Latino', text: 'Latino', },
                                { value: 'Non-Latino', text: 'Non-Latino', },
                                { value: 'Unknown', text: 'Unknown', }
                            ]}
                        />
                    </Col>
                </Row>
                <span className="groupLabel">Clinical</span>
                <Row>
                    <Col span={4}>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    style={AskAlanStyles.margin}
                                    disabled={!editableData}
                                    checked={priorTransplant}
                                    onChange={this.handleChange('priorTransplant')}
                                    value="priorTransplant"
                                    color="primary"
                                />
                            )}
                            label="Prior Transplant"
                        />
                    </Col>
                    <Col span="4">
                        <HighlightedTextField
                            highlighted={templateGuidance.ESRD_TIME}
                            style={AskAlanStyles.textField}
                            disabled={!editableData}
                            margin="dense"
                            error={this.state.errors.esrdTime.length !== 0}
                            helperText={this.state.errors.esrdTime}
                            variant="outlined"
                            type="number"
                            label="ESRD Time"
                            value={esrdTime}
                            onChange={this.handleChange('esrdTime')}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Days</InputAdornment>,
                            }}
                        />
                    </Col>
                    <Col span="4">
                        <HighlightedTextField
                            highlighted={templateGuidance.CPRA}
                            style={AskAlanStyles.textField}
                            disabled={!editableData}
                            margin="dense"
                            error={this.state.errors.cPRA.length !== 0}
                            helperText={this.state.errors.cPRA}
                            variant="outlined"
                            type="number"
                            label="cPRA"
                            value={cPRA}
                            onChange={this.handleChange('cPRA')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <HighlightedSelect
                            highlighted={templateGuidance.DIABETES_TYPE}
                            value={diabetesType}
                            variant="outlined"
                            label="Diabetes Type"
                            onChange={this.handleChange('diabetesType')}
                            disabled={!editableData}
                            style={AskAlanStyles.textField}
                            margin="dense"
                            error={this.state.errors.diabetesType.length !== 0}
                            options={[
                                { value: 'Please Select', text: 'Please Select', },
                                { value: 'Type I', text: 'Type I', },
                                { value: 'Type II', text: 'Type II', },
                                { value: 'Type Other', text: 'Type Other', },
                                { value: 'Unknown', text: 'Unknown', }
                            ]}
                        />
                    </Col>
                    <Col span={4}>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    style={AskAlanStyles.margin}
                                    disabled={!editableData}
                                    checked={cancer}
                                    onChange={this.handleChange('cancer')}
                                    value="cancer"
                                    color="primary"
                                />
                            )}
                            label="Hist Cancer"
                        />
                    </Col>
                    <Col span={4}>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    style={AskAlanStyles.margin}
                                    disabled={!editableData}
                                    checked={pvd}
                                    onChange={this.handleChange('pvd')}
                                    value="pvd"
                                    color="primary"
                                />
                            )}
                            label="Hist PVD"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span="6">
                        <HighlightedDatePicker
                            highlighted={templateGuidance.LAST_SEEN}
                            style={AskAlanStyles.textField}
                            disabled={!editableData}
                            margin="dense"
                            variant="outlined"
                            label="Last Seen"
                            showTodayButton
                            value={lastSeen}
                            onChange={this.handleChange('lastSeen')}
                            labelFunc={renderDateLabel}
                        />
                    </Col>
                    <Col span={6}>
                        <HighlightedSelect
                            highlighted={templateGuidance.PRIMARY_DIAGNOSIS}
                            value={primaryDiagnosis}
                            variant="outlined"
                            label="Primary Diagnosis"
                            onChange={this.handleChange('primaryDiagnosis')}
                            disabled={!editableData}
                            style={AskAlanStyles.textField}
                            margin="dense"
                            options={[
                                { value: 'Please Select', text: 'Please Select', },
                                { value: 'Congenital', text: 'Congenital', },
                                { value: 'DM', text: 'DM', },
                                { value: 'GN', text: 'GN', },
                                { value: 'HTN', text: 'HTN', },
                                { value: 'Other', text: 'Other', },
                                { value: 'Unknown', text: 'Unknown', }
                            ]}
                        />
                    </Col>

                </Row>
                {(editableData)
                    ? (
                        <div style={AskAlanStyles.actionsContainer}>
                            <Row>
                                <Col span={6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.saveRecord}
                                        style={AskAlanStyles.button}
                                    >
                                        Send to Chatroom
                                    </Button>
                                </Col>
                                <Col>
                                    {((saveCandidateStatus === 'FAILED'))
                                        ? (
                                            <div style={AskAlanStyles.error}>Unable to save</div>
                                        ) : <div style={AskAlanStyles.info}>{saveCandidateStatus}</div>}
                                </Col>
                            </Row>
                        </div>
                    ) : <div />}
            </div>
        );
    }
}

export default Candidate;
