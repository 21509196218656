// @flow

import Colors from '../Themes/Colors';

// This file is for a reusable grouping of Theme items
const HeaderStyles = {
    titleContainer: {
        // Not sure what goes here
    },
    logo: {
        marginLeft: 'auto',
    },
    askAlan: {
        color: Colors.black,
        marginLeft: 25,
    },
    betaTag: {
        color: Colors.red,
        marginLeft: 10,
    },
    customHeader: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 0,
        marginBottom: 0,
    },

};

export default HeaderStyles;
