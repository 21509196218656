// @flow
import React, { Component } from 'react';

import Images from '../Themes/Images';
import HeaderStyles from '../Styles/HeaderStyles';

// Header will likely require props at some point, so this will prevent flow errors for now
type Props = {
    userOrgId: number,
};

// eslint-disable-next-line react/prefer-stateless-function
class Header extends Component<Props> {
    render() {
        const {
            userOrgId,
        } = this.props;

        // Demonstration Code - if moving beyond pilot we need a way to handle the logos and names
        //                      if Buckeye (424) or Intermountain Medical Center (18) or Intermountain Donor Services (369) then display the Intermountain logo
        const custom = (userOrgId && (userOrgId === 18 || userOrgId === 369 || userOrgId === 424));
        const customLogo = Images.imhc;

        return (

            <div>
                {(custom)
                    ? (
                        <div>
                            <div className="headerLogoContainer">
                                <h2 style={HeaderStyles.betaTag}>Beta</h2>
                                <img style={HeaderStyles.logo} src={customLogo} alt="Customer Logo" />
                            </div>
                            <div className="headerLogoContainer">
                                <h2 style={HeaderStyles.customHeader}>Custom Offer</h2>
                            </div>
                        </div>
                    )
                    : (
                        <div>
                            <div className="headerLogoContainer">
                                <img className="headerLogo" src={Images.omniLife} alt="OmniLife Logo" />
                                <h2 style={HeaderStyles.askAlan}>Snapshot&trade;</h2>
                                <h2 style={HeaderStyles.betaTag}>Beta</h2>
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}

export default Header;
