// @flow
import * as Sentry from '@sentry/browser';
import delay from 'redux-saga';
import type { Saga } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import type {
    VerifyApplicationActivationCode,
    GetTemplatePreferences,
} from '../Redux/ActivationActions';

import {
    updateActivationStatus,
    storeTemplatePreferences,
} from '../Redux/ActivationActions';

import {
    getOffer,
    setGetOfferStatus,
} from '../Redux/ClinicalActions';

import defaultOfferData from '../Types/Offer';
import type { OfferData } from '../Types/Offer';
import api from '../Services/Api';

import { apiPost, apiFetch } from './ApiSaga';

export function* getOrganizationTemplatePreferences(action: GetTemplatePreferences): Saga<void> {
    yield call(delay, 50);

    // Don't do anything if undefined, null, or 0
    if (!action.organizationId) {
        return;
    }

    const { result, error, } = yield apiFetch(api.txp.getOrganizations);

    if (error) {
        Sentry.captureException(JSON.stringify(error));
    } else if (result.organizations) {
        // https://github.com/eslint/eslint/issues/12165
        // eslint-disable-next-line no-unused-vars
        for (const organization of result.organizations) {
            if (organization.organization_id === action.organizationId) {
                yield put(storeTemplatePreferences(organization.template_preferences));
                break;
            }
        }
    }
}

export default function* verifyApplicationActivationCode(action: VerifyApplicationActivationCode): Saga<void> {
    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield call(delay, 50);

    const params = {
        activation_code: action.txpData.activationCode,
        user_id: action.txpData.userId,
        chatroom_id: action.txpData.chatId,
    };

    if (action.txpData.activationCode === 'DEMONSTRATION') {
        Sentry.captureEvent('DemonstrationMode');
        yield put(updateActivationStatus('AUTH', 'DEMONSTRATION'));
    } else {
        const { result, error, } = yield apiPost(api.txp.anonymous.verifyApplicationActivationCode, null, params);

        if (error) {
            Sentry.captureException(JSON.stringify(error));
            yield put(updateActivationStatus('UNAUTH', ''));
            return;
        }
        yield put(updateActivationStatus('AUTH', result.access_token));
    }

    // now get the donor record
    if (!action.txpData.donorId || action.txpData.donorId === null) {
        const offerData: OfferData = defaultOfferData;
        offerData.transplant_center = action.txpData.transplantCenter;
        offerData.donor.identification.match_id = action.txpData.matchId;
        offerData.donor.identification.unos_id = action.txpData.unosId;
        offerData.donor.identification.organ_type = action.txpData.organType;
        yield put(setGetOfferStatus('NEW', offerData));
    } else {
        yield put(getOffer(action.txpData.donorId, action.txpData.organId, action.txpData.chatId));
    }
}
