// @flow
import moment from 'moment';

const dateTimeFormat = 'MMMM Do hh:mm a';
const dateFormat = 'MMMM Do';

export const renderDateLabel = (date: any) => {
    if (date !== null && date !== undefined && date.isValid()) {
        return moment(date).format(dateFormat);
    }
    return 'Please Select';
};

export const renderDateTimeLabel = (date: any) => {
    if (date !== null && date !== undefined && date.isValid()) {
        return moment(date).format(dateTimeFormat);
    }
    return 'Please Select';
};
