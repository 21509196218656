/* eslint-disable */
export const kiddadgs1y = {
    baselineHazard: 0.024836793958299,
    predictors: [
        {
            'predictor': 'Candidate ethnicity',
            'level': 'Latino',
            'coefficient': -0.109134252294685,
        },
        {
            'predictor': 'Candidate ethnicity',
            'level': 'Non-Latino or unknown',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate ethnicity',
            'level': 'Missing',
            'coefficient': -0.109134252294685,
        },
        {
            'predictor': 'Candidate PVD',
            'level': 'No',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate PVD',
            'level': 'Yes',
            'coefficient': 0.330191029758762,
        },
        {
            'predictor': 'Candidate PVD',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate total albumin',
            'level': 'Apply to < 3.2 (Left LS)',
            'coefficient': 0.0219203615669342,
        },
        {
            'predictor': 'Candidate total albumin',
            'level': 'Apply to < 4.4 (Left LS)',
            'coefficient': 0.0566368636633714,
        },
        {
            'predictor': 'Candidate total albumin',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'Congenital',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'DM',
            'coefficient': 0.219652772984091,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'GN',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'HTN',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'Other',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor age',
            'level': 'Apply to < 10 (Left LS)',
            'coefficient': 0.0299009012618016,
        },
        {
            'predictor': 'Donor age',
            'level': 'Apply to < 15 (Left LS)',
            'coefficient': 0.000574245071597704,
        },
        {
            'predictor': 'Donor age',
            'level': 'Apply to > 65 (Right LS)',
            'coefficient': -0.0307096346662586,
        },
        {
            'predictor': 'Donor age',
            'level': 'Missing',
            'coefficient': -0.0921289039987758,
        },
        {
            'predictor': 'Donor BMI',
            'level': 'Apply to < 18 (Left LS)',
            'coefficient': 0.0000787883151459914,
        },
        {
            'predictor': 'Donor BMI',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor BUN',
            'level': 'Apply to < 10 (Left LS)',
            'coefficient': -0.00526003302516987,
        },
        {
            'predictor': 'Donor BUN',
            'level': 'Apply to < 15 (Left LS)',
            'coefficient': -0.000786101276729026,
        },
        {
            'predictor': 'Donor BUN',
            'level': 'Missing',
            'coefficient': -0.0462534464969374,
        },
        {
            'predictor': 'Donor\'s organ local vs shared',
            'level': 'Local',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor\'s organ local vs shared',
            'level': 'Shared',
            'coefficient': 0.171187877046198,
        },
        {
            'predictor': 'Donor\'s organ local vs shared',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient total ESRD time (days) at transplant',
            'level': 'Apply to < 1500 (Left LS)',
            'coefficient': -0.0000370266187292,
        },
        {
            'predictor': 'Recipient total ESRD time (days) at transplant',
            'level': 'Apply to < 3000 (Left LS)',
            'coefficient': -0.000125055457330612,
        },
        {
            'predictor': 'Recipient total ESRD time (days) at transplant',
            'level': 'Missing',
            'coefficient': -0.430706300085636,
        },
        {
            'predictor': 'Donor risk index',
            'level': 'Linear',
            'coefficient': 0.47051032238964,
        },
        {
            'predictor': 'Donor risk index',
            'level': 'Apply to < 1.7 (Left LS)',
            'coefficient': -0.324984350713756,
        },
        {
            'predictor': 'Donor risk index',
            'level': 'Apply to > 0.8 (Right LS)',
            'coefficient': 0.000477585725830522,
        },
        {
            'predictor': 'Donor risk index',
            'level': 'Missing',
            'coefficient': 0.0104007158867349,
        },
        {
            'predictor': 'Recipient BMI',
            'level': 'Apply to < 20 (Left LS)',
            'coefficient': 0.0520243319517386,
        },
        {
            'predictor': 'Recipient BMI',
            'level': 'Apply to > 26 (Right LS)',
            'coefficient': 0.0247193002966733,
        },
        {
            'predictor': 'Recipient BMI',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient cold ischemia time',
            'level': 'Linear',
            'coefficient': 0.00451583734320678,
        },
        {
            'predictor': 'Recipient cold ischemia time',
            'level': 'Apply to < 10 (Left LS)',
            'coefficient': -0.0000470449821022926,
        },
        {
            'predictor': 'Recipient cold ischemia time',
            'level': 'Apply to < 15 (Left LS)',
            'coefficient': -0.00626524059316245,
        },
        {
            'predictor': 'Recipient cold ischemia time',
            'level': 'Apply to > 30 (Right LS)',
            'coefficient': 0.0069757224710068,
        },
        {
            'predictor': 'Recipient cold ischemia time',
            'level': 'Missing',
            'coefficient': -0.0593421185921913,
        },
        {
            'predictor': 'Recipient primary insurance',
            'level': 'Other',
            'coefficient': -0.0909061068869108,
        },
        {
            'predictor': 'Recipient primary insurance',
            'level': 'Public',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient primary insurance',
            'level': 'Missing',
            'coefficient': -0.0909061068869108,
        },
        {
            'predictor': 'Recipient transplant procedure type',
            'level': 'EnBloc',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient transplant procedure type',
            'level': 'Left',
            'coefficient': -0.00629802864396614,
        },
        {
            'predictor': 'Recipient transplant procedure type',
            'level': 'Right',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient transplant procedure type',
            'level': 'Sequential',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient transplant procedure type',
            'level': 'Missing',
            'coefficient': -0.00629802864396614,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Apply to < 30 (Left LS)',
            'coefficient': 0.014634377646046,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Apply to > 40 (Right LS)',
            'coefficient': 0.00452465635541692,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Apply to > 50 (Right LS)',
            'coefficient': 0.0022735880314433,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Apply to > 55 (Right LS)',
            'coefficient': 0.0198685982870511,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Missing',
            'coefficient': 0,
        }
    ],
}
