// @flow

import React, { PureComponent } from 'react';
import { Col, Row } from 'react-simple-flex-grid';
import TextField from '@material-ui/core/TextField';
import type { DonorOrganData } from '../Types/DonorOrgan';

import AskAlanStyles from '../Styles/AskAlanStyles';

type Props = {
    donorOrganData: DonorOrganData,
    editable: boolean,
};

class UNOSHeader extends PureComponent<Props> {
    render() {
        const {
            donorOrganData,
            editable,
        } = this.props;

        return (
            <div>
                <Row>
                    <Col>
                        <div style={AskAlanStyles.form}>
                            <span className="groupLabel">Donor ID</span>
                            <Row>
                                <Col span="4">
                                    <TextField
                                        style={AskAlanStyles.textField}
                                        disabled={!editable}
                                        margin="dense"
                                        variant="outlined"
                                        label="UNOS ID"
                                        autoFocus
                                        value={donorOrganData.identification.unos_id}
                                    />
                                </Col>
                                <Col span="4">
                                    <TextField
                                        style={AskAlanStyles.textField}
                                        disabled={!editable}
                                        margin="dense"
                                        variant="outlined"
                                        label="Match ID"
                                        value={donorOrganData.identification.match_id}
                                    />
                                </Col>
                                <Col span="4">
                                    <TextField
                                        style={AskAlanStyles.textField}
                                        disabled={!editable}
                                        margin="dense"
                                        variant="outlined"
                                        label="Organ Type"
                                        value={donorOrganData.identification.organ_type}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </div>
        );
    }
}

export default UNOSHeader;
