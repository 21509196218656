// @flow
import type { DonorOrganData } from '../Types/DonorOrgan';
import type { CandidateRecipientData } from '../Types/CandidateRecipient';

import * as riskAdjustmentModels from './RiskAdjustmentModels';
import * as waitListMortalityModels from './WaitlistMortalityModels';
import * as transplantRateModels from './DeceasedDonorTransplantRate';

export type SplineInfo = {
    value: number,
    coefficient: number
}

export type PredictionResponse = {
    baselineHazard: number,
    baselineHazardRatio: number,
    baselineSurvivalRate: number,
    baselineSurvivalPercent: number,
    specificHazard: number,
    specificHazardRatio: number,
    specificSurvivalRate: number,
    specificSurvivalPercent: number,
};

export type RiskFactor = {
    factorName: string,
    factorValue: string,
    specificHazard: number,
    hazardRatio: number,
    factorDisplayLabel: string
}

/**
 * Calculate the coefficient value used for left linear splines given the input and
 * the SplineInfo for a single comparison.
 * @param {number} input
 *      Donor/Candidate input data
 * @param {SplineInfo} splineInfo
 *      The info associated with the Linar Spline for one comparision
 * @returns {number} leftLinearSpline
 */
const calculateLeftLinearSpline = (input: number, splineInfo: SplineInfo): number => {
    let sub: number = splineInfo.value - input;
    sub = (sub > 0) ? sub : 0;

    return sub * splineInfo.coefficient;
};

/**
 * Calculate the coefficient value for right linear splines used given the input and
 * the SplineInfo for a single comparison.
 * @param {number} input
 *      Donor/Candidate input data
 * @param {SplineInfo} splineInfo
 *      The info associated with the Linar Spline for one comparision
 * @returns {number} leftLinearSpline
 */
const calculateRightLinearSpline = (input: number, splineInfo: SplineInfo): number => {
    let sub: number = input - splineInfo.value;
    sub = (sub > 0) ? sub : 0;

    return sub * splineInfo.coefficient;
};

export const createDisplayName = (riskFactor: RiskFactor, totalAllowedLength: number): string => {
    // No limit to the display name if number is not a valid number of characters.
    const label = riskFactor.factorDisplayLabel;
    const value = riskFactor.factorValue;

    let adjustedTotalAllowedLength = totalAllowedLength;
    if (adjustedTotalAllowedLength <= 0) {
        adjustedTotalAllowedLength = Number.MAX_SAFE_INTEGER;
    }
    const totalLength: number = label.length + value.length;
    let displayName: string;
    if (label.length >= adjustedTotalAllowedLength) {
        displayName = label;
        return `${label.substring(0, totalAllowedLength - 3)}...`;
    }
    if (totalLength > totalAllowedLength) {
        const allowedValueLength = totalAllowedLength - label.length;
        displayName = `${label} (${value.substring(0, allowedValueLength - 3)}...)`;
    } else {
        displayName = `${label} (${value})`;
    }
    return displayName;
};

const getCohortAbbreviation = (ageGroup: string): string => ((ageGroup.toUpperCase() === 'PEDIATRIC') ? 'pe' : 'ad');

const getOrganAbbreviation = (organ: string): string => {
    let abbr: string;
    switch (organ.toUpperCase()) {
        case 'KIDNEY':
            abbr = 'ki';
            break;
        default:
            abbr = '';
            break;
    }
    return abbr;
};

/**
 * Calculates the risk factor by looking at all SplineInfo objects and
 * adding the calculated linear spline values together and adds the value
 * to the RiskFactors array.
 *
 * @param {string} predictor
 * @param {number} input
 * @param {any} riskAdjustmentModel
 * @param {RiskFactor[]} riskFactors
 */
export const addCalculatedRiskFactor = (predictor: string, input: number, riskAdjustmentModel: any, riskFactors: RiskFactor[],
    factorDisplayLabel: string) => {
    // Add left and right linear spline values
    let specificHazard: number = 0;
    let hazardRatio: number = 1;
    let riskFactor: RiskFactor = {};
    let level: string = '';

    if (input && riskAdjustmentModel.predictors[predictor]) {
        // If the input is any number besides 0 and the predictor exists.
        level = input.toString();
        const { leftLinearSplines, } = riskAdjustmentModel.predictors[predictor];
        const { rightLinearSplines, } = riskAdjustmentModel.predictors[predictor];

        // Iterate through the left linear splines
        for (let i = 0; i < leftLinearSplines.length; i += 1) {
            specificHazard += calculateLeftLinearSpline(input, leftLinearSplines[i]);
        }

        // Iterate through the right linear splines
        for (let i = 0; i < rightLinearSplines.length; i += 1) {
            specificHazard += calculateRightLinearSpline(input, rightLinearSplines[i]);
        }

        //
        // If the risk model contains a level for Linear then add that
        //   to the adjustment.
        if (riskAdjustmentModel.predictors[predictor].Linear) {
            specificHazard += input * riskAdjustmentModel.predictors[predictor].Linear;
        }

        hazardRatio = Math.E ** specificHazard;
    }

    if (specificHazard !== 0) {
        riskFactor = {
            factorName: predictor,
            factorValue: level,
            specificHazard,
            hazardRatio,
            factorDisplayLabel,
        };
        riskFactors.push(riskFactor);
    }
};

/**
 * Looks up the RiskFactor for the given parameters and input
 *   and adds it to the RiskFactors Array
 * @param {string} predictor
 *   The category that the input value is representing.
 * @param {string} level
 *   The input value associated with the category
 * @param {any} riskAdjustmentModel
 *   The risk model used to calculate/lookup coefficients obtained from the getRiskAdjusmentModel method
 * @param {RiskFactor[]} riskFactors
 *   The array of risk factors that is being added to.
 */
export const addLookedupRiskFactor = (predictor: string, input: ?string, riskAdjustmentModel: any, riskFactors: RiskFactor[],
    factorDisplayLabel: string) => {
    let specificHazard: number = 0;
    const level = input || '';
    let hazardRatio: number = 1;

    if (level && riskAdjustmentModel.predictors[predictor] && riskAdjustmentModel.predictors[predictor][level]) {
        specificHazard = riskAdjustmentModel.predictors[predictor][level];
        hazardRatio = Math.E ** specificHazard;
    }

    if (specificHazard !== 0) {
        const riskFactor: RiskFactor = {
            factorName: predictor,
            factorValue: level,
            specificHazard,
            hazardRatio,
            factorDisplayLabel,
        };
        riskFactors.push(riskFactor);
    }
};

/**
 * Takes the Raw Risk Adjustment model and converts it
 * into a more useable format
 * @param {any} unprocessedRiskAdjustmentModel
 */
const processRiskAdjustmentModel = (unprocessedRiskAdjustmentModel: any, predictorStr: string, levelStr: string, coefficientStr: string): any => {
    const rangeRegex = /Apply to (<|>) ([0-9]+\.?[0-9]*) \((Left LS|Right LS)\)/;
    const riskAdjustmentModel = {};
    riskAdjustmentModel.predictors = {};
    riskAdjustmentModel.baselineHazard = unprocessedRiskAdjustmentModel.baselineHazard;

    if (unprocessedRiskAdjustmentModel.predictors) {
        unprocessedRiskAdjustmentModel.predictors.forEach((predictorObj) => {
            const predictor = predictorObj[predictorStr];
            const level = predictorObj[levelStr];

            if (!riskAdjustmentModel.predictors[predictor]) {
                riskAdjustmentModel.predictors[predictor] = {};
                riskAdjustmentModel.predictors[predictor].leftLinearSplines = [];
                riskAdjustmentModel.predictors[predictor].rightLinearSplines = [];
            }

            const match = rangeRegex.exec(level);
            if (match) {
                const value = parseFloat(match[2]);
                const coefficient = predictorObj[coefficientStr];
                const splineInfo: SplineInfo = { value, coefficient, };

                if (match[3] === 'Left LS') {
                    riskAdjustmentModel.predictors[predictor].leftLinearSplines.push(splineInfo);
                } else if (match[3] === 'Right LS') {
                    riskAdjustmentModel.predictors[predictor].rightLinearSplines.push(splineInfo);
                }
            } else {
                riskAdjustmentModel.predictors[predictor][level] = predictorObj[coefficientStr];
            }
        });
    }

    return riskAdjustmentModel;
};

/**
 * Helper method to get the baseline hazard number.
 * @param {any} riskAdjustmentModel
 *   The risk model used to calculate/lookup coefficients obtained from the getRiskAdjusmentModel method
 */
export const getBaselineHazard = (riskAdjustmentModel: any): number => riskAdjustmentModel.baselineHazard;

/**
 * Get the PredictionResponse given the candidate and donor factors.
 * @param {any} riskAdjustmentModel
 *   The risk model used to calculate/lookup coefficients obtained from the getRiskAdjusmentModel method
 * @param {RiskFactor[]} donorFactors
 *   The factors that adjust the organs survival from the donor
 * @param {RiskFactor[]} candidateFactors
 *   The factors that adjust the organs survival from the candidate
 */
export const predict = (riskAdjustmentModel: any, donorFactors: RiskFactor[], candidateFactors: RiskFactor[]): PredictionResponse => {
    let response: PredictionResponse = {};
    const baselineHazard: number = getBaselineHazard(riskAdjustmentModel);
    const baselineHazardRatio: number = Math.E ** baselineHazard;
    let specificHazardAdjustment: number = 0;

    let i: number = 0;
    for (i = 0; i < donorFactors.length; i += 1) {
        specificHazardAdjustment += donorFactors[i].specificHazard;
    }

    i = 0;
    for (i = 0; i < candidateFactors.length; i += 1) {
        specificHazardAdjustment += candidateFactors[i].specificHazard;
    }

    // TODO: Make sure this is the correct calculation for the hazard ratio including the baseline.
    const specificHazardRatio = Math.E ** (specificHazardAdjustment + baselineHazard);
    const specificHazard = baselineHazard * (Math.E ** (specificHazardAdjustment));

    const baselineSurvivalRate = Math.E ** (-baselineHazard);
    const specificSurvivalRate = Math.E ** (-specificHazard);

    const baselineSurvivalPercent = baselineSurvivalRate * 100;
    const specificSurvivalPercent = specificSurvivalRate * 100;

    response = {
        baselineHazard,
        baselineHazardRatio,
        baselineSurvivalRate,
        baselineSurvivalPercent,
        specificHazard,
        specificHazardRatio,
        specificSurvivalRate,
        specificSurvivalPercent,
    };

    return response;
};

/*
 * Graft Survival/Patient Survival Specific Methods
 */

/**
 * Load and process the correct risk adjustment model given the
 * parameters.
 * @param {string} cohort
 *      Age group of the organ donor/candidate (ADULT or PEDIATRIC)
 * @param {string} donorType
 *      Donor Type of the organ (DECEASED or LIVING)
 * @param {string} period
 *      Period for the organ survival rate (1YR or 3YR)
 */
export const getRiskAdjustmentModel = (cohort: string, donorType: string, period: string): any => {
    // For now we are always using kidney and graft survival
    const organAbbr: string = 'ki';
    const outcomeAbbr: string = 'gs';

    const donorTypeAbbr: string = (donorType === 'DECEASED') ? 'dd' : 'ld';
    const cohortAbbr: string = (cohort === 'ADULT') ? 'ad' : 'pe';
    const periodAbbr: string = (period === '1YR') ? '1y' : '3y';

    // Combine the abbreviations to get the risk adjustment model
    const model: string = organAbbr + donorTypeAbbr + cohortAbbr + outcomeAbbr + periodAbbr;

    return processRiskAdjustmentModel(riskAdjustmentModels[model], 'predictor', 'level', 'coefficient');
};

/**
 * Get an array of RiskFactors for the given donor.
 * @param {DonorOrganData} donor
 *   The object representing the donor
 * @param {any} riskAdjustmentModel
 *   The risk model used to calculate/lookup coefficients obtained from the getRiskAdjusmentModel method
 */
export const loadDonorRiskFactors = (donor: DonorOrganData, riskAdjustmentModel: any): RiskFactor[] => {
    const ethnicityValue = (donor.demographics.ethnicity === 'Not Latino' || donor.demographics.ethnicity === 'Unknown')
        ? 'Non-Latino or unknown' : donor.demographics.ethnicity;

    const riskFactors: RiskFactor[] = [];
    addLookedupRiskFactor('Donor race', donor.demographics.race, riskAdjustmentModel, riskFactors, 'Race');
    addLookedupRiskFactor('Donor ethnicity', ethnicityValue, riskAdjustmentModel, riskFactors, 'Ethnicity');
    addLookedupRiskFactor('Donor gender', donor.demographics.sex, riskAdjustmentModel, riskFactors, 'Sex');
    addLookedupRiskFactor('Donor history of diabetes', ((donor.labs.hba1c > 0) ? 'Yes' : 'No'), riskAdjustmentModel, riskFactors, 'Diabetes');
    addLookedupRiskFactor('Donor cause of death', donor.admission.cause_of_death, riskAdjustmentModel, riskFactors, 'COD');
    addCalculatedRiskFactor('Donor BMI', donor.demographics.bmi, riskAdjustmentModel, riskFactors, 'BMI');
    addCalculatedRiskFactor('Donor age', donor.demographics.age, riskAdjustmentModel, riskFactors, 'Age');
    addCalculatedRiskFactor('Donor serum creatinine', donor.labs.current_creatinine, riskAdjustmentModel, riskFactors, 'Creatinine');
    return riskFactors;
};

/**
 * Get an array of {@RiskRiskFactor} for the given candidate.
 * @param {CandidateRecipientData} candidate
 *   The object representing the donor
 * @param {any} riskAdjustmentModel
 *   The risk model used to calculate/lookup coefficients obtained from the getRiskAdjusmentModel method
 */
export const loadCandidateRiskFactors = (candidate: CandidateRecipientData, riskAdjustmentModel: any): RiskFactor[] => {
    // Possible values for diabetes and ethnicty are combined in the riskAdjustment model.
    const diabetesValue = (candidate.history.diabetes_type === 'Type Other' || candidate.history.diabetes_type === 'Unknown')
        ? 'Type Other/Unknown' : candidate.history.diabetes_type;
    const ethnicityValue = (candidate.demographics.ethnicity === 'Not Latino' || candidate.demographics.ethnicity === 'Unknown')
        ? 'Non-Latino or unknown' : candidate.demographics.ethnicity;

    const riskFactors: RiskFactor[] = [];
    addLookedupRiskFactor('Candidate race', candidate.demographics.race, riskAdjustmentModel, riskFactors, 'Race');
    addLookedupRiskFactor('Candidate gender', candidate.demographics.sex, riskAdjustmentModel, riskFactors, 'Sex');
    addLookedupRiskFactor('Candidate ethnicity', ethnicityValue, riskAdjustmentModel, riskFactors, 'Ethnicity');
    addLookedupRiskFactor('Candidate PVD', (candidate.history.pvd ? 'Yes' : 'No'), riskAdjustmentModel, riskFactors, 'PVD');
    addLookedupRiskFactor('Candidate previous malignancy', (candidate.history.cancer ? 'Yes' : 'No'),
        riskAdjustmentModel, riskFactors, 'Cancer');
    addLookedupRiskFactor('Recipient primary diagnosis at transplant', candidate.history.primary_diagnosis,
        riskAdjustmentModel, riskFactors, 'Diagnosis');
    addLookedupRiskFactor('Candidate diabetes status/type at onset', diabetesValue, riskAdjustmentModel, riskFactors, 'Diabetes');
    addLookedupRiskFactor('Candidate blood type', candidate.demographics.blood_type, riskAdjustmentModel, riskFactors, 'Blood type');
    addCalculatedRiskFactor('Recipient BMI', candidate.demographics.bmi, riskAdjustmentModel, riskFactors, 'BMI');
    addCalculatedRiskFactor('Recipient age', candidate.demographics.age, riskAdjustmentModel, riskFactors, 'Age');
    addCalculatedRiskFactor('Recipient total ESRD time (days) at transplant', candidate.history.esrd_time,
        riskAdjustmentModel, riskFactors, 'ESRD');
    addCalculatedRiskFactor('Recipient most recent CPRA', candidate.labs.cpra, riskAdjustmentModel, riskFactors, 'CPRA');
    return riskFactors;
};

/*
 * Waitlist Survival Specific Methods
 */

/**
 * Load and process the correct risk adjustment model given the
 * parameters.
 * @param {string} organ
 *      The organ that the model applies to.
 * @param {string} cohort
 *      Age group of the organ donor/candidate (ADULT or PEDIATRIC)
 */
export const getWaitlistMortalityModel = (organ: string, cohort: string): any => {
    // For now we are always using kidney and graft survival
    const organAbbr: string = getOrganAbbreviation(organ);
    const cohortAbbr = getCohortAbbreviation(cohort);
    const waitlistMortalityAbbr: string = 'dt';

    // Combine the abbreviations to get the risk adjustment model
    const model: string = organAbbr + cohortAbbr + waitlistMortalityAbbr;

    return processRiskAdjustmentModel(waitListMortalityModels[model], 'Element', 'Level', 'Coefficient');
};

/**
 * Return the correct processed deceased transplant rate model given the parameters.
 * @param {string} organ
 *      The organ that the model applies to.
 * @param {string} cohort
 *      Age group of the organ donor/candidate (ADULT or PEDIATRIC)
 */
export const getDeceasedTransplantRateModel = (organ: string, cohort: string): any => {
    const organAbbr: string = getOrganAbbreviation(organ);
    const cohortAbbr: string = getCohortAbbreviation(cohort);
    const deceasedTransplantAbbr: string = 'ddtx';
    const model = organAbbr + cohortAbbr + deceasedTransplantAbbr;

    return processRiskAdjustmentModel(transplantRateModels[model], 'Element', 'Level', 'Coefficient');
};

/**
 * Get an array of {@RiskRiskFactor} for the given candidate.
 * @param {CandidateRecipientData} candidate
 *   The object representing the donor
 * @param {any} riskAdjustmentModel
 *   The risk model used to calculate/lookup coefficients obtained from the getWaitlistMortalityModel method
 */
export const loadCandidateWaitlistRiskFactors = (candidate: CandidateRecipientData, riskAdjustmentModel: any): RiskFactor[] => {
    const esrdTimeYears = parseFloat(candidate.history.esrd_time) / 365.0;
    const raceValue = (candidate.demographics.race && candidate.demographics.race !== 'Asian' && candidate.demographics.race !== 'White')
        ? 'Other' : candidate.demographics.race;

    // NOTE: Using esrd_time (days) as waitlist time for now.
    let natLogWaitlistTime = 0;
    if (candidate.history.esrd_time) {
        natLogWaitlistTime = Math.log(parseFloat(candidate.history.esrd_time));
    }

    // Set the primary diagnosis value to other if it is not one of the given 4 strings:
    //   Congenital, Diabetes, Glomerulonephritis, Hypertension
    let diagnosisVal;
    if (candidate.history.primary_diagnosis) {
        switch (candidate.history.primary_diagnosis) {
            case 'Congenital':
            case 'Diabetes':
            case 'Glomerulonephritis':
            case 'Hypertension':
                diagnosisVal = candidate.history.primary_diagnosis;
                break;
            default:
                diagnosisVal = 'Other';
                break;
        }
    }

    // Add the risk factors.
    const riskFactors: RiskFactor[] = [];
    addLookedupRiskFactor('Candidate race', raceValue, riskAdjustmentModel, riskFactors, 'Race');
    addLookedupRiskFactor('Candidate sex', candidate.demographics.sex, riskAdjustmentModel, riskFactors, 'Sex');
    addLookedupRiskFactor('Candidate ethnicity', candidate.demographics.ethnicity, riskAdjustmentModel, riskFactors, 'Ethnicity');
    addLookedupRiskFactor('Candidate PVD', (candidate.history.pvd ? 'Yes' : 'No'), riskAdjustmentModel, riskFactors, 'PVD');
    addLookedupRiskFactor('Candidate previous malignancy', (candidate.history.cancer ? 'Yes' : 'No'),
        riskAdjustmentModel, riskFactors, 'Cancer');
    addLookedupRiskFactor('Candidate primary diagnosis', diagnosisVal, riskAdjustmentModel, riskFactors, 'Diagnosis');
    addLookedupRiskFactor('Candidate diabetes type', candidate.history.diabetes_type, riskAdjustmentModel, riskFactors, 'Diabetes');
    addLookedupRiskFactor('Candidate blood type', candidate.demographics.blood_type, riskAdjustmentModel, riskFactors, 'Blood type');
    addCalculatedRiskFactor('Candidate BMI', candidate.demographics.bmi, riskAdjustmentModel, riskFactors, 'BMI');
    addCalculatedRiskFactor('Candidate age at listing', candidate.demographics.age, riskAdjustmentModel, riskFactors, 'Age');
    addCalculatedRiskFactor('Days on the waiting list at beginning of the cohort (natural-log)', natLogWaitlistTime,
        riskAdjustmentModel, riskFactors, 'Waitlist time');
    addCalculatedRiskFactor('Years since initial development of ESRD', esrdTimeYears, riskAdjustmentModel, riskFactors, 'ESRD');
    addCalculatedRiskFactor('Candidate cPRA within 1 month of listing', candidate.labs.cpra, riskAdjustmentModel, riskFactors, 'CPRA');
    addCalculatedRiskFactor('Candidate weight', candidate.demographics.weight, riskAdjustmentModel, riskFactors, 'Weight');
    addCalculatedRiskFactor('Candidate height', candidate.demographics.height, riskAdjustmentModel, riskFactors, 'Height');

    return riskFactors;
};

export default predict;
