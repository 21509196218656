// @flow

export type CandidateDataDemographics = {
    mrn: string,
    full_name: string,
    sex: ?string,
    age: number,
    blood_type: ?string,
    bmi: number,
    height: number,
    weight: number,
    race: ?string,
    ethnicity: ?string,
}

export type CandidateDataHistory = {
    prior_transplant: boolean,
    esrd_time: number,
    diabetes_type: ?string,
    cancer: boolean,
    pvd: boolean,
    last_seen: ?string,
    primary_diagnosis: ?string,
}

export type CandidateDataLabs = {
    cpra: number,
}

export type CandidateData = {
    candidate_id: number,
    demographics: CandidateDataDemographics,
    history: CandidateDataHistory,
    labs: CandidateDataLabs,
};
export const defaultCandidateDataDemographics: CandidateDataDemographics = {
    mrn: '', full_name: '', sex: '', age: 0, blood_type: '', bmi: 0, height: 0, weight: 0, race: '', ethnicity: '',
};
export const defaultCandidateDataHistory: CandidateDataHistory = {
    prior_transplant: false, esrd_time: 0, diabetes_type: '', cancer: false, pvd: false, last_seen: '', primary_diagnosis: '',
};
export const defaultCandidateDataLabs: CandidateDataLabs = { cpra: 0, };
export const defaultCandidateData: CandidateData = {
    candidate_id: 0, demographics: defaultCandidateDataDemographics, history: defaultCandidateDataHistory, labs: defaultCandidateDataLabs,
};
