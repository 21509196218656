// @flow

import React, { PureComponent } from 'react';
import { Col, Row } from 'react-simple-flex-grid';
import { HorizontalBar } from 'react-chartjs-2';
import UNOSHeader from './UNOSHeader';
import DemogHeader from './DemogHeader';

import type { RiskFactor } from '../Analytics/Predictor';
import {
    getRiskAdjustmentModel, loadDonorRiskFactors, loadCandidateRiskFactors, predict, createDisplayName,
} from '../Analytics/Predictor';
import combineCandidateData from '../Utils/combineCandidateRecipientData';

import type { DonorOrganData } from '../Types/DonorOrgan';
import type { CandidateData } from '../Types/Candidate';
import type { RecipientData } from '../Types/Recipient';

import AskAlanStyles from '../Styles/AskAlanStyles';

type Props = {
    donorOrganData: DonorOrganData,
    candidateData: CandidateData,
    recipientData: RecipientData,
};

class GraftSurvival extends PureComponent<Props> {
    render() {
        const {
            donorOrganData,
            candidateData,
            recipientData,
        } = this.props;

        const showStats = donorOrganData.identification.organ_type === 'Kidney';
        if (!showStats) {
            return (
                <div>
                    {`Not yet available for ${donorOrganData.identification.organ_type}`}
                </div>
            );
        }

        // Merge candidate and recipient data before getting risk factors.
        //   Candidate is from the TC and supercedes recipient where there is overlap.
        const mergedCandidateData = combineCandidateData(recipientData, candidateData);

        const oneYearRiskModel = getRiskAdjustmentModel('ADULT', 'DECEASED', '1YR');
        const threeYearRiskModel = getRiskAdjustmentModel('ADULT', 'DECEASED', '3YR');

        const donorRiskFactors1yr: RiskFactor[] = loadDonorRiskFactors(donorOrganData, oneYearRiskModel);
        const donorRiskFactors3yr: RiskFactor[] = loadDonorRiskFactors(donorOrganData, threeYearRiskModel);
        const candidateRiskFactors1yr: RiskFactor[] = loadCandidateRiskFactors(mergedCandidateData, oneYearRiskModel);
        const candidateRiskFactors3yr: RiskFactor[] = loadCandidateRiskFactors(mergedCandidateData, threeYearRiskModel);

        const p1yr = predict(oneYearRiskModel, donorRiskFactors1yr, candidateRiskFactors1yr);
        const p3yr = predict(threeYearRiskModel, donorRiskFactors3yr, candidateRiskFactors3yr);

        const displayNameSize = 17;
        const donorFactorsApplied1YR = donorRiskFactors1yr.map((rf) => (
            <span key={rf.factorName}>
                {createDisplayName(rf, displayNameSize)}
                {' : '}
                {rf.hazardRatio.toFixed(4)}
                <br />
            </span>
        ));
        const donorFactorsApplied3YR = donorRiskFactors3yr.map((rf) => (
            <span key={rf.factorName}>
                {createDisplayName(rf, displayNameSize)}
                {' : '}
                {rf.hazardRatio.toFixed(4)}
                <br />
            </span>
        ));
        const candidateFactorsApplied1YR = candidateRiskFactors1yr.map((rf) => (
            <span key={rf.factorName}>
                {createDisplayName(rf, displayNameSize)}
                {' : '}
                {rf.hazardRatio.toFixed(4)}
                <br />
            </span>
        ));
        const candidateFactorsApplied3YR = candidateRiskFactors3yr.map((rf) => (
            <span key={rf.factorName}>
                {createDisplayName(rf, displayNameSize)}
                {' : '}
                {rf.hazardRatio.toFixed(4)}
                <br />
            </span>
        ));

        let specific1yrColor = (p1yr.baselineSurvivalPercent > p1yr.specificSurvivalPercent) ? 'rgb(180, 0, 0)' : 'rgb(0,180,0)';
        let specific3yrColor = (p3yr.baselineSurvivalPercent > p3yr.specificSurvivalPercent) ? 'rgb(180, 0, 0)' : 'rgb(0,180,0)';
        if (p1yr.baselineSurvivalPercent === p1yr.specificSurvivalPercent) specific1yrColor = 'rgb(180,180,180)';
        if (p3yr.baselineSurvivalPercent === p3yr.specificSurvivalPercent) specific3yrColor = 'rgb(180,180,180)';

        const chartData = {
            labels: ['Baseline 1yr', 'Specific 1yr', 'Baseline 3yr', 'Specific 3yr'],
            datasets: [{
                label: 'SRTR Prediction',
                backgroundColor: ['rgb(0, 100, 0)', specific1yrColor, 'rgb(0, 100, 0)', specific3yrColor],
                data: [p1yr.baselineSurvivalPercent, p1yr.specificSurvivalPercent,
                    p3yr.baselineSurvivalPercent, p3yr.specificSurvivalPercent],
            }],
        };

        return (
            <div>

                <UNOSHeader donorOrganData={donorOrganData} editable={false} />
                <DemogHeader candidateData={candidateData} />
                <h2>Graft Survival [Adult] [Deceased Donor]</h2>
                <div style={AskAlanStyles.form}>
                    <span className="groupLabelCenter">Baseline Hazard</span>
                    <Row>
                        <Col span={2} />
                        <Col span={5}><span className="groupLabelCenter">1 Year</span></Col>
                        <Col span={5}><span className="groupLabelCenter">3 Year</span></Col>
                    </Row>
                    <Row>
                        <Col span={2}><span className="groupLabel">Hazard</span></Col>
                        <Col span={5}>{p1yr.baselineHazardRatio.toFixed(4)}</Col>
                        <Col span={5}>{p3yr.baselineHazardRatio.toFixed(4)}</Col>
                    </Row>
                    <Row>
                        <Col span={2}><span className="groupLabel">Survival</span></Col>
                        <Col span={5}>{`${p1yr.baselineSurvivalPercent.toFixed(2)}%` }</Col>
                        <Col span={5}>{`${p3yr.baselineSurvivalPercent.toFixed(2)}%` }</Col>
                    </Row>

                    <br />
                    <span className="groupLabelCenter">Donor Factors Applied</span>
                    <Row>
                        <Col span={2} />
                        <Col span={5}><span className="groupLabel">{donorFactorsApplied1YR}</span></Col>
                        <Col span={5}><span className="groupLabel">{donorFactorsApplied3YR}</span></Col>
                    </Row>

                    <br />
                    <span className="groupLabelCenter">Candidate Factors Applied</span>
                    <Row>
                        <Col span={2} />
                        <Col span={5}><span className="groupLabel">{candidateFactorsApplied1YR}</span></Col>
                        <Col span={5}><span className="groupLabel">{candidateFactorsApplied3YR}</span></Col>
                    </Row>

                    <br />
                    <span className="groupLabelCenter">Predicted Outcome</span>
                    <Row>
                        <Col span={2}><span className="groupLabel">Hazard</span></Col>
                        <Col span={5}>{p1yr.specificHazardRatio.toFixed(4)}</Col>
                        <Col span={5}>{p3yr.specificHazardRatio.toFixed(4)}</Col>
                    </Row>
                    <Row>
                        <Col span={2}>
                            <span className="groupLabel">Survival</span>
                        </Col>
                        <Col span={5}>{`${p1yr.specificSurvivalPercent.toFixed(2)}%` }</Col>
                        <Col span={5}>{`${p3yr.specificSurvivalPercent.toFixed(2)}%` }</Col>
                    </Row>
                    <HorizontalBar data={chartData} />
                </div>

            </div>
        );
    }
}

export default GraftSurvival;
