/* eslint-disable */
export const kildadgs3y = {
    baselineHazard: 0.045231042253872,
    predictors: [
        {
            'predictor': 'Recipient crossmatch (T cell, B cell)',
            'level': 'Not Positive',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient crossmatch (T cell, B cell)',
            'level': 'Positive',
            'coefficient': 0.059789121389937,
        },
        {
            'predictor': 'Recipient crossmatch (T cell, B cell)',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate blood type',
            'level': 'A',
            'coefficient': 0.127219345814732,
        },
        {
            'predictor': 'Candidate blood type',
            'level': 'AB',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate blood type',
            'level': 'B',
            'coefficient': -0.112361322332642,
        },
        {
            'predictor': 'Candidate blood type',
            'level': 'O',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate blood type',
            'level': 'Missing',
            'coefficient': -0.112361322332642,
        },
        {
            'predictor': 'Candidate diabetes status/type at onset',
            'level': 'None',
            'coefficient': -0.0602152502127037,
        },
        {
            'predictor': 'Candidate diabetes status/type at onset',
            'level': 'Type I',
            'coefficient': 0.0190904033635693,
        },
        {
            'predictor': 'Candidate diabetes status/type at onset',
            'level': 'Type II',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate diabetes status/type at onset',
            'level': 'Type Other/Unknown',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate diabetes status/type at onset',
            'level': 'Missing',
            'coefficient': -0.0602152502127037,
        },
        {
            'predictor': 'Candidate PVD',
            'level': 'No',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate PVD',
            'level': 'Yes',
            'coefficient': 0.445918846953159,
        },
        {
            'predictor': 'Candidate PVD',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate race',
            'level': 'Asian',
            'coefficient': -0.25208020757744,
        },
        {
            'predictor': 'Candidate race',
            'level': 'Black',
            'coefficient': 0.194953697929107,
        },
        {
            'predictor': 'Candidate race',
            'level': 'Multiracial',
            'coefficient': -0.207334058625117,
        },
        {
            'predictor': 'Candidate race',
            'level': 'Native American',
            'coefficient': 0.219820428870089,
        },
        {
            'predictor': 'Candidate race',
            'level': 'Pacific Islander',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate race',
            'level': 'White',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate race',
            'level': 'Missing',
            'coefficient': -0.25208020757744,
        },
        {
            'predictor': 'Candidate total albumin',
            'level': 'Apply to < 2.8 (Left LS)',
            'coefficient': 0.133203665107723,
        },
        {
            'predictor': 'Candidate total albumin',
            'level': 'Apply to < 3.6 (Left LS)',
            'coefficient': 0.000416484575105675,
        },
        {
            'predictor': 'Candidate total albumin',
            'level': 'Apply to < 3.8 (Left LS)',
            'coefficient': 0.0923153448635835,
        },
        {
            'predictor': 'Candidate total albumin',
            'level': 'Apply to < 4.2 (Left LS)',
            'coefficient': 0.270778431276943,
        },
        {
            'predictor': 'Candidate total albumin',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'CMV interaction (donor and recipient)',
            'level': 'Recipient CMV Not Positive Donor CMV No',
            'coefficient': -0.0564789282495465,
        },
        {
            'predictor': 'CMV interaction (donor and recipient)',
            'level': 'Recipient CMV Not Positive Donor CMV Yes',
            'coefficient': 0.209290928122986,
        },
        {
            'predictor': 'CMV interaction (donor and recipient)',
            'level': 'Recipient CMV Positive Donor CMV No',
            'coefficient': 0,
        },
        {
            'predictor': 'CMV interaction (donor and recipient)',
            'level': 'Recipient CMV Positive Donor CMV Yes',
            'coefficient': 0,
        },
        {
            'predictor': 'CMV interaction (donor and recipient)',
            'level': 'Missing',
            'coefficient': -0.0564789282495465,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'Congenital',
            'coefficient': -0.272562487810713,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'DM',
            'coefficient': 0.0569078341555971,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'GN',
            'coefficient': -0.00840856310174293,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'HTN',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'Other',
            'coefficient': 0.00153788103692627,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'Missing',
            'coefficient': -0.272562487810713,
        },
        {
            'predictor': 'Donor age',
            'level': 'Apply to > 45 (Right LS)',
            'coefficient': 0.0155948575803554,
        },
        {
            'predictor': 'Donor age',
            'level': 'Apply to > 65 (Right LS)',
            'coefficient': 0.131391303887739,
        },
        {
            'predictor': 'Donor age',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor BMI',
            'level': 'Apply to > 26 (Right LS)',
            'coefficient': 0.00151526016889885,
        },
        {
            'predictor': 'Donor BMI',
            'level': 'Apply to > 31 (Right LS)',
            'coefficient': 0.0145354026723853,
        },
        {
            'predictor': 'Donor BMI',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor ethnicity',
            'level': 'Latino',
            'coefficient': -0.174415269765813,
        },
        {
            'predictor': 'Donor ethnicity',
            'level': 'Non-Latino or unknown',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor ethnicity',
            'level': 'Missing',
            'coefficient': -0.174415269765813,
        },
        {
            'predictor': 'Donor paired donation',
            'level': 'No',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor paired donation',
            'level': 'Yes',
            'coefficient': 0.0375005859660725,
        },
        {
            'predictor': 'Donor paired donation',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor race',
            'level': 'Asian',
            'coefficient': -0.135220500053687,
        },
        {
            'predictor': 'Donor race',
            'level': 'Black',
            'coefficient': 0.00000968358266487759,
        },
        {
            'predictor': 'Donor race',
            'level': 'Multiracial',
            'coefficient': 0.683609160415122,
        },
        {
            'predictor': 'Donor race',
            'level': 'Native American',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor race',
            'level': 'Pacific Islander',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor race',
            'level': 'White',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor race',
            'level': 'Missing',
            'coefficient': -0.135220500053687,
        },
        {
            'predictor': 'EBV interaction (donor and recipient)',
            'level': 'Recipient EBV Not Positive Donor EBV No',
            'coefficient': 0,
        },
        {
            'predictor': 'EBV interaction (donor and recipient)',
            'level': 'Recipient EBV Not Positive Donor EBV Yes',
            'coefficient': 0.0328510194238132,
        },
        {
            'predictor': 'EBV interaction (donor and recipient)',
            'level': 'Recipient EBV Positive Donor EBV No',
            'coefficient': -0.0665272025314539,
        },
        {
            'predictor': 'EBV interaction (donor and recipient)',
            'level': 'Recipient EBV Positive Donor EBV Yes',
            'coefficient': 0,
        },
        {
            'predictor': 'EBV interaction (donor and recipient)',
            'level': 'Missing',
            'coefficient': -0.0665272025314539,
        },
        {
            'predictor': 'Recipient total ESRD time (days) at transplant',
            'level': 'Apply to < 500 (Left LS)',
            'coefficient': -0.00045902468279469,
        },
        {
            'predictor': 'Recipient total ESRD time (days) at transplant',
            'level': 'Apply to < 1500 (Left LS)',
            'coefficient': -0.000150586188253637,
        },
        {
            'predictor': 'Recipient total ESRD time (days) at transplant',
            'level': 'Missing',
            'coefficient': -0.455391623777801,
        },
        {
            'predictor': 'Recipient HLA mismatches (A)',
            'level': 0,
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient HLA mismatches (A)',
            'level': 1,
            'coefficient': 0.0249004250229174,
        },
        {
            'predictor': 'Recipient HLA mismatches (A)',
            'level': 2,
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient HLA mismatches (A)',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient HLA mismatches (B)',
            'level': 0,
            'coefficient': -0.056385191258381,
        },
        {
            'predictor': 'Recipient HLA mismatches (B)',
            'level': 1,
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient HLA mismatches (B)',
            'level': 2,
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient HLA mismatches (B)',
            'level': 'Missing',
            'coefficient': -0.056385191258381,
        },
        {
            'predictor': 'Recipient BMI',
            'level': 'Apply to < 18 (Left LS)',
            'coefficient': 0.112558815528366,
        },
        {
            'predictor': 'Recipient BMI',
            'level': 'Apply to > 24 (Right LS)',
            'coefficient': 0.0104405981450772,
        },
        {
            'predictor': 'Recipient BMI',
            'level': 'Apply to > 30 (Right LS)',
            'coefficient': 0.0041395776084091,
        },
        {
            'predictor': 'Recipient BMI',
            'level': 'Apply to > 34 (Right LS)',
            'coefficient': 0.00338860580012762,
        },
        {
            'predictor': 'Recipient BMI',
            'level': 'Apply to > 36 (Right LS)',
            'coefficient': 0.0491554309365765,
        },
        {
            'predictor': 'Recipient BMI',
            'level': 'Apply to > 38 (Right LS)',
            'coefficient': 0.000225103399568946,
        },
        {
            'predictor': 'Recipient BMI',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient HLA mismatches (DR)',
            'level': 0,
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient HLA mismatches (DR)',
            'level': 1,
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient HLA mismatches (DR)',
            'level': 2,
            'coefficient': 0.020954240289321,
        },
        {
            'predictor': 'Recipient HLA mismatches (DR)',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient HBV core antibody',
            'level': 'Not Positive',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient HBV core antibody',
            'level': 'Positive',
            'coefficient': -0.279852613839911,
        },
        {
            'predictor': 'Recipient HBV core antibody',
            'level': 'Missing',
            'coefficient': -0.279852613839911,
        },
        {
            'predictor': 'Recipient HCV status',
            'level': 'Not Positive',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient HCV status',
            'level': 'Positive',
            'coefficient': 0.243946854606777,
        },
        {
            'predictor': 'Recipient HCV status',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient HIV status',
            'level': 'Not Positive',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient HIV status',
            'level': 'Positive',
            'coefficient': 0.137192688389315,
        },
        {
            'predictor': 'Recipient HIV status',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient most recent CPRA',
            'level': 'Apply to < 10 (Left LS)',
            'coefficient': -0.00350089542428084,
        },
        {
            'predictor': 'Recipient most recent CPRA',
            'level': 'Apply to > 80 (Right LS)',
            'coefficient': 0.0119985087674443,
        },
        {
            'predictor': 'Recipient most recent CPRA',
            'level': 'Missing',
            'coefficient': -0.0350089542428084,
        },
        {
            'predictor': 'Recipient primary insurance',
            'level': 'Other',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient primary insurance',
            'level': 'Public',
            'coefficient': 0.0455841724993883,
        },
        {
            'predictor': 'Recipient primary insurance',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient transplant procedure type',
            'level': 'Left',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient transplant procedure type',
            'level': 'Right',
            'coefficient': 0.0226213089607381,
        },
        {
            'predictor': 'Recipient transplant procedure type',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Apply to < 35 (Left LS)',
            'coefficient': 0.0288478806464259,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Apply to < 40 (Left LS)',
            'coefficient': 0.00759975149304326,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Apply to < 45 (Left LS)',
            'coefficient': 0.000647596939256884,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Apply to > 55 (Right LS)',
            'coefficient': 0.0392618427151733,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Apply to > 60 (Right LS)',
            'coefficient': 0.00135798652701738,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Apply to > 70 (Right LS)',
            'coefficient': -0.0162023320312328,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Missing',
            'coefficient': 0,
        }
    ],
}
