/* eslint-disable */
export const kildadgs1y = {
    baselineHazard: 0.028183547433573,
    predictors: [
        {
            'predictor': 'Candidate blood type',
            'level': 'A',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate blood type',
            'level': 'AB',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate blood type',
            'level': 'B',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate blood type',
            'level': 'O',
            'coefficient': -0.0185065856514167,
        },
        {
            'predictor': 'Candidate blood type',
            'level': 'Missing',
            'coefficient': -0.0185065856514167,
        },
        {
            'predictor': 'Candidate diabetes status/type at onset',
            'level': 'None',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate diabetes status/type at onset',
            'level': 'Type I',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate diabetes status/type at onset',
            'level': 'Type II',
            'coefficient': 0.297162024970509,
        },
        {
            'predictor': 'Candidate diabetes status/type at onset',
            'level': 'Type Other/Unknown',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate diabetes status/type at onset',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate ethnicity',
            'level': 'Latino',
            'coefficient': -0.0881753735942237,
        },
        {
            'predictor': 'Candidate ethnicity',
            'level': 'Non-Latino or unknown',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate ethnicity',
            'level': 'Missing',
            'coefficient': -0.0881753735942237,
        },
        {
            'predictor': 'Candidate race',
            'level': 'Asian',
            'coefficient': -0.479369958212559,
        },
        {
            'predictor': 'Candidate race',
            'level': 'Black',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate race',
            'level': 'Multiracial',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate race',
            'level': 'Native American',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate race',
            'level': 'Pacific Islander',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate race',
            'level': 'White',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate race',
            'level': 'Missing',
            'coefficient': -0.479369958212559,
        },
        {
            'predictor': 'Candidate total albumin',
            'level': 'Apply to > 2.8 (Right LS)',
            'coefficient': -0.0737755778365468,
        },
        {
            'predictor': 'Candidate total albumin',
            'level': 'Apply to > 4 (Right LS)',
            'coefficient': -0.067118067327783,
        },
        {
            'predictor': 'Candidate total albumin',
            'level': 'Missing',
            'coefficient': -0.229424338568186,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'Congenital',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'DM',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'GN',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'HTN',
            'coefficient': -0.0323143652723661,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'Other',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'Missing',
            'coefficient': -0.0323143652723661,
        },
        {
            'predictor': 'EBV interaction (donor and recipient)',
            'level': 'Recipient EBV Not Positive Donor EBV No',
            'coefficient': 0,
        },
        {
            'predictor': 'EBV interaction (donor and recipient)',
            'level': 'Recipient EBV Not Positive Donor EBV Yes',
            'coefficient': 0.144531732884825,
        },
        {
            'predictor': 'EBV interaction (donor and recipient)',
            'level': 'Recipient EBV Positive Donor EBV No',
            'coefficient': 0,
        },
        {
            'predictor': 'EBV interaction (donor and recipient)',
            'level': 'Recipient EBV Positive Donor EBV Yes',
            'coefficient': -0.0076003551919839,
        },
        {
            'predictor': 'EBV interaction (donor and recipient)',
            'level': 'Missing',
            'coefficient': -0.0076003551919839,
        },
        {
            'predictor': 'Recipient total ESRD time (days) at transplant',
            'level': 'Apply to < 2000 (Left LS)',
            'coefficient': -0.000215914782601541,
        },
        {
            'predictor': 'Recipient total ESRD time (days) at transplant',
            'level': 'Apply to < 2500 (Left LS)',
            'coefficient': -0.0000552404236716069,
        },
        {
            'predictor': 'Recipient total ESRD time (days) at transplant',
            'level': 'Missing',
            'coefficient': -0.569930624382099,
        },
        {
            'predictor': 'Recipient BMI',
            'level': 'Apply to < 22 (Left LS)',
            'coefficient': -0.0792484810969099,
        },
        {
            'predictor': 'Recipient BMI',
            'level': 'Apply to < 24 (Left LS)',
            'coefficient': -0.00692915309413735,
        },
        {
            'predictor': 'Recipient BMI',
            'level': 'Apply to > 38 (Right LS)',
            'coefficient': 0.0980187808147269,
        },
        {
            'predictor': 'Recipient BMI',
            'level': 'Missing',
            'coefficient': -0.40077863347306,
        },
        {
            'predictor': 'Recipient primary insurance',
            'level': 'Other',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient primary insurance',
            'level': 'Public',
            'coefficient': 0.074495024211434,
        },
        {
            'predictor': 'Recipient primary insurance',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Apply to > 30 (Right LS)',
            'coefficient': 0.00337539181190938,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Missing',
            'coefficient': 0,
        }
    ],
}
