// @flow
import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

type Props = {
    highlighted: boolean,
    style: any,
    label: string,
    value: string,
    disabled: boolean,
    onChange: any,
    error: boolean,
    margin: string,
    variant: string,
    options: any[],
    fullWidth: boolean,
    classes: any,
};

const styles = () => ({
    cssLabel: {
        color: 'green',
    },

    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: 'green !important',
            borderWidth: '3px',
        },
    },

    cssFocused: {},

    notchedOutline: {
        borderWidth: '2px',
        borderColor: 'green !important',
    },

});


class HighlightedSelect extends PureComponent<Props> {
    // Workaround for passing the props onChange event through to the child
    //   this will call the parents onChange event when the handleChange function
    //   is called at this HighlightedSelect level.
    constructor(props) {
        super(props);
        this.handleChange = props.onChange.bind(this);
    }

    handleChange = {};

    render() {
        const { classes, highlighted, } = this.props;
        const inputLabelProps = highlighted ? {
            classes: {
                root: classes.cssLabel,
            },
        } : undefined;

        const inputProps = highlighted ? {
            classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
                error: classes.cssErrorLabel,
            },
        } : { classes: { error: classes.cssErrorLabel, }, };

        return (
            <TextField
                fullWidth={this.props.fullWidth}
                select
                disabled={this.props.disabled}
                error={this.props.error}
                style={this.props.style}
                label={this.props.label}
                value={this.props.value}
                onChange={this.handleChange}
                margin={this.props.margin}
                variant={this.props.variant}
                SelectProps={{
                    native: true,
                }}
                InputLabelProps={inputLabelProps}
                InputProps={inputProps}
            >
                {this.props.options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.text}
                    </option>
                ))}
            </TextField>
        );
    }
}

export default withStyles(styles)(HighlightedSelect);
