// @flow

import React, { PureComponent } from 'react';
import { Col, Row } from 'react-simple-flex-grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import UNOSHeader from './UNOSHeader';
import type { DonorOrganData } from '../Types/DonorOrgan';
import type { RecipientData } from '../Types/Recipient';
import HighlightedSelect from './HighlightedSelect';
import HighlightedTextField from './HighlightedTextField';

import AskAlanStyles from '../Styles/AskAlanStyles';

type Props = {
    userOrgId: number,
    donorOrganData: DonorOrganData,
    recipientData: RecipientData,
    saveRecipientStatus: string,
    recipientTemplatePreferences: any,

    onSaveRecipientData: (recipientData: RecipientData, saveToServer: boolean) => *,
    onSetSaveRecipientStatus: (status: string, recipientId: number)=> *,

};

type State = {
    errors: {
        name: string,
        waitlistNumber: string,
        age: string,
        cPRA: string,
        mismatchA: string,
        mismatchB: string,
        mismatchDR: string,
    },
    formData: {
        waitlistNumber: number,
        priority: string,
        name: string,
        sex: string,
        age: number,
        cPRA: number,
        mismatchA: number,
        mismatchB: number,
        mismatchDR: number,
    },
};

class Recipient extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            errors: {
                name: '',
                waitlistNumber: '',
                age: '',
                cPRA: '',
                mismatchA: '',
                mismatchB: '',
                mismatchDR: '',
            },
            formData: {
                priority: this.props.recipientData.waitlist.priority || 'Please Select',
                waitlistNumber: this.props.recipientData.waitlist.waitlist_number || 0,

                name: this.props.recipientData.demographics.full_name || '',
                sex: this.props.recipientData.demographics.sex || 'Please Select',
                age: this.props.recipientData.demographics.age || 0,

                cPRA: this.props.recipientData.labs.cpra || 0,
                mismatchA: this.props.recipientData.labs.mismatch_a || 0,
                mismatchB: this.props.recipientData.labs.mismatch_b || 0,
                mismatchDR: this.props.recipientData.labs.mismatch_dr || 0,
            },
        };
    }

    componentWillUnmount() {
        // If this is 'my data' then save. If not (e.g. TC viewing data entered by OPO then don't save)
        if (this.props.userOrgId === this.props.donorOrganData.organization_id) {
            const recipient = this.getRecipientData();
            this.props.onSaveRecipientData(recipient, false);
            this.props.onSetSaveRecipientStatus('', recipient.recipient_id);
        }
    }

    setError = (fieldName: string, errorMessage: string) => {
        this.setState((prevState) => {
            const errors = { ...prevState.errors, };
            errors[fieldName] = errorMessage;
            return { errors, };
        });
        return 1;
    };

    getRecipientData = (): RecipientData => {
        const form = this.state.formData;

        const recipientData = {
            recipient_id: this.props.recipientData.recipient_id,

            waitlist: {
                priority: form.priority === 'Please Select' ? null : form.priority,
                waitlist_number: Number(form.waitlistNumber),
            },
            demographics: {
                full_name: form.name,
                sex: form.sex === 'Please Select' ? null : form.sex,
                age: Number(form.age),
            },
            labs: {
                cpra: Number(form.cPRA),
                mismatch_a: Number(form.mismatchA),
                mismatch_b: Number(form.mismatchB),
                mismatch_dr: Number(form.mismatchDR),
            },
        };
        return recipientData;
    }

    clearErrors = () => {
        this.setState((prevState) => {
            const errors = { ...prevState.errors, };
            errors.name = '';
            errors.waitlistNumber = '';
            errors.age = '';
            errors.cPRA = '';
            errors.mismatchA = '';
            errors.mismatchB = '';
            errors.mismatchDR = '';
            return { errors, };
        });
    };

    handleChange = (prop: any) => (event: any) => {
        const newValue = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
        this.setState((prevState) => {
            const formData = { ...prevState.formData, };
            formData[prop] = newValue;
            return { formData, };
        });
    };

    saveRecord = () => {
        this.clearErrors();
        const recipient = this.getRecipientData();
        // Save locally
        this.props.onSaveRecipientData(recipient, false);
        // validate

        // Save to server
        this.props.onSaveRecipientData(recipient, true);
    };

    render() {
        const {
            userOrgId,
            donorOrganData,
            saveRecipientStatus,
            recipientTemplatePreferences,
        } = this.props;

        const templateGuidance = recipientTemplatePreferences || {};

        const {
            name,
            waitlistNumber,
            sex,
            age,
            priority,
            cPRA,
            mismatchA,
            mismatchB,
            mismatchDR,
        } = this.state.formData;

        const editableData = ((donorOrganData.organization_id === 0) || (userOrgId === donorOrganData.organization_id));

        return (
            <div>
                <UNOSHeader donorOrganData={donorOrganData} editable={false} />
                <span className="groupLabel">Demographic</span>
                <Row>
                    <Col span="12">
                        <HighlightedTextField
                            highlighted={templateGuidance.FULL_NAME}
                            style={AskAlanStyles.textField}
                            margin="dense"
                            disabled={!editableData}
                            variant="outlined"
                            label="Name"
                            value={name}
                            onChange={this.handleChange('name')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span="6">
                        <HighlightedSelect
                            highlighted={templateGuidance.PRIORITY}
                            value={priority}
                            variant="outlined"
                            label="Priority"
                            onChange={this.handleChange('priority')}
                            disabled={!editableData}
                            style={AskAlanStyles.textField}
                            margin="dense"
                            options={[
                                { value: 'Please Select', text: 'Please Select', },
                                { value: 'Primary', text: 'Primary', },
                                { value: 'Secondary', text: 'Secondary', },
                                { value: 'Tertiary', text: 'Tertiary', },
                                { value: 'Backup', text: 'Backup', }
                            ]}
                        />
                    </Col>
                    <Col span="6">
                        <HighlightedTextField
                            highlighted={templateGuidance.WAITLIST_NUMBER}
                            style={AskAlanStyles.textField}
                            margin="dense"
                            disabled={!editableData}
                            error={this.state.errors.waitlistNumber.length !== 0}
                            helperText={this.state.errors.waitlistNumber}
                            variant="outlined"
                            label="Waitlist Number"
                            type="number"
                            value={waitlistNumber}
                            onChange={this.handleChange('waitlistNumber')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span="6">
                        <HighlightedSelect
                            highlighted={templateGuidance.SEX}
                            value={sex}
                            variant="outlined"
                            label="Sex"
                            onChange={this.handleChange('sex')}
                            disabled={!editableData}
                            style={AskAlanStyles.textField}
                            margin="dense"
                            options={[
                                { value: 'Please Select', text: 'Please Select', },
                                { value: 'Male', text: 'Male', },
                                { value: 'Female', text: 'Female', }
                            ]}
                        />
                    </Col>
                    <Col span="6">
                        <HighlightedTextField
                            highlighted={templateGuidance.AGE}
                            style={AskAlanStyles.textField}
                            disabled={!editableData}
                            margin="dense"
                            error={this.state.errors.age.length !== 0}
                            helperText={this.state.errors.age}
                            variant="outlined"
                            label="Age"
                            type="number"
                            value={age}
                            onChange={this.handleChange('age')}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">years</InputAdornment>,
                            }}
                        />
                    </Col>
                </Row>
                <span className="groupLabel">Clinical</span>
                <Row>
                    <Col span="12">
                        <HighlightedTextField
                            highlighted={templateGuidance.CPRA}
                            style={AskAlanStyles.textField}
                            disabled={!editableData}
                            margin="dense"
                            error={this.state.errors.cPRA.length !== 0}
                            helperText={this.state.errors.cPRA}
                            variant="outlined"
                            type="number"
                            label="cPRA"
                            value={cPRA}
                            onChange={this.handleChange('cPRA')}
                        />
                    </Col>
                </Row>
                <span className="groupLabelCenter">Mismatches</span>
                <Row>
                    <Col span="4">
                        <HighlightedTextField
                            highlighted={templateGuidance.MISMATCH_A}
                            style={AskAlanStyles.textField}
                            disabled={!editableData}
                            margin="dense"
                            error={this.state.errors.mismatchA.length !== 0}
                            helperText={this.state.errors.mismatchA}
                            variant="outlined"
                            label="A"
                            type="number"
                            value={mismatchA}
                            onChange={this.handleChange('mismatchA')}
                        />
                    </Col>
                    <Col span="4">
                        <HighlightedTextField
                            highlighted={templateGuidance.MISMATCH_B}
                            style={AskAlanStyles.textField}
                            disabled={!editableData}
                            margin="dense"
                            error={this.state.errors.mismatchB.length !== 0}
                            helperText={this.state.errors.mismatchB}
                            variant="outlined"
                            label="B"
                            type="number"
                            value={mismatchB}
                            onChange={this.handleChange('mismatchB')}
                        />
                    </Col>
                    <Col span="4">
                        <HighlightedTextField
                            highlighted={templateGuidance.MISMATCH_DR}
                            style={AskAlanStyles.textField}
                            disabled={!editableData}
                            margin="dense"
                            error={this.state.errors.mismatchDR.length !== 0}
                            helperText={this.state.errors.mismatchDR}
                            variant="outlined"
                            label="DR"
                            type="number"
                            value={mismatchDR}
                            onChange={this.handleChange('mismatchDR')}
                        />
                    </Col>
                </Row>
                {(editableData)
                    ? (
                        <div style={AskAlanStyles.actionsContainer}>
                            <Row>
                                <Col span={6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.saveRecord}
                                        style={AskAlanStyles.button}
                                    >
                                        Send to Chatroom
                                    </Button>
                                </Col>
                                <Col>
                                    {((saveRecipientStatus === 'FAILED'))
                                        ? (
                                            <div style={AskAlanStyles.error}>Unable to save</div>
                                        ) : <div style={AskAlanStyles.info}>{saveRecipientStatus}</div>}
                                </Col>
                            </Row>
                        </div>
                    ) : <div />}
            </div>
        );
    }
}

export default Recipient;
