/* eslint-disable import/no-self-import */
// @flow
import allSagas from '../Saga';
import SagaManager from '../Saga/SagaManager';

import configureStore from './configureStore';
import activationReducer from './ActivationActions';
import clinicalReducer from './ClinicalActions';


export const reducers = {
    activation: activationReducer,
    clinical: clinicalReducer,
};

const assembleStore = () => {
    const res = configureStore(reducers);
    const {
        store,
        persistor,
        sagaMiddleware,
        replaceReducer,
    } = res;

    // Kick off the root Saga
    SagaManager.run(sagaMiddleware, allSagas);

    if (module.hot) {
        module.hot.accept(() => {
            const nextRootReducer = require('./').reducers; // eslint-disable-line global-require
            replaceReducer(nextRootReducer);

            const newYieldedSagas = require('../Saga').default; // eslint-disable-line global-require

            SagaManager.cancel(store);
            const newManager = require('../Saga/SagaManager').default; // eslint-disable-line global-require
            newManager.run(sagaMiddleware, newYieldedSagas, true);
        });
    }

    return {
        store,
        persistor,
    };
};

export default assembleStore;
