// @flow

import type { DonorOrganData } from './DonorOrgan';
import { defaultDonorOrganData } from './DonorOrgan';

import type { RecipientData } from './Recipient';
import { defaultRecipientData } from './Recipient';

import type { CandidateData } from './Candidate';
import { defaultCandidateData } from './Candidate';

export type OfferData = {
    transplant_center: string,
    donor: DonorOrganData,
    recipients: [RecipientData],
    candidates: [CandidateData],
};

const defaultOfferData: OfferData = {
    transplant_center: '', donor: defaultDonorOrganData, recipients: [defaultRecipientData], candidates: [defaultCandidateData],
};

export default defaultOfferData;
