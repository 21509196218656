// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// pick utils
import MomentUtils from '@date-io/moment';

import ThemeProvider from 'react-toolbox/lib/ThemeProvider';
import queryString from 'query-string';
import { Base64 } from 'js-base64';

import * as Sentry from '@sentry/browser';

import App from './App';
// import unregisterServiceWorker from './registerServiceWorker';
import theme from './Toolbox/theme';

import './Toolbox/theme.css';
import { appConfiguration } from './AppConfiguration';
import type { TxpData } from './Types/TxpData';
import txpDefaultData from './Types/TxpData';
import './index.css';

const { version, } = appConfiguration();
Sentry.init({
    dsn: 'https://3501419a5e964e959259540b7ced78c6@sentry.io/1324694',
    environment: process.env.NODE_ENV,
    release: version,
});

const loadTxpData = function loadTypData() {
    const values = queryString.parse(window.location.search);
    if (values.txpData) {
        const txpString = Base64.decode(values.txpData);
        // marshall the untyped data - defensive code to handle older clients
        const txpRaw = JSON.parse(txpString);
        const txpData: TxpData = JSON.parse(txpString);
        // allow for version differences
        if (txpData.userId === undefined && txpRaw.user !== undefined) txpData.userId = txpRaw.user;
        if (txpData.orgId === undefined && txpRaw.orgaisation_id !== undefined) txpData.orgId = txpRaw.orgaisation_id;
        Sentry.captureMessage(txpString);
        return txpData;
    }
    // Defensive coding for missing data
    return txpDefaultData;
};

const txpData = loadTxpData();

const root = document.getElementById('root');

if (root !== null) {
    ReactDOM.render(
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <ThemeProvider theme={theme}>
                <App txpData={txpData} />
            </ThemeProvider>
        </MuiPickersUtilsProvider>,
        root
    );
}

// unregisterServiceWorker();
