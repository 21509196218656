// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import AskAlanStyles from '../Styles/AskAlanStyles';
import ApplicationStyles from '../Themes/ApplicationStyles';
import images from '../Themes/Images';
import type {
    StoreActivationParameters,
    VerifyApplicationActivationCode,
} from '../Redux/ActivationActions';
import {
    verifyApplicationActivationCode,
    storeActivationParameters,
} from '../Redux/ActivationActions';
import type { TxpData } from '../Types/TxpData';

type Props = {
    txpData: TxpData,

    activationStatus: string,
    recordStatus: string,
    donor: any,

    onStoreActivationParameters: (txpData: TxpData) => StoreActivationParameters,
    onVerifyApplicationActivationCode: (txpData: TxpData) => VerifyApplicationActivationCode,

};

class ActivationPage extends Component<Props> {
    componentDidMount() {
        const {
            txpData,
        } = this.props;

        // Store the data in case of refresh (edge case) - Consider removing
        this.props.onStoreActivationParameters(txpData);

        // Validate the data against the server
        this.props.onVerifyApplicationActivationCode(txpData);
    }

    render() {
        const {
            activationStatus,
            recordStatus,
            donor,
        } = this.props;

        if (activationStatus === 'AUTH' && (recordStatus === 'LOADED' || recordStatus === 'NEW')) {
            return (
                <Redirect to={{
                    pathname: '/offer',
                    donor,
                }}
                />
            );
        } if (activationStatus === 'UNAUTH') {
            return (
                <Redirect to="/noauth" />
            );
        } if (recordStatus === 'FAILED') {
            return (
                <Redirect to="/noauth" />
            );
        }
        return (
            <div style={ApplicationStyles.verticalCenter}>
                <h4 style={AskAlanStyles.formLabel}>Loading...</h4>
                <img id="loading" style={AskAlanStyles.loading} src={images.logo} alt="" />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activationStatus: state.activation.status,
    recordStatus: state.clinical.getStatus,
    donor: state.clinical.donor,
});

export default connect(mapStateToProps, {
    onStoreActivationParameters: storeActivationParameters,
    onVerifyApplicationActivationCode: verifyApplicationActivationCode,
})(ActivationPage);
