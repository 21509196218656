
// @flow

import React, { PureComponent } from 'react';
import { Col, Row } from 'react-simple-flex-grid';
import TextField from '@material-ui/core/TextField';
import type { CandidateData } from '../Types/Candidate';

import AskAlanStyles from '../Styles/AskAlanStyles';

type Props = {
    candidateData: CandidateData,
};

class DemogHeader extends PureComponent<Props> {
    render() {
        const {
            candidateData,
        } = this.props;

        return (
            <div>
                <span className="groupLabel">Candidate Demographic</span>
                <Row>
                    <Col span="4">
                        <TextField
                            style={AskAlanStyles.textField}
                            margin="dense"
                            variant="outlined"
                            label="Name"
                            value={candidateData.demographics.full_name}
                            InputProps={{ readOnly: true, }}
                        />
                    </Col>
                    <Col span="4">
                        <TextField
                            style={AskAlanStyles.textField}
                            margin="dense"
                            variant="outlined"
                            label="Gender"
                            value={candidateData.demographics.sex}
                            InputProps={{ readOnly: true, }}
                        />
                    </Col>
                    <Col span="4">
                        <TextField
                            style={AskAlanStyles.textField}
                            margin="dense"
                            variant="outlined"
                            label="Age"
                            value={`${candidateData.demographics.age} Years`}
                            InputProps={{ readOnly: true, }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default DemogHeader;
