// @flow

import Fonts from './Fonts';
import Colors from './Colors';


export const textInputHeight = 50;

// This file is for a reusable grouping of Theme items.
// Similar to an XML fragment layout in Android
const ApplicationStyles = {
    window: {
        mainContainer: {
            flex: 1,
            backgroundColor: Colors.transparent,
        },
        mainCard: {
            backgroundColor: Colors.mainBackground,
        },
        backgroundImage: {
            bottom: 0,
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
        },
        backgroundImageFull: {
            bottom: 0,
            height: '100%',
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
            width: '100%',
        },
        container: {
            flex: 1,
            backgroundColor: Colors.transparent,
        },
        header: {},
        blankText: {
            color: Colors.text,
            fontSize: 14,
            fontWeight: '600',
        },
        rootContainer: {
            flex: 1,
            backgroundColor: Colors.mainBackground,
        },
        mainView: {
            flex: 1,
            backgroundColor: Colors.mainBackground,
        },

        overlay: {
            alignItems: 'center',
            backgroundColor: Colors.white,
            bottom: 0,
            justifyContent: 'center',
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
        },
    },
    horizontalCenter: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    verticalCenter: {
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
    },
    textInput: {
        ...Fonts.style.input,
    },
    textInputError: {
        color: Colors.formError,
    },
    textInputDisabled: {
        color: Colors.textInputTextDisabled,
    },
    fakeTextInput: {
        ...Fonts.style.input,
        height: textInputHeight,
        justifyContent: 'center',
        textAlign: 'left',
        color: Colors.textInputText,
        borderBottomWidth: 1,
        borderColor: Colors.fakeTextInputBorderBottom,
    },
    button: {
        backgroundColor: Colors.buttonBackground,
        color: Colors.white,
        borderWidth: 1,
        alignItems: 'center',
        textTransform: 'none',
    },
    buttonInverse: {
        backgroundColor: Colors.white,
        color: Colors.buttonBackground,
        borderWidth: 1,
        alignItems: 'center',
        textTransform: 'none',
    },
    buttonLabel: {
        color: Colors.buttonText,
        backgroundColor: Colors.transparent,
    },
    buttonDisabled: {
        backgroundColor: Colors.buttonBackgroundDisabled,
        color: Colors.buttonTextDisabled,
        borderWidth: 1,
        alignItems: 'center',
        textTransform: 'none',
    },
    titleText: {
        color: Colors.black,
        fontSize: 24,
        lineHeight: 29,
        marginBottom: 12,
        textAlign: 'center',
    },
    textMuted: {
        color: Colors.gray,
        textAlign: 'center',
    },
    textCenter: {
        textAlign: 'center',
    },

    link: {
        color: Colors.linkText,
        textAlign: 'center',
    },
    linkBold: {
        color: Colors.linkText,
        fontWeight: '600',
    },
    linkDisabled: {
        color: Colors.linkTextDisabled,
    },

    debug: {
        borderColor: 'red',
        borderWidth: 1,
    },
    debug1: {
        borderColor: 'blue',
        borderWidth: 1,
    },
    debug2: {
        borderColor: 'green',
        borderWidth: 1,
    },

    flex1: {
        flex: 1,
    },
    flex2: {
        flex: 2,
    },

    flexColumn: {
        flexDirection: 'column',
    },
    flexRow: {
        flexDirection: 'row',
    },

    selfFlexStart: {
        alignSelf: 'flex-start',
    },
    selfFlexCenter: {
        alignSelf: 'center',
    },
    selfFlexEnd: {
        alignSelf: 'flex-end',
    },

    dot: {
        alignSelf: 'center',
        backgroundColor: Colors.lightGray,
        borderRadius: 2,
        height: 4,
        marginHorizontal: 4,
        transform: [
            {
                translateY: 1,
            }
        ],
        width: 4,
        display: 'inline-block',
    },

    loadingOverlay: {
        backgroundColor: Colors.transparentWhite,
        bottom: 0,
        left: 0,
        padding: 50,
        position: 'absolute',
        right: 0,
        top: 0,
    },
    Loginh1: {
        ...Fonts.style.h1,
        color: Colors.black,
        textAlign: 'center',
    },
};

export default ApplicationStyles;
