import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';

import Routes from './Navigation/Routes';

import assembleStore from './Redux';

import './App.css';

// create our store
const {
    store,
    persistor,
} = assembleStore();


class App extends PureComponent<Props> {
    render() {
        const {
            txpData,
        } = this.props;

        return (
            <div>
                <Provider store={store}>
                    <PersistGate persistor={persistor}>
                        <Router>
                            <Routes txpData={txpData} />
                        </Router>
                    </PersistGate>
                </Provider>
            </div>
        );
    }
}

export default App;
