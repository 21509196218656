// @flow

import Colors from '../Themes/Colors';

const AskAlanStyles = {
    loading: {
        marginTop: 20,
        width: 60,
        height: 60,
    },
    root: {
        marginTop: 0,
        padding: 10,
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
    },
    formLabel: {
        color: Colors.formLabel,
        fontSize: 17,
        lineHeight: 2,
    },
    margin: {
        margin: 0,
        // margin: 10 * 0.5,
    },
    info: {
        alignSelf: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: 18,
        bold: true,
        color: Colors.blue,
    },
    header: {
        alignSelf: 'center',
        fontSize: 18,
        bold: true,
        color: Colors.black,
    },
    askAlan: {
        color: Colors.black,
        marginLeft: 25,
    },
    error: {
        bold: true,
        fontSize: 18,
        color: Colors.red,
    },
    textField: {
        marginTop: 5,
    },
    button: {
        marginTop: 10,
        marginRight: 10,
        textTransform: 'none',
    },
    actionsContainer: {
        marginBottom: 10 * 2,
    },
    resetContainer: {
        padding: 10 * 3,
    },
    subheader: {
        fontSize: 14,
        color: Colors.black,
    },
    tableExample: {
        fontSize: 12,
        color: Colors.black,
    },
    tableHeader: {
        bold: true,
        fontSize: 12,
        color: Colors.black,
    },
    footer: {
        fontSize: 10,
        color: Colors.black,
    },
    notchedOutline: {
        borderWidth: '3px',
        borderColor: 'green !important',
    },
};

export default AskAlanStyles;
