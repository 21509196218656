// @flow

import type { CandidateData } from '../Types/Candidate';
import type { RecipientData } from '../Types/Recipient';
import type { CandidateRecipientData } from '../Types/CandidateRecipient';

const combineAllCandidateData = (recipientData: RecipientData, candidateData: CandidateData): CandidateRecipientData => {
    const combinedData: CandidateRecipientData = {};
    combinedData.recipient_id = recipientData.recipient_id;
    combinedData.candidate_id = candidateData.candidate_id;

    // Copy the candidate and recipient data into the combinedData object.
    combinedData.waitlist = { ...recipientData.waitlist, };
    combinedData.demographics = { ...recipientData.demographics, ...candidateData.demographics, };
    combinedData.history = { ...candidateData.history, };
    // $FlowFixMe
    combinedData.labs = { ...recipientData.labs, ...candidateData.labs, };

    // Now cater for fields in both sets - candidateData should be prioritized
    combinedData.demographics.sex = (candidateData.demographics.sex && candidateData.demographics.sex.length > 0)
        ? candidateData.demographics.sex : recipientData.demographics.sex;
    combinedData.demographics.age = (candidateData.demographics.age > 0) ? candidateData.demographics.age : recipientData.demographics.age;
    combinedData.labs.cpra = (candidateData.labs.cpra > 0) ? candidateData.labs.cpra : recipientData.labs.cpra;

    return combinedData;
};

export default combineAllCandidateData;
