// @flow

export type RecipientDataWaitlist = {
    waitlist_number: number,
    priority: ?string,
}

export type RecipientDataDemographics = {
    full_name: string,
    sex: ?string,
    age: number,
}
export type RecipientDataLabs = {
    cpra: number,
    mismatch_a: number,
    mismatch_b: number,
    mismatch_dr: number,
}

export type RecipientData = {
    recipient_id: number,
    waitlist: RecipientDataWaitlist,
    demographics: RecipientDataDemographics,
    labs: RecipientDataLabs,
};
export const defaultRecipientDataWaitlist: RecipientDataWaitlist = { waitlist_number: 0, priority: '', };
export const defaultRecipientDataDemographics: RecipientDataDemographics = { full_name: '', sex: '', age: 0, };
export const defaultRecipientDataLabs: RecipientDataLabs = {
    cpra: 0, mismatch_a: 0, mismatch_b: 0, mismatch_dr: 0,
};
export const defaultRecipientData: RecipientData = {
    recipient_id: 0, waitlist: defaultRecipientDataWaitlist, demographics: defaultRecipientDataDemographics, labs: defaultRecipientDataLabs,
};
