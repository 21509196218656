/* eslint-disable */
export const kiadddtx = {
    baselineHazard: 0.000586,
    predictors: [
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to > 50 (Right LS)',
            'Coefficient': 0.00295,
        },
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to > 55 (Right LS)',
            'Coefficient': 0.000811,
        },
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to > 65 (Right LS)',
            'Coefficient': -0.002771,
        },
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to > 70 (Right LS)',
            'Coefficient': 0.028495,
        },
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to > 75 (Right LS)',
            'Coefficient': -0.743076,
        },
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to < 25 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to < 35 (Left LS)',
            'Coefficient': -0.010261,
        },
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to < 45 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to < 50 (Left LS)',
            'Coefficient': 0.011786,
        },
        {
            'Element': 'Candidate blood type',
            'Level': 'A',
            'Coefficient': 0.443161,
        },
        {
            'Element': 'Candidate blood type',
            'Level': 'AB',
            'Coefficient': 0.900171,
        },
        {
            'Element': 'Candidate blood type',
            'Level': 'B',
            'Coefficient': -0.009857,
        },
        {
            'Element': 'Candidate BMI',
            'Level': 'Apply to > 30 (Right LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate BMI',
            'Level': 'Apply to > 32 (Right LS)',
            'Coefficient': -0.020567,
        },
        {
            'Element': 'Candidate BMI',
            'Level': 'Apply to > 38 (Right LS)',
            'Coefficient': -0.041249,
        },
        {
            'Element': 'Candidate BMI',
            'Level': 'Apply to > 40 (Right LS)',
            'Coefficient': 0.010262,
        },
        {
            'Element': 'Candidate BMI',
            'Level': 'Apply to < 20 (Left LS)',
            'Coefficient': -0.029851,
        },
        {
            'Element': 'Candidate BMI',
            'Level': 'Apply to < 22 (Left LS)',
            'Coefficient': -0.025484,
        },
        {
            'Element': 'Candidate BMI',
            'Level': 'Apply to < 24 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate BMI',
            'Level': 'Apply to < 26 (Left LS)',
            'Coefficient': 0.008289,
        },
        {
            'Element': 'Candidate diabetes type',
            'Level': 'None',
            'Coefficient': 0.216933,
        },
        {
            'Element': 'Candidate diabetes type',
            'Level': 'Type 1',
            'Coefficient': -0.143755,
        },
        {
            'Element': 'Candidate diabetes type',
            'Level': 'Unknown',
            'Coefficient': -0.181693,
        },
        {
            'Element': 'Candidate education',
            'Level': 'Grade School/None',
            'Coefficient': 0.067366,
        },
        {
            'Element': 'Candidate education',
            'Level': 'High School',
            'Coefficient': 0.053835,
        },
        {
            'Element': 'Candidate education',
            'Level': 'Post High School Degree',
            'Coefficient': -0.009496,
        },
        {
            'Element': 'Candidate ethnicity',
            'Level': 'Latino',
            'Coefficient': -0.170656,
        },
        {
            'Element': 'Candidate sex',
            'Level': 'Female',
            'Coefficient': 0.011121,
        },
        {
            'Element': 'Candidate height',
            'Level': 'Apply to > 165 (Right LS)',
            'Coefficient': 0.000452,
        },
        {
            'Element': 'Candidate height',
            'Level': 'Apply to > 170 (Right LS)',
            'Coefficient': 0.00014,
        },
        {
            'Element': 'Candidate height',
            'Level': 'Apply to > 180 (Right LS)',
            'Coefficient': -0.00207,
        },
        {
            'Element': 'Candidate height',
            'Level': 'Apply to > 190 (Right LS)',
            'Coefficient': -0.033774,
        },
        {
            'Element': 'Candidate height',
            'Level': 'Apply to < 150 (Left LS)',
            'Coefficient': -0.004698,
        },
        {
            'Element': 'Candidate height',
            'Level': 'Apply to < 155 (Left LS)',
            'Coefficient': 0.001703,
        },
        {
            'Element': 'Candidate height',
            'Level': 'Apply to < 160 (Left LS)',
            'Coefficient': 0.003301,
        },
        {
            'Element': 'Candidate height',
            'Level': 'Apply to < 180 (Left LS)',
            'Coefficient': -0.000001,
        },
        {
            'Element': 'Candidate previous malignancy',
            'Level': 'Yes',
            'Coefficient': 0.076317,
        },
        {
            'Element': 'Candidate PVD',
            'Level': 'Yes',
            'Coefficient': 0.329413,
        },
        {
            'Element': 'Candidate primary insurance',
            'Level': 'Medicaid',
            'Coefficient': -0.053798,
        },
        {
            'Element': 'Candidate primary insurance',
            'Level': 'Medicare',
            'Coefficient': 0.044727,
        },
        {
            'Element': 'Candidate primary insurance',
            'Level': 'Other',
            'Coefficient': -0.384657,
        },
        {
            'Element': 'Candidate race',
            'Level': 'Asian',
            'Coefficient': -0.086899,
        },
        {
            'Element': 'Candidate race',
            'Level': 'Other',
            'Coefficient': 0.155374,
        },
        {
            'Element': 'Candidate race',
            'Level': 'White',
            'Coefficient': 0.130459,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to > 3.6 (Right LS)',
            'Coefficient': 0.08085,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to > 3.8 (Right LS)',
            'Coefficient': 0.005726,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to > 4.2 (Right LS)',
            'Coefficient': 0.003161,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to > 4.6 (Right LS)',
            'Coefficient': -0.002388,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to > 4.8 (Right LS)',
            'Coefficient': -0.105164,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to > 4 (Right LS)',
            'Coefficient': 0.066202,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to < 3.2 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to < 3.6 (Left LS)',
            'Coefficient': 0.088714,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to < 3 (Left LS)',
            'Coefficient': 0.254191,
        },
        {
            'Element': 'Candidate weight',
            'Level': 'Apply to > 110 (Right LS)',
            'Coefficient': -0.000113,
        },
        {
            'Element': 'Candidate weight',
            'Level': 'Apply to > 120 (Right LS)',
            'Coefficient': 0.008306,
        },
        {
            'Element': 'Candidate weight',
            'Level': 'Apply to > 90 (Right LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate weight',
            'Level': 'Apply to < 110 (Left LS)',
            'Coefficient': 0.000357,
        },
        {
            'Element': 'Candidate weight',
            'Level': 'Apply to < 50 (Left LS)',
            'Coefficient': -0.000627,
        },
        {
            'Element': 'Candidate weight',
            'Level': 'Apply to < 60 (Left LS)',
            'Coefficient': 0.004185,
        },
        {
            'Element': 'Candidate weight',
            'Level': 'Apply to < 70 (Left LS)',
            'Coefficient': -0.002071,
        },
        {
            'Element': 'Candidate working for income',
            'Level': 'Yes',
            'Coefficient': -0.000668,
        },
        {
            'Element': 'Candidate cPRA within 1 month of listing',
            'Level': 'Apply to > 30 (Right LS)',
            'Coefficient': 0.000463,
        },
        {
            'Element': 'Candidate cPRA within 1 month of listing',
            'Level': 'Apply to > 40 (Right LS)',
            'Coefficient': 0.000356,
        },
        {
            'Element': 'Candidate cPRA within 1 month of listing',
            'Level': 'Apply to > 80 (Right LS)',
            'Coefficient': 0.019494,
        },
        {
            'Element': 'Candidate cPRA within 1 month of listing',
            'Level': 'Apply to > 90 (Right LS)',
            'Coefficient': -0.048369,
        },
        {
            'Element': 'Candidate cPRA within 1 month of listing',
            'Level': 'Apply to < 10 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate cPRA within 1 month of listing',
            'Level': 'Apply to < 50 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate cPRA within 1 month of listing',
            'Level': 'Apply to < 60 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate cPRA within 1 month of listing',
            'Level': 'Apply to < 90 (Left LS)',
            'Coefficient': -0.001135,
        },
        {
            'Element': 'Candidate primary diagnosis',
            'Level': 'Congenital',
            'Coefficient': 0.004104,
        },
        {
            'Element': 'Candidate primary diagnosis',
            'Level': 'Diabetes',
            'Coefficient': -0.146327,
        },
        {
            'Element': 'Candidate primary diagnosis',
            'Level': 'Glomerulonephritis',
            'Coefficient': 0.005122,
        },
        {
            'Element': 'Candidate primary diagnosis',
            'Level': 'Hypertension',
            'Coefficient': -0.085737,
        },
        {
            'Element': 'Candidate primary diagnosis',
            'Level': 'Other',
            'Coefficient': 0.012441,
        },
        {
            'Element': 'Years since initial development of ESRD',
            'Level': 'Apply to > 10 (Right LS)',
            'Coefficient': -0.029,
        },
        {
            'Element': 'Years since initial development of ESRD',
            'Level': 'Apply to > 16 (Right LS)',
            'Coefficient': -0.006971,
        },
        {
            'Element': 'Years since initial development of ESRD',
            'Level': 'Apply to > 8 (Right LS)',
            'Coefficient': -0.001607,
        },
        {
            'Element': 'Years since initial development of ESRD',
            'Level': 'Apply to < 2 (Left LS)',
            'Coefficient': 0.183966,
        },
        {
            'Element': 'Years since initial development of ESRD',
            'Level': 'Apply to < 4 (Left LS)',
            'Coefficient': -0.138283,
        },
        {
            'Element': 'Years since initial development of ESRD',
            'Level': 'Apply to < 6 (Left LS)',
            'Coefficient': -0.128587,
        },
        {
            'Element': 'Years since initial development of ESRD',
            'Level': 'Apply to < 8 (Left LS)',
            'Coefficient': -0.074054,
        },
        {
            'Element': 'Prior heart transplant',
            'Level': 'Yes',
            'Coefficient': -0.153112,
        },
        {
            'Element': 'On heart waiting list',
            'Level': 'Yes',
            'Coefficient': 1.915062,
        },
        {
            'Element': 'On SPK/pancreas waiting list',
            'Level': 'Yes',
            'Coefficient': 0.002732,
        },
        {
            'Element': 'Prior SPK transplant',
            'Level': 'Yes',
            'Coefficient': -0.157633,
        },
        {
            'Element': 'Prior liver transplant',
            'Level': 'Yes',
            'Coefficient': 0.287928,
        },
        {
            'Element': 'On liver waiting list',
            'Level': 'Yes',
            'Coefficient': 1.453716,
        },
        {
            'Element': 'Days on the waiting list at beginning of the cohort (natural-log)',
            'Level': 'Apply to > 6 (Right LS)',
            'Coefficient': 0.463562,
        },
        {
            'Element': 'Days on the waiting list at beginning of the cohort (natural-log)',
            'Level': 'Apply to > 7 (Right LS)',
            'Coefficient': -0.271398,
        },
        {
            'Element': 'Days on the waiting list at beginning of the cohort (natural-log)',
            'Level': 'Apply to > 8 (Right LS)',
            'Coefficient': -6.075301,
        },
        {
            'Element': 'Days on the waiting list at beginning of the cohort (natural-log)',
            'Level': 'Apply to < 1 (Left LS)',
            'Coefficient': 0.005966,
        },
        {
            'Element': 'Days on the waiting list at beginning of the cohort (natural-log)',
            'Level': 'Apply to < 4 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Days on the waiting list at beginning of the cohort (natural-log)',
            'Level': 'Apply to < 6 (Left LS)',
            'Coefficient': 0.105658,
        },
        {
            'Element': 'Prior lung transplant',
            'Level': 'Yes',
            'Coefficient': -0.669644,
        },
        {
            'Element': 'On lung waiting list',
            'Level': 'Yes',
            'Coefficient': 2.22038,
        },
        {
            'Element': 'Candidate preemptively listed',
            'Level': 'Yes',
            'Coefficient': -0.179892,
        },
        {
            'Element': 'Prior kidney transplant failure',
            'Level': 'Yes',
            'Coefficient': 0.394656,
        },
        {
            'Element': 'Prior kidney transplant',
            'Level': 'Yes',
            'Coefficient': -1.505418,
        }
    ],
}
