// @flow

import React, { PureComponent } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Col, Row } from 'react-simple-flex-grid';
import HighlightedTextField from './HighlightedTextField';

import AskAlanStyles from '../Styles/AskAlanStyles';

type Props = {
    editableData: boolean,
    labNameDisplay: string,
    labNameSuffix: string,
    labNameUnits: string,

    admitValue: number,
    peakValue: number,
    currentValue: number,

    donorTemplatePreferences: any,
    templateFieldSuffix: string,

    handleChange: (prop: string, value: any) => *,

};

class AdmitPeakCurrentLabs extends PureComponent<Props> {
    handleChange = (prop: any) => (event: any) => {
        this.props.handleChange(prop, event.target.value);
    }

    render() {
        const {
            editableData,
            admitValue,
            peakValue,
            currentValue,
            labNameSuffix,
            labNameDisplay,
            labNameUnits,
            donorTemplatePreferences,
            templateFieldSuffix,
        } = this.props;

        const admitField = `admit${labNameSuffix}`;
        const peakField = `peak${labNameSuffix}`;
        const currentField = `current${labNameSuffix}`;

        const templateGuidance = donorTemplatePreferences || {};

        return (
            <div>
                <span className="groupLabelCenter">{labNameDisplay}</span>
                <Row>
                    <Col span={4}>

                        <HighlightedTextField
                            highlighted={templateGuidance[`ADMIT_${templateFieldSuffix}`]}
                            style={AskAlanStyles.textField}
                            margin="dense"
                            disabled={!editableData}
                            variant="outlined"
                            label="Admit"
                            type="number"
                            value={admitValue}
                            onChange={this.handleChange(admitField)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{labNameUnits}</InputAdornment>,
                            }}
                        />
                    </Col>
                    <Col span={4}>
                        <HighlightedTextField
                            highlighted={templateGuidance[`PEAK_${templateFieldSuffix}`]}
                            style={AskAlanStyles.textField}
                            margin="dense"
                            disabled={!editableData}
                            variant="outlined"
                            label="Peak"
                            type="number"
                            value={peakValue}
                            onChange={this.handleChange(peakField)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{labNameUnits}</InputAdornment>,
                            }}
                        />
                    </Col>
                    <Col span={4}>
                        <HighlightedTextField
                            highlighted={templateGuidance[`CURRENT_${templateFieldSuffix}`]}
                            style={AskAlanStyles.textField}
                            margin="dense"
                            disabled={!editableData}
                            variant="outlined"
                            label="Current"
                            type="number"
                            value={currentValue}
                            onChange={this.handleChange(currentField)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{labNameUnits}</InputAdornment>,
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AdmitPeakCurrentLabs;
