// @flow

import type { OfferData } from '../Types/Offer';
import type { DonorOrganData } from '../Types/DonorOrgan';
import type { RecipientData } from '../Types/Recipient';
import type { CandidateData } from '../Types/Candidate';

import { defaultDonorOrganData } from '../Types/DonorOrgan';
import { defaultRecipientData } from '../Types/Recipient';
import { defaultCandidateData } from '../Types/Candidate';


type ClinicalState = {
    getStatus: string,
    saveDonorOrganStatus: string,
    saveRecipientStatus: string,
    saveCandidateStatus: string,
    donorId: number,
    organId: number,
    // Sprint 9 (03/19) - Only allow for 1 candidate and recipient
    recipientId: number,
    candidateId: number,
    donorOrgan: DonorOrganData,
    recipient: RecipientData,
    candidate: CandidateData,
};

const initialState: ClinicalState = {
    getStatus: '',
    saveDonorOrganStatus: '',
    saveRecipientStatus: '',
    saveCandidateStatus: '',
    donorId: 0,
    organId: 0,
    recipientId: 0,
    candidateId: 0,
    donorOrgan: defaultDonorOrganData,
    recipient: defaultRecipientData,
    candidate: defaultCandidateData,
};

export type GetOffer = { type: 'Clinical/GET_OFFER', donorId: number, organId: number, chatroomId: number };
export type SetGetOfferStatus = { type: 'Clinical/SET_GET_OFFER_STATUS', status: string, offer: OfferData };

export type SaveDonorOrgan = { type: 'Clinical/SAVE_DONOR_ORGAN', donorOrgan: DonorOrganData};
export type SaveDonorOrganLocal = { type: 'Clinical/SAVE_DONOR_ORGAN_LOCAL', donorOrgan: DonorOrganData };
export type SetSaveDonorOrganStatus = { type: 'Clinical/SET_SAVE_DONOR_ORGAN_STATUS', status: string, donorId: number, organId: number };

export type SaveRecipient = { type: 'Clinical/SAVE_RECIPIENT', recipient: RecipientData };
export type SaveRecipientLocal = { type: 'Clinical/SAVE_RECIPIENT_LOCAL', recipient: RecipientData };
export type SetSaveRecipientStatus = { type: 'Clinical/SET_SAVE_RECIPIENT_STATUS', status: string, recipientId: number };

export type SaveCandidate = { type: 'Clinical/SAVE_CANDIDATE', candidate: CandidateData };
export type SaveCandidateLocal = { type: 'Clinical/SAVE_CANDIDATE_LOCAL', candidate: CandidateData };
export type SetSaveCandidateStatus = { type: 'Clinical/SET_SAVE_CANDIDATE_STATUS', status: string, candidateId: number };


type Action =
    | GetOffer
    | SetGetOfferStatus

    | SaveDonorOrgan
    | SaveDonorOrganLocal
    | SetSaveDonorOrganStatus

    | SaveRecipient
    | SaveRecipientLocal
    | SetSaveRecipientStatus

    | SaveCandidate
    | SaveCandidateLocal
    | SetSaveCandidateStatus;

export const getOffer = (donorId: number, organId: number, chatroomId: number): GetOffer => ({
    type: 'Clinical/GET_OFFER',
    donorId,
    organId,
    chatroomId,
});

export const setGetOfferStatus = (status: string, offer: OfferData): SetGetOfferStatus => ({
    type: 'Clinical/SET_GET_OFFER_STATUS',
    status,
    offer,
});

export const saveDonorOrgan = (donorOrgan: DonorOrganData): SaveDonorOrgan => ({
    type: 'Clinical/SAVE_DONOR_ORGAN',
    donorOrgan,
});

export const saveDonorOrganLocal = (donorOrgan: DonorOrganData): SaveDonorOrganLocal => ({
    type: 'Clinical/SAVE_DONOR_ORGAN_LOCAL',
    donorOrgan,
});

export const setSaveDonorOrganStatus = (status: string, donorId: number, organId: number): SetSaveDonorOrganStatus => ({
    type: 'Clinical/SET_SAVE_DONOR_ORGAN_STATUS',
    status,
    donorId,
    organId,
});

export const saveRecipient = (recipient: RecipientData): SaveRecipient => ({
    type: 'Clinical/SAVE_RECIPIENT',
    recipient,
});

export const saveRecipientLocal = (recipient: RecipientData): SaveRecipientLocal => ({
    type: 'Clinical/SAVE_RECIPIENT_LOCAL',
    recipient,
});

export const setSaveRecipientStatus = (status: string, recipientId: number): SetSaveRecipientStatus => ({
    type: 'Clinical/SET_SAVE_RECIPIENT_STATUS',
    status,
    recipientId,
});

export const saveCandidate = (candidate: CandidateData): SaveCandidate => ({
    type: 'Clinical/SAVE_CANDIDATE',
    candidate,
});

export const saveCandidateLocal = (candidate: CandidateData): SaveCandidateLocal => ({
    type: 'Clinical/SAVE_CANDIDATE_LOCAL',
    candidate,
});

export const setSaveCandidateStatus = (status: string, candidateId: number): SetSaveCandidateStatus => ({
    type: 'Clinical/SET_SAVE_CANDIDATE_STATUS',
    status,
    candidateId,
});

const reducer = (state: ClinicalState = initialState, action: Action): ClinicalState => {
    switch (action.type) {
        case 'Clinical/SET_GET_OFFER_STATUS':
            return {
                ...state,

                getStatus: action.status,
                donorId: action.offer.donor.donor_id || 0,
                organId: action.offer.donor.organ_id || 0,
                donorOrgan: action.offer.donor,
                recipient: action.offer.recipients[0] || defaultRecipientData,
                recipientId: action.offer.recipients[0].recipient_id || 0,
                candidate: action.offer.candidates[0] || defaultCandidateData,
                candidateId: action.offer.candidates[0].candidate_id || 0,

            };

        case 'Clinical/SET_SAVE_DONOR_ORGAN_STATUS':
            return {
                ...state,

                saveDonorOrganStatus: action.status,
                donorId: action.donorId,
                organId: action.organId,

            };

        case 'Clinical/SET_SAVE_CANDIDATE_STATUS':
            return {
                ...state,

                saveCandidateStatus: action.status,
                candidateId: action.candidateId,

            };

        case 'Clinical/SAVE_DONOR_ORGAN_LOCAL':
            return {
                ...state,

                donorOrgan: action.donorOrgan,

            };

        case 'Clinical/SAVE_CANDIDATE_LOCAL':
            return {
                ...state,

                candidate: action.candidate,

            };

        case 'Clinical/SAVE_RECIPIENT_LOCAL':
            return {
                ...state,

                recipient: action.recipient,

            };

        case 'Clinical/SET_SAVE_RECIPIENT_STATUS':
            return {
                ...state,

                saveRecipientStatus: action.status,
                recipientId: action.recipientId,

            };

        default:
            return state;
    }
};

export default reducer;
