/* eslint-disable */
export const kipeddtx = {
    baselineHazard: 0.002424,
    predictors: [
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to > 14 (Right LS)',
            'Coefficient': -0.033709,
        },
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to < 2 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to < 4 (Left LS)',
            'Coefficient': -0.000239,
        },
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to < 6 (Left LS)',
            'Coefficient': -0.000482,
        },
        {
            'Element': 'Candidate blood type',
            'Level': 'A',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate blood type',
            'Level': 'AB',
            'Coefficient': 0.112608,
        },
        {
            'Element': 'Candidate blood type',
            'Level': 'B',
            'Coefficient': -0.193721,
        },
        {
            'Element': 'Candidate BMI',
            'Level': 'Apply to > 30 (Right LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate BMI',
            'Level': 'Apply to > 32 (Right LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate height',
            'Level': 'Apply to < 100 (Left LS)',
            'Coefficient': -0.001899,
        },
        {
            'Element': 'Candidate height',
            'Level': 'Apply to < 80 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to > 3.8 (Right LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to < 3.4 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate weight',
            'Level': 'Apply to < 10 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate weight',
            'Level': 'Apply to < 20 (Left LS)',
            'Coefficient': -0.016042,
        },
        {
            'Element': 'Candidate cPRA within 1 month of listing',
            'Level': 'Apply to > 10 (Right LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate cPRA within 1 month of listing',
            'Level': 'Apply to > 20 (Right LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate cPRA within 1 month of listing',
            'Level': 'Apply to < 50 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Years since initial development of ESRD',
            'Level': 'Apply to < 1 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Prior liver transplant',
            'Level': 'Yes',
            'Coefficient': 0,
        },
        {
            'Element': 'Days on the waiting list at beginning of the cohort (natural-log)',
            'Level': 'Apply to > 1 (Right LS)',
            'Coefficient': -0.06322,
        },
        {
            'Element': 'Days on the waiting list at beginning of the cohort (natural-log)',
            'Level': 'Apply to > 5 (Right LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Days on the waiting list at beginning of the cohort (natural-log)',
            'Level': 'Apply to > 6 (Right LS)',
            'Coefficient': -0.282419,
        },
        {
            'Element': 'Days on the waiting list at beginning of the cohort (natural-log)',
            'Level': 'Apply to > 7 (Right LS)',
            'Coefficient': -0.296178,
        },
        {
            'Element': 'Days on the waiting list at beginning of the cohort (natural-log)',
            'Level': 'Linear',
            'Coefficient': 0,
        },
        {
            'Element': 'Prior pancreas transplant',
            'Level': 'Yes',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate preemptively listed',
            'Level': 'Yes',
            'Coefficient': -0.801407,
        },
        {
            'Element': 'Prior kidney transplant',
            'Level': 'Yes',
            'Coefficient': -0.796061,
        }
    ],
}
