// @flow
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import AskAlan from '../Containers/AskAlan';
import ActivationPage from '../Containers/ActivationPage';
import type { TxpData } from '../Types/TxpData';

type Props = {
    txpData: TxpData,
};

class Routes extends Component<Props> {
    render() {
        const {
            txpData,
        } = this.props;

        return (
            <Switch>
                <Route
                    path="/askalan"
                    render={() => (
                        <ActivationPage txpData={txpData} />
                    )}
                />
                <Route
                    path="/load"
                    render={() => (
                        <ActivationPage txpData={txpData} />
                    )}
                />
                <Route path="/offer" component={AskAlan} />
                <Route path="/noauth" component={() => { window.location = 'https://www.omnilife.ai'; return null; }} />
            </Switch>
        );
    }
}

export default Routes;
