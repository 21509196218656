import { call, select } from 'redux-saga/effects';


function* apiCall(resource, method, ...args) {
    let result = null;

    const token = yield select((state) => state.activation.accessToken);

    // Create new headers config because we might need to set token
    const headers = {
        Accept: 'application/json',
    };

    // Add Authorization header unless withCredentials is false for the resource

    if (token && resource.config().withCredentials) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        result = yield call([resource, method], ...args, {
            headers,
        });
    } catch (error) {
        return {
            error,
        };
    }

    return {
        result,
    };
}


export function* apiFetch(resource, kwargs, query) {
    return yield call(apiCall, resource, 'fetch', kwargs, query);
}

export function* apiPost(resource, kwargs, data, query, attachments) {
    return yield call(apiCall, resource, 'post', kwargs, data, query, attachments);
}

export function* apiDelete(resource, kwargs, data, query, attachments) {
    return yield call(apiCall, resource, 'del', kwargs, data, query, attachments);
}

export function* apiPut(resource, kwargs, data, query, attachments) {
    return yield call(apiCall, resource, 'put', kwargs, data, query, attachments);
}
