// @flow
import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DateTimePicker } from '@material-ui/pickers';

type Props = {
  highlighted: boolean,
  style: any,
  label: string,
  value: string,
  disabled: boolean,
  onChange: any,
  error: boolean,
  margin: string,
  variant: string,
  classes: any,
  showTodayButton: boolean,
};

const styles = () => ({
    cssLabel: {
        color: 'green',
    },

    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: 'green !important',
            borderWidth: '3px',
        },
    },

    cssFocused: {},

    notchedOutline: {
        borderWidth: '2px',
        borderColor: 'green !important',
    },

});


class HighlightedDateTimePicker extends PureComponent<Props> {
    // Workaround for passing the props onChange event through to the child
    //   this will call the parents onChange event when the handleChange function
    //   is called at this HighlightedDateTimePicker level.
    constructor(props) {
        super(props);
        this.handleChange = props.onChange.bind(this);
    }

    handleChange = {};

    render() {
        const { classes, highlighted, } = this.props;
        const inputLabelProps = highlighted ? {
            classes: {
                root: classes.cssLabel,
            },
        } : undefined;

        const inputProps = highlighted ? {
            classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
                error: classes.cssErrorLabel,
            },
        } : { classes: { error: classes.cssErrorLabel, }, };

        return (

            <DateTimePicker
                style={this.props.style}
                margin={this.props.margin}
                disabled={this.props.disabled}
                error={this.props.error}
                variant={this.props.variant}
                label={this.props.label}
                value={this.props.value}
                onChange={this.handleChange}
                InputLabelProps={inputLabelProps}
                InputProps={inputProps}
                showTodayButton={this.props.showTodayButton}
            />
        );
    }
}

export default withStyles(styles)(HighlightedDateTimePicker);
