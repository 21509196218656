// @flow

export type TxpData = {
    activationCode: string,
    chatId: number,
    donorId: number,
    matchId: string,
    orgId: number,
    targetOrgId: number,
    organId: number,
    organType: string,
    transplantCenter: string,
    unosId: string,
    userId: number,

}

const defaultTxpData: TxpData = {
    activationCode: '',
    chatId: 0,
    donorId: 0,
    matchId: '',
    orgId: 0,
    targetOrgId: 0,
    organId: 0,
    organType: '',
    transplantCenter: '',
    unosId: '',
    userId: 0,
};

export default defaultTxpData;
