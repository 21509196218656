// @flow
import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

type Props = {
  highlighted: boolean,
  type: string,
  style: any,
  label: string,
  value: string,
  disabled: boolean,
  helperText: string,
  onChange: any,
  error: boolean,
  margin: string,
  multiline: boolean,
  rowsMax: string,
  InputProps: any,
  margin: string,
  classes: any,
};

const styles = () => ({
    cssLabel: {
        color: 'green',
    },

    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: 'green !important',
            borderWidth: '3px',
        },
    },

    cssFocused: {},

    notchedOutline: {
        borderWidth: '2px',
        borderColor: 'green !important',
    },

});

class HighlightedTextField extends PureComponent<Props> {
    // Workaround for passing the props onChange event through to the child
    //   this will call the parents onChange event when the handleChange function
    //   is called at this HighlightedTextField level.
    constructor(props) {
        super(props);
        this.handleChange = props.onChange.bind(this);
    }

    handleChange = {};

    render() {
        const { classes, highlighted, } = this.props;
        const inputLabelProps = highlighted ? {
            classes: {
                root: classes.cssLabel,
            },
        } : undefined;

        const inputProps = highlighted ? {
            classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
            },
        } : undefined;

        return (
            <TextField
                type={this.props.type}
                style={this.props.style}
                label={this.props.label}
                value={this.props.value}
                disabled={this.props.disabled}
                helperText={this.props.helperText}
                onChange={this.handleChange}
                error={this.props.error}
                margin={this.props.margin}
                variant="outlined"
                rowsMax={this.props.rowsMax}
                multiline={this.props.multiline}
                InputLabelProps={inputLabelProps}
                InputProps={{ ...this.props.InputProps, ...inputProps, }}
            />
        );
    }
}

export default withStyles(styles)(HighlightedTextField);
