// @flow

import type { TxpData } from '../Types/TxpData';

type ActivationState = {
    code: string,
    userId: number,
    chatId: number,
    orgId: number,
    targetOrgId: number,
    donorId: number,
    organId: number,
    status: string,
    accessToken: string,
    organType: string,
    transplantCenter: string,
    unosId: string,
    matchId: string,
    templatePreferences: any,

};

const initialState: ActivationState = {
    code: '',
    userId: 0,
    chatId: 0,
    orgId: 0,
    targetOrgId: 0,
    donorId: 0,
    organId: 0,
    status: '',
    accessToken: '',
    organType: '',
    transplantCenter: '',
    unosId: '',
    matchId: '',
    templatePreferences: {},
};

export type StoreActivationParameters = { type: 'Activation/STORE_ACTIVATION_PARAMETERS', txpData: TxpData };
export type VerifyApplicationActivationCode = { type: 'Activation/VERIFY_APPLICATION_ACTIVATION_CODE', txpData: TxpData };
export type UpdateActivationStatus = { type: 'Activation/UPDATE_ACTIVATION_STATUS', activationStatus: string, accessToken: string };
export type GetTemplatePreferences = { type: 'Activation/GET_TEMPLATE_PREFERENCES', organizationId: number };
export type StoreTemplatePreferences = { type: 'Activation/STORE_TEMPLATE_PREFERENCES', templatePreferences: any };

type Action =
    | StoreActivationParameters
    | VerifyApplicationActivationCode
    | UpdateActivationStatus
    | GetTemplatePreferences
    | StoreTemplatePreferences;

export const storeActivationParameters = (txpData: TxpData): StoreActivationParameters => ({
    type: 'Activation/STORE_ACTIVATION_PARAMETERS',
    txpData,
});

export const verifyApplicationActivationCode = (txpData: TxpData): VerifyApplicationActivationCode => ({
    type: 'Activation/VERIFY_APPLICATION_ACTIVATION_CODE',
    txpData,
});

export const updateActivationStatus = (activationStatus: string, accessToken: string): UpdateActivationStatus => ({
    type: 'Activation/UPDATE_ACTIVATION_STATUS',
    activationStatus,
    accessToken,
});

export const getTemplatePreferences = (organizationId: number): GetTemplatePreferences => ({
    type: 'Activation/GET_TEMPLATE_PREFERENCES',
    organizationId,
});

export const storeTemplatePreferences = (templatePreferences: any): StoreTemplatePreferences => ({
    type: 'Activation/STORE_TEMPLATE_PREFERENCES',
    templatePreferences,
});

const reducer = (state: ActivationState = initialState, action: Action): ActivationState => {
    switch (action.type) {
        case 'Activation/STORE_ACTIVATION_PARAMETERS':
            // Saving the activation paramters received
            return {
                ...state,

                code: action.txpData.activationCode,
                userId: action.txpData.userId,
                chatId: action.txpData.chatId,
                orgId: action.txpData.orgId,
                targetOrgId: action.txpData.targetOrgId,
                donorId: action.txpData.donorId,
                organId: action.txpData.organId,

                matchId: action.txpData.matchId,
                unosId: action.txpData.unosId,
                transplantCenter: action.txpData.transplantCenter,
                organType: action.txpData.organType,

                status: initialState.status,
                accessToken: initialState.accessToken,

            };

        case 'Activation/UPDATE_ACTIVATION_STATUS':
            // Saving the activation paramters received
            return {
                ...state,

                status: action.activationStatus,
                accessToken: action.accessToken,

            };

        case 'Activation/STORE_TEMPLATE_PREFERENCES':
            // Saving the activation paramters received
            return {
                ...state,

                templatePreferences: action.templatePreferences,

            };

        default:
            return state;
    }
};

export default reducer;
