/* eslint-disable */
export const kiddpegs3y = {
    baselineHazard: 0.092788763368061,
    predictors: [
        {
            'predictor': 'Candidate race',
            'level': 'Black',
            'coefficient': 0.273534646302146,
        },
        {
            'predictor': 'Candidate race',
            'level': 'Other',
            'coefficient': 0,
        },
        {
            'predictor': 'Candidate race',
            'level': 'White',
            'coefficient': -0.08973588447028,
        },
        {
            'predictor': 'Candidate race',
            'level': 'Missing',
            'coefficient': -0.08973588447028,
        },
        {
            'predictor': 'Candidate total albumin',
            'level': 'Apply to < 2.6 (Left LS)',
            'coefficient': 0.00445507464364112,
        },
        {
            'predictor': 'Candidate total albumin',
            'level': 'Apply to < 3 (Left LS)',
            'coefficient': 0.0154813241856959,
        },
        {
            'predictor': 'Candidate total albumin',
            'level': 'Apply to > 4 (Right LS)',
            'coefficient': -0.00422894544325139,
        },
        {
            'predictor': 'Candidate total albumin',
            'level': 'Missing',
            'coefficient': -0.00465183998757653,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'Congenital',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'FSGS',
            'coefficient': 0.640889472082263,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'GN',
            'coefficient': 0.151050493601616,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'Other',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient primary diagnosis at transplant',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor age',
            'level': 'Apply to < 14 (Left LS)',
            'coefficient': 0.0399165293650682,
        },
        {
            'predictor': 'Donor age',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor BMI',
            'level': 'Apply to < 24 (Left LS)',
            'coefficient': 0.00790779492134659,
        },
        {
            'predictor': 'Donor BMI',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor serum creatinine',
            'level': 'Apply to > 0.8 (Right LS)',
            'coefficient': 0.0672822623601669,
        },
        {
            'predictor': 'Donor serum creatinine',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor hematocrit',
            'level': 'Apply to < 34 (Left LS)',
            'coefficient': 0.00664088509155394,
        },
        {
            'predictor': 'Donor hematocrit',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor history of hypertension',
            'level': 'No',
            'coefficient': 0,
        },
        {
            'predictor': 'Donor history of hypertension',
            'level': 'Yes',
            'coefficient': -0.0886440423490913,
        },
        {
            'predictor': 'Donor history of hypertension',
            'level': 'Missing',
            'coefficient': -0.0886440423490913,
        },
        {
            'predictor': 'Donor INR',
            'level': 'Apply to < 1.1 (Left LS)',
            'coefficient': 1.18068636982027,
        },
        {
            'predictor': 'Donor INR',
            'level': 'Apply to > 1.3 (Right LS)',
            'coefficient': 0.0117275998830486,
        },
        {
            'predictor': 'Donor INR',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient total ESRD time (days) at transplant = 0',
            'level': 'Not Zero',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient total ESRD time (days) at transplant = 0',
            'level': 'Zero',
            'coefficient': -0.137484995487147,
        },
        {
            'predictor': 'Recipient total ESRD time (days) at transplant = 0',
            'level': 'Missing',
            'coefficient': -0.137484995487147,
        },
        {
            'predictor': 'Donor risk index',
            'level': 'Apply to > 1 (Right LS)',
            'coefficient': 0.0295966852998556,
        },
        {
            'predictor': 'Donor risk index',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient BMI',
            'level': 'Apply to < 16 (Left LS)',
            'coefficient': -0.040389386470304,
        },
        {
            'predictor': 'Recipient BMI',
            'level': 'Apply to < 22 (Left LS)',
            'coefficient': -0.000624083654950577,
        },
        {
            'predictor': 'Recipient BMI',
            'level': 'Missing',
            'coefficient': -0.0884576906431868,
        },
        {
            'predictor': 'Recipient motor development',
            'level': 'Impaired',
            'coefficient': 0.000913864313572881,
        },
        {
            'predictor': 'Recipient motor development',
            'level': 'Not Impaired',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient motor development',
            'level': 'Missing',
            'coefficient': 0,
        },
        {
            'predictor': 'Recipient most recent CPRA',
            'level': 'Apply to < 40 (Left LS)',
            'coefficient': -0.00614034516628547,
        },
        {
            'predictor': 'Recipient most recent CPRA',
            'level': 'Apply to > 80 (Right LS)',
            'coefficient': 0.0107435509897268,
        },
        {
            'predictor': 'Recipient most recent CPRA',
            'level': 'Missing',
            'coefficient': -0.245613806651419,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Apply to < 2 (Left LS)',
            'coefficient': 0.00398239752761146,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Apply to < 4 (Left LS)',
            'coefficient': 0.0221724288565364,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Apply to > 12 (Right LS)',
            'coefficient': 0.0707187646701454,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Apply to > 20 (Right LS)',
            'coefficient': -0.136329915187049,
        },
        {
            'predictor': 'Recipient age',
            'level': 'Missing',
            'coefficient': 0,
        }
    ],
}
