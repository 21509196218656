/* eslint-disable no-template-curly-in-string */
import { Router, parseErrors } from 'tg-resources';
import { SuperAgentResource as Resource } from '@tg-resources/superagent';

import isString from '../Utils/isString';


const api = new Router({
    txp: new Router({
        // API(s) that do not need access token
        anonymous: new Router({
            verifyApplicationActivationCode: new Resource('/auth/application/activation/verify'),
        }, {
            withCredentials: false,
        }),
        getOffer: new Resource('/offer/${chatroom_id}/donor/${donor_id}/organ/${organ_id}'),
        insertDonorOrgan: new Resource('/offer/donor'),
        updateDonorOrgan: new Resource('/offer/donor/${donor_id}/organ/${organ_id}'),
        insertRecipient: new Resource('/offer/recipient'),
        updateRecipient: new Resource('/offer/recipient/${recipient_id}'),
        insertCandidate: new Resource('/offer/candidate'),
        updateCandidate: new Resource('/offer/candidate/${candidate_id}'),
        getOrganizations: new Resource('/organization/all'),

    }, {
        apiRoot: (process.env.REACT_APP_API_ROOT || '').replace(/\/$/, ''),
        parseErrors: (errorText, parentConfig) => {
            let error = null;

            if (isString(errorText)) {
                if (errorText) {
                    try {
                        error = JSON.parse(errorText);
                    } catch (e) {
                        // if json parsing fails, handle as text
                        error = errorText;
                    }
                } else {
                    error = '';
                }
            } else {
                error = errorText;
            }

            if (error && error.request_error) {
                const preparedError = {};

                (error.request_error || []).forEach((fieldError) => {
                    preparedError[fieldError.parameter_name] = preparedError[fieldError.parameter_name] || [];
                    (fieldError.violated_constraints || []).forEach((msg) => {
                        preparedError[fieldError.parameter_name].push(msg);
                    });
                });
                return parseErrors(preparedError, parentConfig);
            } if (error && error.error) {
                // eslint-disable-next-line prefer-destructuring
                error = error.error;

                // Hackish solution to be able to handle recovery endpoint errors
                //  the errors for it are a json string with prepended statuscode
                if (isString(error) && (error.startsWith('403 - {') || error.startsWith('400 - {'))) {
                    try {
                        error = JSON.parse(error.substring(6));

                        if (error && error.errorSummary) {
                            error = error.errorSummary;
                        }
                    } catch (er) {
                        // nothing to do
                    }
                }

                error = {
                    non_field_errors: error,
                };
            }

            return parseErrors(error, parentConfig);
        },
    }),
}, {
    headers: () => ({
        Accept: 'application/json',
        Apikey: process.env.REACT_APP_API_KEY || '',
    }),
    withCredentials: true,
});

export default api;
