/* eslint-disable */
export const kiaddt = {
    baselineHazard: 0.000108,
    predictors: [
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to > 25 (Right LS)',
            'Coefficient': 0.001987,
        },
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to > 30 (Right LS)',
            'Coefficient': 0.016705,
        },
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to > 60 (Right LS)',
            'Coefficient': -0.003999,
        },
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to > 65 (Right LS)',
            'Coefficient': -0.004992,
        },
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to > 75 (Right LS)',
            'Coefficient': 0.876305,
        },
        {
            'Element': 'Candidate age at listing',
            'Level': 'Linear',
            'Coefficient': 0.000427,
        },
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to < 25 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to < 40 (Left LS)',
            'Coefficient': -0.000923,
        },
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to < 45 (Left LS)',
            'Coefficient': -0.00075,
        },
        {
            'Element': 'Candidate age at listing',
            'Level': 'Apply to < 70 (Left LS)',
            'Coefficient': -0.011242,
        },
        {
            'Element': 'Candidate blood type',
            'Level': 'A',
            'Coefficient': 0.021741,
        },
        {
            'Element': 'Candidate blood type',
            'Level': 'AB',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate blood type',
            'Level': 'B',
            'Coefficient': -0.028176,
        },
        {
            'Element': 'Candidate BMI',
            'Level': 'Apply to > 28 (Right LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate BMI',
            'Level': 'Apply to > 30 (Right LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate BMI',
            'Level': 'Apply to > 40 (Right LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate BMI',
            'Level': 'Apply to < 20 (Left LS)',
            'Coefficient': -0.024901,
        },
        {
            'Element': 'Candidate BMI',
            'Level': 'Apply to < 22 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate BMI',
            'Level': 'Apply to < 24 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate BMI',
            'Level': 'Apply to < 26 (Left LS)',
            'Coefficient': 0.021676,
        },
        {
            'Element': 'Candidate BMI',
            'Level': 'Apply to < 34 (Left LS)',
            'Coefficient': 0.004727,
        },
        {
            'Element': 'Candidate BMI',
            'Level': 'Apply to < 40 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate diabetes type',
            'Level': 'None',
            'Coefficient': -0.363152,
        },
        {
            'Element': 'Candidate diabetes type',
            'Level': 'Type 1',
            'Coefficient': 0.314732,
        },
        {
            'Element': 'Candidate diabetes type',
            'Level': 'Unknown',
            'Coefficient': 0.235796,
        },
        {
            'Element': 'Candidate education',
            'Level': 'Grade School/None',
            'Coefficient': -0.238144,
        },
        {
            'Element': 'Candidate education',
            'Level': 'Post High School Degree',
            'Coefficient': -0.005611,
        },
        {
            'Element': 'Candidate education',
            'Level': 'Technical',
            'Coefficient': 0.028413,
        },
        {
            'Element': 'Candidate ethnicity',
            'Level': 'Latino',
            'Coefficient': -0.501284,
        },
        {
            'Element': 'Candidate sex',
            'Level': 'Female',
            'Coefficient': -0.048476,
        },
        {
            'Element': 'Candidate height',
            'Level': 'Apply to > 160 (Right LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate height',
            'Level': 'Apply to > 165 (Right LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate height',
            'Level': 'Apply to > 175 (Right LS)',
            'Coefficient': 0.008445,
        },
        {
            'Element': 'Candidate height',
            'Level': 'Apply to > 180 (Right LS)',
            'Coefficient': -0.009346,
        },
        {
            'Element': 'Candidate height',
            'Level': 'Apply to > 190 (Right LS)',
            'Coefficient': -0.033924,
        },
        {
            'Element': 'Candidate height',
            'Level': 'Apply to < 150 (Left LS)',
            'Coefficient': 0.029475,
        },
        {
            'Element': 'Candidate height',
            'Level': 'Apply to < 160 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate previous malignancy',
            'Level': 'Yes',
            'Coefficient': -0.003325,
        },
        {
            'Element': 'Candidate PVD',
            'Level': 'Yes',
            'Coefficient': 0.131955,
        },
        {
            'Element': 'Candidate primary insurance',
            'Level': 'Medicaid',
            'Coefficient': -0.051275,
        },
        {
            'Element': 'Candidate primary insurance',
            'Level': 'Other',
            'Coefficient': -0.112645,
        },
        {
            'Element': 'Candidate primary insurance',
            'Level': 'Private/Self',
            'Coefficient': -0.068416,
        },
        {
            'Element': 'Candidate race',
            'Level': 'Asian',
            'Coefficient': -0.16739,
        },
        {
            'Element': 'Candidate race',
            'Level': 'Other',
            'Coefficient': 0.05249,
        },
        {
            'Element': 'Candidate race',
            'Level': 'White',
            'Coefficient': 0.351828,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to > 2.8 (Right LS)',
            'Coefficient': -0.176224,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to > 3.2 (Right LS)',
            'Coefficient': -0.044175,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to > 4.2 (Right LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to > 4.6 (Right LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to > 4.8 (Right LS)',
            'Coefficient': 0.436807,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to < 2.8 (Left LS)',
            'Coefficient': -0.073883,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to < 3.4 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to < 3.6 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to < 3.8 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to < 4.4 (Left LS)',
            'Coefficient': 0.164542,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to < 4.6 (Left LS)',
            'Coefficient': 0.000035,
        },
        {
            'Element': 'Candidate albumin',
            'Level': 'Apply to < 4 (Left LS)',
            'Coefficient': 0.071248,
        },
        {
            'Element': 'Candidate weight',
            'Level': 'Apply to > 100 (Right LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate weight',
            'Level': 'Apply to > 120 (Right LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate weight',
            'Level': 'Apply to > 90 (Right LS)',
            'Coefficient': 0.000054,
        },
        {
            'Element': 'Candidate weight',
            'Level': 'Apply to < 70 (Left LS)',
            'Coefficient': 0.001462,
        },
        {
            'Element': 'Candidate working for income',
            'Level': 'Yes',
            'Coefficient': -0.193736,
        },
        {
            'Element': 'Candidate cPRA within 1 month of listing',
            'Level': 'Apply to > 70 (Right LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate cPRA within 1 month of listing',
            'Level': 'Apply to > 80 (Right LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate cPRA within 1 month of listing',
            'Level': 'Apply to > 90 (Right LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate cPRA within 1 month of listing',
            'Level': 'Apply to < 40 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Candidate cPRA within 1 month of listing',
            'Level': 'Apply to < 50 (Left LS)',
            'Coefficient': 0.00051,
        },
        {
            'Element': 'Candidate primary diagnosis',
            'Level': 'Congenital',
            'Coefficient': -0.010156,
        },
        {
            'Element': 'Candidate primary diagnosis',
            'Level': 'Diabetes',
            'Coefficient': 0.194162,
        },
        {
            'Element': 'Candidate primary diagnosis',
            'Level': 'Glomerulonephritis',
            'Coefficient': -0.083803,
        },
        {
            'Element': 'Candidate primary diagnosis',
            'Level': 'Hypertension',
            'Coefficient': 0.019443,
        },
        {
            'Element': 'Candidate primary diagnosis',
            'Level': 'Other',
            'Coefficient': -0.087036,
        },
        {
            'Element': 'Years since initial development of ESRD',
            'Level': 'Apply to > 16 (Right LS)',
            'Coefficient': 0.00597,
        },
        {
            'Element': 'Years since initial development of ESRD',
            'Level': 'Apply to < 16 (Left LS)',
            'Coefficient': -0.012166,
        },
        {
            'Element': 'Years since initial development of ESRD',
            'Level': 'Apply to < 2 (Left LS)',
            'Coefficient': -0.045715,
        },
        {
            'Element': 'Years since initial development of ESRD',
            'Level': 'Apply to < 4 (Left LS)',
            'Coefficient': -0.075825,
        },
        {
            'Element': 'Years since initial development of ESRD',
            'Level': 'Apply to < 6 (Left LS)',
            'Coefficient': 0,
        },
        {
            'Element': 'Years since initial development of ESRD',
            'Level': 'Apply to < 8 (Left LS)',
            'Coefficient': -0.00002,
        },
        {
            'Element': 'Prior heart transplant',
            'Level': 'Yes',
            'Coefficient': 0.850887,
        },
        {
            'Element': 'On heart waiting list',
            'Level': 'Yes',
            'Coefficient': 1.343799,
        },
        {
            'Element': 'On SPK/pancreas waiting list',
            'Level': 'Yes',
            'Coefficient': 0.180923,
        },
        {
            'Element': 'Prior SPK transplant',
            'Level': 'Yes',
            'Coefficient': 0.007837,
        },
        {
            'Element': 'Prior liver transplant',
            'Level': 'Yes',
            'Coefficient': 0.898825,
        },
        {
            'Element': 'On liver waiting list',
            'Level': 'Yes',
            'Coefficient': 1.078766,
        },
        {
            'Element': 'Days on the waiting list at beginning of the cohort (natural-log)',
            'Level': 'Apply to > 4 (Right LS)',
            'Coefficient': 0.194818,
        },
        {
            'Element': 'Days on the waiting list at beginning of the cohort (natural-log)',
            'Level': 'Apply to > 6 (Right LS)',
            'Coefficient': 0.207194,
        },
        {
            'Element': 'Days on the waiting list at beginning of the cohort (natural-log)',
            'Level': 'Apply to > 7 (Right LS)',
            'Coefficient': 0.176072,
        },
        {
            'Element': 'Days on the waiting list at beginning of the cohort (natural-log)',
            'Level': 'Apply to > 8 (Right LS)',
            'Coefficient': -0.000555,
        },
        {
            'Element': 'Days on the waiting list at beginning of the cohort (natural-log)',
            'Level': 'Apply to < 1 (Left LS)',
            'Coefficient': -0.008407,
        },
        {
            'Element': 'Prior lung transplant',
            'Level': 'Yes',
            'Coefficient': 0.827556,
        },
        {
            'Element': 'On lung waiting list',
            'Level': 'Yes',
            'Coefficient': 0,
        },
        {
            'Element': 'Prior pancreas transplant',
            'Level': 'Yes',
            'Coefficient': -0.051123,
        },
        {
            'Element': 'Candidate preemptively listed',
            'Level': 'Yes',
            'Coefficient': -0.358041,
        },
        {
            'Element': 'Prior kidney transplant failure',
            'Level': 'Yes',
            'Coefficient': 0.170952,
        },
        {
            'Element': 'Prior kidney transplant',
            'Level': 'Yes',
            'Coefficient': -0.420065,
        }
    ],
}
