// @flow
import * as Sentry from '@sentry/browser';
import delay from 'redux-saga';
import type { Saga } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import type {
    GetOffer,
    SaveDonorOrgan,
    SaveRecipient,
    SaveCandidate,
} from '../Redux/ClinicalActions';

import {
    setGetOfferStatus,
    setSaveDonorOrganStatus,
    setSaveRecipientStatus,
    setSaveCandidateStatus,
    saveDonorOrganLocal,
    saveRecipientLocal,
    saveCandidateLocal,
} from '../Redux/ClinicalActions';

import api from '../Services/Api';

import { apiFetch, apiPost, apiPut } from './ApiSaga';
import defaultOfferData from '../Types/Offer';
import { defaultRecipientData } from '../Types/Recipient';
import { defaultCandidateData } from '../Types/Candidate';

import type { OfferData } from '../Types/Offer';
import type { RecipientData } from '../Types/Recipient';
import type { CandidateData } from '../Types/Candidate';

const defaultSerologies = {
    offer: '',
};

export function* getOffer(action: GetOffer): Saga<void> {
    yield call(delay, 50);

    const { result, error, } = yield apiFetch(api.txp.getOffer, {
        donor_id: action.donorId,
        organ_id: action.organId,
        chatroom_id: action.chatroomId,
    });

    if (error) {
        Sentry.captureException(JSON.stringify(error));
        yield put(setGetOfferStatus('FAILED', defaultOfferData));
    } else {
        // Pending change of message type to structure the recipient and candidates, we have to map by hand.
        const offer: OfferData = defaultOfferData;
        offer.donor = result.donor;

        // defensive coding for missing defaults caused by simple case
        if (offer.donor.labs.serologies === null) offer.donor.labs.serologies = defaultSerologies;

        const recipient: RecipientData = defaultRecipientData;
        if (result.recipients && result.recipients.length > 0) {
            const msgRecipient = result.recipients[0];
            recipient.recipient_id = msgRecipient.recipient_id;
            recipient.waitlist.waitlist_number = msgRecipient.waitlist_number;
            recipient.waitlist.priority = msgRecipient.priority;

            recipient.demographics.full_name = msgRecipient.full_name;
            recipient.demographics.sex = msgRecipient.sex;
            recipient.demographics.age = msgRecipient.age;

            recipient.labs.cpra = msgRecipient.cpra;
            recipient.labs.mismatch_a = msgRecipient.mismatch_a;
            recipient.labs.mismatch_b = msgRecipient.mismatch_b;
            recipient.labs.mismatch_dr = msgRecipient.mismatch_dr;
        }
        offer.recipients[0] = recipient;

        const candidate: CandidateData = defaultCandidateData;
        if (result.candidates && result.candidates.length > 0) {
            const msgCandidate = result.candidates[0];
            candidate.candidate_id = msgCandidate.candidate_id;

            candidate.demographics.mrn = msgCandidate.mrn;
            candidate.demographics.full_name = msgCandidate.full_name;
            candidate.demographics.sex = msgCandidate.sex;
            candidate.demographics.age = msgCandidate.age;
            candidate.demographics.blood_type = msgCandidate.blood_type;
            candidate.demographics.bmi = msgCandidate.bmi;
            candidate.demographics.height = msgCandidate.height;
            candidate.demographics.weight = msgCandidate.weight;
            candidate.demographics.race = msgCandidate.race;
            candidate.demographics.ethnicity = msgCandidate.ethnicity;

            candidate.history.prior_transplant = msgCandidate.prior_transplant;
            candidate.history.esrd_time = msgCandidate.esrd_time;
            candidate.history.diabetes_type = msgCandidate.diabetes_type;
            candidate.history.cancer = msgCandidate.cancer;
            candidate.history.pvd = msgCandidate.pvd;
            candidate.history.last_seen = msgCandidate.last_seen;
            candidate.history.primary_diagnosis = msgCandidate.primary_diagnosis;

            candidate.labs.cpra = msgCandidate.cpra;
        }
        offer.candidates[0] = candidate;

        yield put(setGetOfferStatus('LOADED', offer));
    }
}

export function* saveDonorOrgan(action: SaveDonorOrgan): Saga<void> {
    yield call(delay, 50);

    const activation = yield select((state) => state.activation);
    const clinical = yield select((state) => state.clinical);
    yield put(setSaveDonorOrganStatus('', clinical.donorId, clinical.organId));

    if (activation.token === 'DEMONSTRATION') {
        return;
    }

    const coreParams = {
        chatroom_id: activation.chatId,
        organization_id: activation.orgId,
    };

    // Flow does not like object assign as many variables from donorRecord are not in coreParams.
    // $FlowFixMe
    const params = Object.assign(coreParams, action.donorOrgan);

    if (clinical.donorId === 0) {
        coreParams.organization_id = activation.orgId;
        const { result, error, } = yield apiPost(api.txp.insertDonorOrgan, null, params);
        if (result) {
            const donorId = result.donor_id;
            const organId = result.organ_id;
            yield put(setSaveDonorOrganStatus('Donor Added', donorId, organId));

            // update the local copy
            const { donorOrgan, } = action;
            donorOrgan.donor_id = donorId;
            donorOrgan.organ_id = organId;
            donorOrgan.organization_id = activation.orgId;
            yield put(saveDonorOrganLocal(donorOrgan));
        } else {
            Sentry.captureException(JSON.stringify(error));
            yield put(setSaveDonorOrganStatus('FAILED', 0, 0));
        }
    } else {
        const urlParms = {
            donor_id: clinical.donorId,
            organ_id: clinical.organId,
        };
        const { error, } = yield apiPut(api.txp.updateDonorOrgan, urlParms, params);
        if (error) {
            Sentry.captureException(JSON.stringify(error));
            yield put(setSaveDonorOrganStatus('FAILED', clinical.donorId, clinical.organId));
        } else {
            yield put(setSaveDonorOrganStatus('Donor Updated', clinical.donorId, clinical.organId));
        }
    }
}

export function* saveRecipient(action: SaveRecipient): Saga<void> {
    yield call(delay, 50);

    const activation = yield select((state) => state.activation);
    const clinical = yield select((state) => state.clinical);
    yield put(setSaveRecipientStatus('', clinical.recipientId));

    if (activation.token === 'DEMONSTRATION') {
        return;
    }

    const coreParams = {
        chatroom_id: activation.chatId,
        organization_id: activation.orgId,
        donor_id: clinical.donorId,
        organ_id: clinical.organId,
    };

    // Flow does not like object assign as many variables from recipient are not in coreParams.
    // $FlowFixMe
    const params = Object.assign(coreParams, action.recipient.waitlist, action.recipient.demographics, action.recipient.labs);

    if (clinical.recipientId === 0) {
        const { result, error, } = yield apiPost(api.txp.insertRecipient, null, params);
        if (result) {
            const recipientId = result.recipient_id;
            yield put(setSaveRecipientStatus('Recipient Saved', recipientId));

            // update the local copy with the new Recipient Id
            const { recipient, } = action;
            recipient.recipient_id = recipientId;
            yield put(saveRecipientLocal(recipient));
        } else {
            Sentry.captureException(JSON.stringify(error));
            yield put(setSaveRecipientStatus('FAILED', 0));
        }
    } else {
        const urlParms = {
            recipient_id: clinical.recipientId,
        };
        const { error, } = yield apiPut(api.txp.updateRecipient, urlParms, params);
        if (error) {
            Sentry.captureException(JSON.stringify(error));
            yield put(setSaveRecipientStatus('FAILED', clinical.recipientId));
        } else {
            yield put(setSaveRecipientStatus('Recipient Updated', clinical.recipientId));
        }
    }
}

export function* saveCandidate(action: SaveCandidate): Saga<void> {
    yield call(delay, 50);

    const activation = yield select((state) => state.activation);
    const clinical = yield select((state) => state.clinical);
    yield put(setSaveCandidateStatus('', clinical.candidateId));

    if (activation.token === 'DEMONSTRATION') {
        return;
    }

    const coreParams = {
        chatroom_id: activation.chatId,
        organization_id: activation.orgId,
        donor_id: clinical.donorId,
        organ_id: clinical.organId,
        recipient_id: clinical.recipientId,
    };

    // Flow does not like object assign as many variables from recipient are not in coreParams.
    // $FlowFixMe
    const params = Object.assign(coreParams, action.candidate.history, action.candidate.demographics, action.candidate.labs);

    if (clinical.candidateId === 0) {
        const { result, error, } = yield apiPost(api.txp.insertCandidate, null, params);
        if (result) {
            const candidateId = result.candidate_id;
            yield put(setSaveCandidateStatus('Candidate Saved', candidateId));

            // update the local copy
            const { candidate, } = action;
            candidate.candidate_id = candidateId;
            yield put(saveCandidateLocal(candidate));
        } else {
            yield put(setSaveCandidateStatus('FAILED', 0));
            Sentry.captureException(JSON.stringify(error));
        }
    } else {
        const urlParms = {
            candidate_id: clinical.candidateId,
        };
        const { error, } = yield apiPut(api.txp.updateCandidate, urlParms, params);
        if (error) {
            yield put(setSaveCandidateStatus('FAILED', clinical.candidateId));
            Sentry.captureException(JSON.stringify(error));
        } else {
            yield put(setSaveCandidateStatus('Candidate Updated', clinical.candidateId));
        }
    }
}
